import { AUTO_ASSIGNMENT_TAB_KEYS } from '@/constants/channels';

export default {
	adminId: state => {
		return state.account?.admin?.id ?? null;
	},
	isAbleToClockIn: state => {
		return state.permissions.includes('consult_prescription:edit');
	},
	isUserClockedIn: state => {
		return state.account?.admin?.isClockedIn ?? false;
	},
	userRole: state => {
		return state.account?.admin?.role ?? null;
	},
	userSubRole: state => {
		return state.account?.admin?.subRole ?? null;
	},
	userSubRoleDefaultChannelTab: state => {
		return state.account?.admin?.subRole?.meta?.defaultChannelTab ?? AUTO_ASSIGNMENT_TAB_KEYS.ALL;
	},
	userSubRolePermissionSlugs: state => {
		return state.account?.admin?.subRole?.permissions?.map(permission => permission.slug) ?? [
			'consult_prescription:search_bar',
			'consult_prescription:filter',
			'consult_prescription:tab:all',
			'consult_prescription:tab:assigned',
			'consult_prescription:tab:unassigned',
			'consult_prescription:tab:triage',
			'consult_prescription:tab:end',
		];
	},
	isAutoAssignmentActive: state => {
		return state.config?.auto_assignment?.isActive ?? true;
	},
	permissions: state => {
		return state.permissions;
	}
}