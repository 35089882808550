import axios from '../axios';
import { MENUS } from '@/constants/menus';
import cookies from 'js-cookie';

export default {
  login({ commit }, payload) {
    cookies.set('accessToken', payload.accessToken);
    cookies.set('refreshToken', payload.refreshToken);
    commit('UPDATE_USER', payload.account);
  },
  logoutExpiredToken({ commit }) {
    cookies.remove('accessToken');
    cookies.remove('refreshToken');
    localStorage.removeItem('accessToken'); // TODO: will be removed later
    commit('LOGOUT_USER');
    window.location.assign('/login');
  },
  async logout({ commit, getters }) {
    // Clock-out
    if (getters.userSubRole) {
      await axios.post('/admins/clocking', {
        clinicalRoleId: getters.userSubRole.id,
        action: 'clock_out',
        note: 'Clock-out on log-out',
      });
    }

    cookies.remove('accessToken');
    cookies.remove('refreshToken');
    localStorage.removeItem('accessToken'); // TODO: will be removed later
    commit('LOGOUT_USER');
    window.location.assign('/login');
  },
  async getMe({ commit, dispatch }) {
    const getMeV1 = axios.get('/auth/me').then((resp) => {
      commit('UPDATE_USER', resp.data.data);
    });
    const getMeV2 = axios.get('/v2/auth/me').then((resp) => {
      const { permissions } = resp.data.data;
      commit('UPDATE_PERMISSIONS', permissions);
      const allowedMenus = MENUS.filter((menu) =>
        permissions.some((permission) => menu.permissions.includes(permission))
      );
      const isAllowed = allowedMenus.some((menu) =>
        window.location.pathname.startsWith(menu.url)
      ) || window.location.pathname.startsWith('/user');
      if (!isAllowed) {
        if (allowedMenus.length > 0) {
          window.location.assign(allowedMenus[0].url);
        } else {
          dispatch('logout');
        }
      }
    });
    await Promise.all([getMeV1, getMeV2]);
  },
  updatePusherConnectionState({ commit }, payload) {
    commit('UPDATE_PUSHER_CONNECTION_STATE', payload);
  },
  updateConfig({ commit }, payload) {
    commit('UPDATE_CONFIG', payload);
  },
};
