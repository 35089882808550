<template>
  <div class="pagination-wrapper">
    <div
      class="navigation"
      :class="{ 'navigation-active': currentPage > 1 }"
      @click="handleClickPage(currentPage - 1)"
    >
      <i class="fa fa-chevron-left" />
    </div>
    <div
      v-for="(page, idx) in pages"
      :key="idx"
      class="page"
      :class="{
        'page-active': page === currentPage,
        'cursor-pointer': page !== '...',
      }"
      @click="handleClickPage(page)"
    >
      {{ page }}
    </div>
    <div
      class="navigation"
      :class="{ 'navigation-active': currentPage < totalPages }"
      @click="handleClickPage(currentPage + 1)"
      style="border-left: 1px solid #bcc0d1;"
    >
      <i class="fa fa-chevron-right" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentPage: Number,
    totalPages: Number,
  },
  computed: {
    pages() {
      const pages = [
        1,
        this.currentPage - 1,
        this.currentPage,
        this.currentPage + 1,
        this.totalPages,
      ].filter((page) => page >= 1 && page <= this.totalPages);

      // remove duplicate pages
      const uniquePages = new Set(pages);

      const renderedPages = [];
      let prevPage;
      Array.from(uniquePages).forEach((page) => {
        if (page - prevPage > 1) {
          renderedPages.push('...');
        }
        renderedPages.push(page);
        prevPage = page;
      });
      return renderedPages;
    },
  },
  methods: {
    handleClickPage(page) {
      if (
        page !== '...' &&
        page !== this.currentPage &&
        page >= 1 &&
        page <= this.totalPages
      ) {
        this.$emit('change', page);
      }
    },
  },
};
</script>

<style scoped>
.pagination-wrapper {
  display: inline-flex;
  align-items: center;
  border: 1px solid #bcc0d1;
  border-radius: 8px;
  background-color: white;
}

.page,
.navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  min-width: 32px;
  padding: 4px;
  font-size: 12px;
}

.page {
  color: #111820;
  border-left: 1px solid #bcc0d1;
}

.page-active {
  background-color: #0072cd;
  color: white;
}

.navigation {
  color: rgba(17, 24, 32, 0.4);
}

.navigation-active {
  color: #0072cd;
  cursor: pointer;
}
</style>
