<template>
  <div class="wrapper" :style="styleObject">
    <span>
      <i v-if="iconClass" class="fa" :class="{ [iconClass]: true }"></i>
    </span>
    <input
      type="text"
      :value="value"
      @input="(e) => $emit('input', e.target.value)"
      :placeholder="placeholder"
      class="input"
    />
  </div>
</template>

<script>
export default {
  props: {
    iconClass: {
      type: String,
    },
    styleObject: {
      type: Object,
    },
    value: {
      type: String,
    },
    placeholder: {
      type: String,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  height: 40px;
  border: 1px solid #bcc0d1 !important;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding-inline: 12px;
}

.input {
  border: none;
  outline: none;
  flex: 1;
  font-size: 14px;
  padding: 0;
  height: 100%;
}
</style>
