<template>
  <div class="section mw-100 p-2">
    <div class="card">
      <div class="card-body">
        <div class="d-flex gap-1 align-items-center flex-wrap">
          <div class="form-group" style="flex: 1 0 250px">
            <label class="f-12 mb-0.25">Date</label>
            <date-picker
              v-model="date"
              format="DD/MM/YYYY"
              type="date"
              input-class="form-control"
              value-type="YYYY-MM-DD"
              placeholder="Select Date Range"
              :range="true"
              :disabled-date="disabledDate"
              @input="hasViewPermission ? getListAndSummary() : undefined"
              style="width: 100%"
            />
          </div>
          <div class="form-group" style="flex: 1 0 200px">
            <label class="f-12 mb-0.25">Doctor</label>
            <multiselect
              v-model="doctor"
              placeholder="Select Doctor"
              :options="doctorOptions"
              label="name"
              track-by="id"
              select-label=""
              deselect-label="Reset"
              :multiple="false"
              @input="hasViewPermission ? getListAndSummary() : undefined"
            >
              <span slot="noResult">Oops! No data found.</span>
            </multiselect>
          </div>
          <div class="form-group" style="flex: 1 0 200px">
            <label class="f-12 mb-0.25">Polyclinic</label>
            <multiselect
              v-model="polyclinic"
              placeholder="Select Polyclinic"
              :options="polyclinicOptions"
              label="name"
              track-by="id"
              select-label=""
              deselect-label="Reset"
              :multiple="false"
              @input="hasViewPermission ? getListAndSummary() : undefined"
            >
              <span slot="noResult">Oops! No data found.</span>
            </multiselect>
          </div>
          <div class="form-group" style="flex: 1 0 200px">
            <label class="f-12 mb-0.25">Survey Result</label>
            <multiselect
              v-model="surveyResult"
              placeholder="Select Survey Result"
              :options="[
                {
                  value: 'SATISFIED',
                  label: getSatisfactionLevelLabel('SATISFIED'),
                },
                {
                  value: 'UNSATISFIED',
                  label: getSatisfactionLevelLabel('UNSATISFIED'),
                },
              ]"
              label="label"
              track-by="value"
              select-label=""
              deselect-label="Reset"
              :multiple="false"
              :searchable="false"
              @input="hasViewPermission ? getListAndSummary() : undefined"
            >
            </multiselect>
          </div>
          <span class="font-500 pointer text-primary" @click="resetFilter"
            >Reset Filter</span
          >
        </div>

        <template v-if="hasViewPermission">
          <!-- Charts -->
          <div v-if="isShowSummaryChart" class="row mb-2">
            <div class="col-12 col-md">
              <CanvasJSChart
                :options="summaryChartOptions"
                :styles="{ height: '270px' }"
              />
            </div>
            <div v-if="isShowSatisfiedChart" class="col-12 col-md">
              <CanvasJSChart
                :options="satisfiedChartOptions"
                :styles="{ height: '270px' }"
              />
            </div>
            <div v-if="isShowUnsatisfiedChart" class="col-12 col-md">
              <CanvasJSChart
                :options="unsatisfiedChartOptions"
                :styles="{ height: '270px' }"
              />
            </div>
          </div>

          <!-- Table -->
          <div class="table-responsive">
            <table class="table mb-0">
              <thead>
                <tr>
                  <th scope="col" class="font-600 text-black">Konsul ID</th>
                  <th scope="col" class="font-600 text-black">
                    Submit Date & Time
                  </th>
                  <th scope="col" class="font-600 text-black">Doctor</th>
                  <th scope="col" class="font-600 text-black">Polyclinic</th>
                  <th scope="col" class="font-600 text-black">Survey Result</th>
                  <th scope="col" class="font-600 text-black">Result</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(result, key) in results.rows" :key="key">
                  <td>{{ result.consultId }}</td>
                  <td>
                    {{ result.submittedAt | moment('DD MMM YYYY HH.mm') }}
                  </td>
                  <td>{{ result.doctorName }}</td>
                  <td>{{ result.polyClinicName }}</td>
                  <td>
                    {{ getSatisfactionLevelLabel(result.satisfactionLevel) }}
                  </td>
                  <td>
                    {{
                      result.reasons && result.reasons.length === 0
                        ? '-'
                        : result.reasons.join(', ')
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col text-center mt-1" v-if="results.rows.length === 0">
            <p class="f-14">There is no data</p>
          </div>
        </template>
      </div>
    </div>
    <div class="card">
      <div
        class="card-body pl-3 d-flex flex-row justify-content-between flex-wrap"
        style="gap: 1rem"
      >
        <PaginateList :data="results" v-if="hasViewPermission" />
        <button
          v-if="hasDownloadPermission"
          type="button"
          class="btn btn-sm btn-primary"
          @click="download"
          :disabled="isLoadingDownload"
        >
          <span v-if="isLoadingDownload">
            Downloading <i class="fa fa-spinner fa-spin"></i>
          </span>
          <span v-else>Download</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import PaginateList from '@/components/PaginateList';
import CanvasJSChart from '@/components/CanvasJSChart';

export default {
  components: {
    PaginateList,
    CanvasJSChart,
  },
  data() {
    return {
      results: {
        rows: [],
        currentPage: 1,
        totalPages: 1,
      },
      limit: 10,
      isLoadingDownload: false,
      // filters
      date: [
        this.$moment().subtract(30, 'days').format('YYYY-MM-DD'),
        this.$moment().format('YYYY-MM-DD'),
      ],
      doctor: null,
      doctorOptions: [],
      surveyResult: null,
      polyclinic: null,
      polyclinicOptions: [],

      // charts
      isShowSummaryChart: true,
      summaryChartOptions: {
        height: 270,
        theme: 'light2',
        animationEnabled: false,
        title: {
          text: 'Summary',
          fontSize: 18,
        },
        data: [
          {
            type: 'pie',
            indexLabel: '{label} (#percent%)',
            indexLabelFontSize: 11,
            yValueFormatString: '#,##0',
            toolTipContent:
              "<span style='\"'color: {color};'\"'>{label}</span> {y} (#percent%)",
            dataPoints: [],
          },
        ],
      },
      isShowSatisfiedChart: true,
      satisfiedChartOptions: {
        height: 270,
        theme: 'light2',
        animationEnabled: false,
        title: {
          text: 'Puas',
          fontSize: 18,
        },
        data: [
          {
            type: 'pie',
            indexLabelFormatter: (e) => {
              const labelWords = e.dataPoint.label.split(' ');
              // truncate first 4 words
              if (labelWords.length > 4) {
                return `${labelWords
                  .slice(0, 4)
                  .join(' ')}... (${e.percent.toFixed(2)}%)`;
              }
              return `${e.dataPoint.label} (${e.percent.toFixed(2)}%)`;
            },
            indexLabelFontSize: 9,
            yValueFormatString: '#,##0',
            toolTipContent:
              "<span style='\"'color: {color};'\"'>{label}</span> {y} (#percent%)",
            dataPoints: [],
          },
        ],
      },
      isShowUnsatisfiedChart: true,
      unsatisfiedChartOptions: {
        height: 270,
        theme: 'light2',
        animationEnabled: false,
        title: {
          text: 'Kecewa',
          fontSize: 18,
        },
        data: [
          {
            type: 'pie',
            indexLabelFormatter: (e) => {
              const labelWords = e.dataPoint.label.split(' ');
              if (labelWords.length > 4) {
                // truncate first 4 words
                return `${labelWords
                  .slice(0, 4)
                  .join(' ')}... (${e.percent.toFixed(2)}%)`;
              }
              return `${e.dataPoint.label} (${e.percent.toFixed(2)}%)`;
            },
            indexLabelFontSize: 9,
            yValueFormatString: '#,##0',
            toolTipContent:
              "<span style='\"'color: {color};'\"'>{label}</span> {y} (#percent%)",
            dataPoints: [],
            // ],
          },
        ],
      },
    };
  },
  computed: {
    permissions() {
      return this.$store.getters.permissions;
    },
    hasViewPermission() {
      return this.permissions.includes('satisfaction_survey:view');
    },
    hasDownloadPermission() {
      return this.permissions.includes('satisfaction_survey:download');
    },
  },
  mounted() {
    this.getDoctorOptions();
    this.getPolyclinicOptions();
    if (this.hasViewPermission) {
      this.getListAndSummary();
      this.$root.$on('paginate', (data) => {
        this.getList(data);
      });
    }
  },
  methods: {
    getSummary() {
      this.axios
        .get('/v2/clinical/satisfactions/summary', {
          params: {
            startDate: this.date[0],
            endDate: this.date[1],
            doctorId: this.doctor?.id ?? undefined,
            satisfactionLevel: this.surveyResult?.value ?? undefined,
            polyClinicId: this.polyclinic?.id ?? undefined,
          },
        })
        .then((res) => {
          const { data } = res.data;
          this.isShowSatisfiedChart = data.SATISFIED.count > 0;
          this.isShowUnsatisfiedChart = data.UNSATISFIED.count > 0;
          this.isShowSummaryChart =
            this.isShowSatisfiedChart || this.isShowUnsatisfiedChart;

          this.summaryChartOptions.data[0].dataPoints = [
            { label: 'Puas', y: data.SATISFIED.count, color: '#51CDA0' },
            { label: 'Kecewa', y: data.UNSATISFIED.count, color: '#DF7970' },
          ];

          this.satisfiedChartOptions.data[0].dataPoints =
            data.SATISFIED.reasons.map((reason) => ({
              label: reason.label,
              y: reason.count,
            }));

          this.unsatisfiedChartOptions.data[0].dataPoints =
            data.UNSATISFIED.reasons.map((reason) => ({
              label: reason.label,
              y: reason.count,
            }));
          this.$forceUpdate();
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: err.response.data.message,
          });
        });
    },
    disabledDate(date, currentValue) {
      if (currentValue[0] && currentValue[1]) return false;

      const maxNinetyDaysFuture =
        currentValue[0] && this.$moment(date).isAfter(currentValue[0], 'day')
          ? date > new Date(currentValue[0].getTime() + 90 * 24 * 3600 * 1000)
          : false;
      const maxNinetyDaysPast =
        currentValue[0] && this.$moment(date).isBefore(currentValue[0], 'day')
          ? date < new Date(currentValue[0].getTime() - 90 * 24 * 3600 * 1000)
          : false;
      return maxNinetyDaysFuture || maxNinetyDaysPast;
    },
    getDoctorOptions() {
      this.axios
        .get('/v2/misc/doctors', {
          params: {
            page: 1,
            includesDeleted: true,
          },
        })
        .then((resp) => {
          this.doctorOptions = resp.data.data.rows;
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: err.response.data.message,
          });
        });
    },
    getPolyclinicOptions() {
      this.axios
        .get('/v2/misc/polyclinics', {
          params: {
            page: 1,
            includesDeleted: true,
          },
        })
        .then((resp) => {
          this.polyclinicOptions = resp.data.data.rows;
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: err.response.data.message,
          });
        });
    },
    getList(page = 1) {
      this.axios
        .get('/v2/clinical/satisfactions', {
          params: {
            page,
            limit: this.limit,
            startDate: this.date[0],
            endDate: this.date[1],
            doctorId: this.doctor?.id ?? undefined,
            satisfactionLevel: this.surveyResult?.value ?? undefined,
            polyClinicId: this.polyclinic?.id ?? undefined,
          },
        })
        .then((resp) => {
          this.results = resp.data.data;
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: err.response.data.message,
          });
        });
    },
    getListAndSummary() {
      this.getList();
      this.getSummary();
    },
    getSatisfactionLevelLabel(satisfactionLevel) {
      switch (satisfactionLevel) {
        case 'SATISFIED':
          return 'Puas';
        case 'UNSATISFIED':
          return 'Kecewa';
        default:
          return '-';
      }
    },
    download() {
      this.axios
        .post(
          '/v2/clinical/satisfactions/download',
          {
            startDate: this.date[0],
            endDate: this.date[1],
            doctorId: this.doctor?.id ?? undefined,
            satisfactionLevel: this.surveyResult?.value ?? undefined,
            polyClinicId: this.polyclinic?.id ?? undefined,
          },
          { responseType: 'arraybuffer' }
        )
        .then((res) => {
          const blob = new Blob([res.data], { type: 'text/csv' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `Satisfaction survey result ${this.$moment().format(
            'DD-MM-YYYY'
          )}.csv`;
          link.click();
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: err.response.data.message,
          });
        })
        .finally(() => {
          this.isLoadingDownload = false;
        });
    },
    resetFilter() {
      this.date = [
        this.$moment().subtract(30, 'days').format('YYYY-MM-DD'),
        this.$moment().format('YYYY-MM-DD'),
      ];
      this.doctor = null;
      this.polyclinic = null;
      this.surveyResult = null;
      if (this.hasViewPermission) {
        this.getListAndSummary();
      }
    },
  },
};
</script>

<style scoped>
.mb-0\.25 {
  margin-bottom: 0.25rem;
}
.gap-1 {
  gap: 1rem;
}
.flex-1 {
  flex: 1;
}
</style>
