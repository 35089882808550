<template>
  <div class="wrapper">
    <div
      v-if="clinicTeam.status"
      class="status-indicator"
      :style="{ backgroundColor: getStatusIndicatorColor(clinicTeam.status) }"
    ></div>
    <div class="image-wrapper">
      <img :src="clinicTeam.avatar" width="32" height="32" alt="avatar" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    clinicTeam: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getStatusIndicatorColor(status) {
      switch (status) {
        case 'AVAILABLE':
          return '#16b777';
        case 'BOOKED':
          return '#ffae62';
        case 'OFF':
          return '#eb5757';
        case 'UNREGISTERED':
          return '#111820';
        default:
          return '';
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  display: inline-flex;
}

.image-wrapper {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  overflow: hidden;
}

.image-wrapper img {
  object-fit: cover;
  object-position: center;
}

.status-indicator {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid white;
  z-index: 1;
}
</style>
