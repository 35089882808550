<template>
    <div class="mw-100 p-2">
        <div 
            class="card w-100 p-0" 
            :style="`max-width:720px;`"
        >
            <div class="card-body">
                <ValidationObserver ref="observer">
                    <form @submit.prevent="save()">
                        <h3 class="font-600 mb-2">Ubah Profile</h3>
                        <div class="row">
                            <div class="col-lg-4 col-xs-12 text-center mb-2">
                                <div class="profile-avatar">
                                    <img :src="model.avatar ? model.avatar:'/assets/img/icon/profile.png'" class="img-fluid" alt="">
                                    <div class="edit-avatar">
                                        <label for="file-upload" class="btn-edit">
                                            <i class="fa fa-pencil"></i>
                                        </label>
                                        <input id="file-upload" class="input-file-avatar" type="file" v-on:change="handleFileUpload">
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <ValidationProvider name="email" v-slot="{ errors }" rules="required|email">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="" class="f-14 text-secondary">Email <span class="text-danger">*</span></label>
                                        <input type="text" v-model="model.email" class="form-control" placeholder="Enter email" disabled>
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>

                                <ValidationProvider name="name" v-slot="{ errors }" rules="required">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="" class="f-14 text-secondary required">Nama <span class="text-danger">*</span></label>
                                        <input type="text" v-model="model.fullName" class="form-control" placeholder="Enter nama">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>

                                <ValidationProvider name="no. telp" v-slot="{ errors }">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="" class="f-14 text-secondary">No. Telepon</label>
                                        <input type="text" v-model="model.mobileNumber" class="form-control" placeholder="Enter telepon">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>

                                <ValidationProvider name="sip" v-slot="{ errors }">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="" class="f-14 text-secondary">SIP</label>
                                        <input type="text" v-model="model.sip" class="form-control" placeholder="Enter SIP">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>

                                <ValidationProvider name="str" v-slot="{ errors }">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="" class="f-14 text-secondary">STR</label>
                                        <input type="text" v-model="model.str" class="form-control" placeholder="Enter STR">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                                
                                <div class="form-group">
                                    <label class="f-14 text-secondary" style="margin-bottom: .25rem;">Gender</label>
                                    <div class="gender-wrapper">
                                        <button 
                                            type="button" 
                                            class="gender left" 
                                            :class="{ active: model.gender === 'M' }"
                                            @click="setGender('M')"
                                        >
                                            Laki-Laki
                                        </button>
                                        <button 
                                            type="button" 
                                            class="gender right" 
                                            :class="{ active: model.gender === 'F' }"
                                            @click="setGender('F')"
                                        >
                                            Perempuan
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="">
                            <router-link to="/" class="btn text-danger">Batal</router-link>
                            <button type="submit" class="btn btn-primary" :disabled="isLoading">
                                <div v-if="isLoading">Menyimpan <i class="fa fa-spinner fa-spin"></i></div>
                                <div v-else>Simpan</div>
                            </button>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            model: {
                id: null,
                email: "",
                fullName: "",
                mobileNumber: "",
                sip: "",
                str: "",
                avatar: "",
                gender: null,
                admin: {}
            },
            temp_photo: null,
            isLoading: false
        }
    },
    computed: {
        account() {
            return this.$store.state.account;
        },
    },
    mounted() {
        if (this.account) {
            this.model = {
                id: this.account.id,
                email: this.account.email,
                fullName: this.account.admin.fullName,
                mobileNumber: this.account.admin.mobileNumber,
                sip: this.account.admin.sip,
                str: this.account.admin.str,
                avatar: this.account.admin.avatar,
                gender: this.account.admin.gender,
                admin: this.account.admin,
            };
        }
    },
    watch: {
        account: function(account) {
            if (account) {
                this.model = {
                    id: account.id,
                    email: account.email,
                    fullName: account.admin.fullName,
                    mobileNumber: account.admin.mobileNumber,
                    sip: account.admin.sip,
                    str: account.admin.str,
                    avatar: account.admin.avatar,
                    gender: account.admin.gender,
                    admin: account.admin,
                };
            }
        }
    },
    methods: {
        setGender(value) {
            this.model.gender = value;
            this.$forceUpdate();
        },
        async save() {
            let _ = this
            const isValid = await _.$refs.observer.validate()
            if (isValid) {
                let data = {
                    email: _.model.email,
                    firstName: _.model.fullName.split(' ')[0],
                    lastName: _.model.fullName.split(' ').filter( (n,i) => i > 0 ).join(' '),
                    mobileNumber: _.model.mobileNumber,
                    sip: _.model.sip,
                    str: _.model.str,
                    avatar: _.model.avatar,
                    gender: _.model.gender || undefined,
                }
                _.isLoading = true
                _.axios.put('admins/' + _.model.admin.id, data)
                    .then(() => {
                        _.$swal.fire({
                            title: 'Data Saved!',
                            text: 'Profile Updated',
                            icon: 'success',
                            confirmButtonColor: '#0036A0',
                            confirmButtonText: 'OK'
                        })

                        _.isLoading = false
                        this.$store.dispatch('getMe');
                        _.$router.push('/')
                    })
                    .catch(err => {
                        _.$notify({
                            type: 'error',
                            title: 'Error!',
                            text: err.response.data.message,
                        });
                        _.isLoading = false
                    })
            }
        },
        handleFileUpload(event){
            this.file = event.target.files[0]

            let formData = new FormData()
            formData.append('folder', 'avatars')
            formData.append('file', this.file)

            this.axios.post('/misc/upload',
                formData,
                {
                  headers: {
                      'Content-Type': 'multipart/form-data'
                  }
                }).then(resp => {
                    if ( resp.data.success ) {
                      let file = resp.data.data.file
                      this.model.avatar = file
                    } else {
                      this.model.avatar = 'https://via.placeholder.com/150?text=dummy+image'
                    }
                }).catch(err => {
                    console.log(err.response)
                })
          },
    }
}
</script>

<style scoped>
.gender-wrapper {
    display: flex;
}
.gender {
    flex: 1;
    cursor: pointer;
    padding: 12px;
    font-size: 14px;
    background: #FFFFFF;
    color: #626262;
    border: 1px solid #626262;
}
.gender.left{
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
.gender.right{
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
.gender.active {
    background: #0036A0;
    color: #FFFFFF;
    border: 1px solid #0036A0;
}
</style>