export default {
    UPDATE_USER(state, payload) {
    	state.account = payload;
    },
    LOGOUT_USER(state) {
    	state.account = null;
    },
    UPDATE_PERMISSIONS(state, payload) {
    	state.permissions = payload;
    },
    UPDATE_PUSHER_CONNECTION_STATE(state, payload) {
        state.pusherConnectionState = payload;
    },
    UPDATE_CONFIG(state, payload) {
        const { key, value } = payload;
        state.config[key] = {
            ...state.config[key],
            ...value,
        };
    },
}