<template>
  <VTooltip placement="bottom">
    <div
      class="chips"
      @mouseenter="isHovered = true"
      @mouseleave="isHovered = false"
    >
      <div
        v-if="editable && isHovered"
        class="circle overlay border-dashed-primary-blue cursor-pointer"
        @click="$emit('clickEdit')"
      >
        <img
          src="/assets/img/icon/edit-icon-blue.svg"
          width="16"
          height="16"
          alt=""
        />
      </div>
      <div v-if="clinicTeam === null" class="circle border-dashed">
        <span class="initial-role-name">{{ role.name[0] }}</span>
      </div>
      <ClinicTeamAvatar v-else :clinicTeam="clinicTeam"></ClinicTeamAvatar>
    </div>
    <template #popper>
      <span v-if="clinicTeam === null" class="f-10"
        >Unassigned {{ role.name }}</span
      >
      <div v-else class="d-flex flex-column f-10">
        <span>{{ role.name }}:</span>
        <span>{{ clinicTeam.name }}</span>
      </div>
    </template>
  </VTooltip>
</template>

<script>
import ClinicTeamAvatar from './ClinicTeamAvatar.vue';

export default {
  components: {
    ClinicTeamAvatar,
  },
  props: {
    clinicTeam: Object,
    role: Object,
    editable: Boolean,
  },
  data() {
    return {
      isHovered: false,
    };
  },
  methods: {
    getStatusIndicatorColor(status) {
      switch (status) {
        case 'AVAILABLE':
          return '#16b777';
        case 'BOOKED':
          return '#ffae62';
        case 'OFF':
          return '#eb5757';
        case 'UNREGISTERED':
          return '#111820';
        default:
          return '';
      }
    },
  },
};
</script>

<style scoped>
.chips {
  border-radius: 50%;
  position: relative;
  display: inline-flex;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.circle {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.border-dashed {
  border: 1px dashed #bcc0d1;
}

.border-dashed-primary-blue {
  border: 1px dashed #0072cd;
}

.initial-role-name {
  color: #798ea5;
  font-size: 16px;
  font-weight: 600;
}
</style>
