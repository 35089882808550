<template>
    <UserForm pageType="add" />
</template>

<script>
    import UserForm from './components/UserForm.vue'

    export default {
        components: {
            UserForm
        },
        mounted() {
            if (!this.hasAddPermission) {
                window.location.assign('/offline-clinic-booking');
            }
        },
        computed: {
            permissions() {
                return this.$store.getters.permissions;
            },
            hasAddPermission() {
                return this.permissions.includes('offline_clinic_booking:add');
            },
        },
    }
</script>