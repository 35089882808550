<template>
    <div class="mw-100 p-2">
        <div class="section">
            <div class="card">
                <div class="card-body">
                    <div class="row gap-1">
                        <div class="col-12">
                            <p class="f-16 font-600 text-black">User Information</p>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <multiselect
                                v-model="searchParam"
                                :options="[
                                    { value: 'mobileNumber', label: 'Search by Phone Number' },
                                    { value: 'name', label: 'Search by Name' },
                                    { value: 'email', label: 'Search by Email' },
                                ]"
                                label="label"
                                track-by="value"
                                :show-labels="false"
                                :multiple="false"
                                :searchable="false"
                                :allow-empty="false"
                                @input="handleChangeSearchParam()"
                            ></multiselect>
                        </div>
                        <div class="input-group col-lg-4 col-md-6 col-sm-12">
                            <span class="input-group-prepend">
                                <div class="input-group-text border-right-0 bg-transparent text-blue">
                                    <i class="fa fa-search"></i>
                                </div>
                            </span>
                            <input class="form-control border-left-0" v-model="search" type="search" placeholder="Cari user..." @input="handleSearch()">
                        </div>
                    </div>     
                    <div v-if="search.length > 0" class="mt-1">
                        <p class="text-danger">
                            {{  
                                !results.rows.length
                                    ? 'Account is not found, please create a new account.'
                                    : 'Please select or create new account.' 
                            }}
                        </p>
                        <div class="btn-responsive">
                            <router-link to="/offline-clinic-booking/user/add" class="btn btn-primary rounded">
                                Create New Account
                            </router-link>
                        </div>
                    </div> 
                    <div v-if="results.rows.length > 0 && search !== ''" class="table-responsive">
                        <table class="table mt-2">
                            <thead>
                                <tr>
                                    <th class="font-600 text-black text-left">User ID</th>
                                    <th class="font-600 text-black text-left">Name</th>
                                    <th class="font-600 text-black text-left">Email</th>
                                    <th class="font-600 text-black text-left">Phone Number</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(user, userIdx) in results.rows" :key="userIdx">
                                    <td class="text-left align-top">
                                        <router-link
                                            :to="`/offline-clinic-booking/user/${user.id}`"
                                            class="text-light-blue font-500"
                                        >
                                            {{ user.id }}
                                        </router-link>
                                    </td>
                                    <td class="text-left align-top">
                                        <div class="d-flex align-items-center gap-1">
                                            <span v-if="user.isVip">👑</span>
                                            <img
                                                v-if="user.isSubscribed"
                                                src="/assets/img/icon/diri-xtra-logo.svg"
                                                width="24"
                                                height="24"
                                                alt=""
                                            />
                                            <img
                                                v-if="user.isLowValueCustomer"
                                                src="/assets/img/icon/low-value-customer.png"
                                                width="18"
                                                height="18"
                                                alt=""
                                            />
                                            <span class="text-truncate">{{ user.name }}</span>
                                        </div>
                                    </td>
                                    <td class="text-left align-top">{{ user.email }}</td>
                                    <td class="text-left align-top">{{ user.mobileNumber }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body d-flex gap-1">
                    <button
                        type="button"
                        class="btn btn-primary btn-sm rounded"
                        @click="prevPagination()"
                        :disabled="results.currentPage === 1"
                    >
                        <i class="fa fa-chevron-left"></i>
                    </button>
                    <button
                        type="button"
                        class="btn btn-primary btn-sm rounded"
                        @click="nextPagination()"
                        :disabled="results.totalPages < 1 || results.currentPage === results.totalPages"
                    >
                        <i class="fa fa-chevron-right"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { debounce } from "debounce";
    export default {
        data() {
            return {
                results: {
                    rows: [],
                    currentPage: 1,
                    totalPages: 1,
                },
                limit: 10,
                search: '',
                searchParam: { value: 'mobileNumber', label: 'Search by Phone Number' },
            }
        },
        mounted() {
            if (this.hasAddPermission) {
                this.getList();
            } else {
                window.location.assign('/offline-clinic-booking');
            }
        },
        computed: {
            permissions() {
                return this.$store.getters.permissions;
            },
            hasAddPermission() {
                return this.permissions.includes('offline_clinic_booking:add');
            },
            sanitizedSearch() {
                if (this.searchParam.value === 'email') {
                    return this.search.replace(/ /g, '');
                }
                if (this.searchParam.value === 'mobileNumber') {
                    const trimmedValue = this.search.replace(/ /g, '');
                    if (trimmedValue.startsWith('+62')) {
                        return `0${trimmedValue.slice(3)}`;
                    }
                    return trimmedValue;
                }
                return this.search;
            },
        },
        methods: {
            handleChangeSearchParam: debounce(function() {
                if (this.search) {
                    this.getList();
                }
            }, 500),
            handleSearch: debounce(function() {
                this.getList();
            }, 500),
            prevPagination() {
                this.getList(this.results.currentPage - 1);
            },
            nextPagination() {
                this.getList(this.results.currentPage + 1);
            },
            getList(page = 1) {
                const params = {
                    page,
                    limit: this.limit,
                };
                if (this.search) {
                    params[this.searchParam.value] = this.sanitizedSearch;
                    this.axios.get('/v2/clinical/offline-clinic-booking/users', { params })
                        .then((res) =>{
                            this.results = res.data.data;
                        })
                } else {
                    this.results = {
                        rows: [],
                        currentPage: 1,
                        totalPages: 1,
                    };
                }
            },
        },
    }
</script>

<style scoped>
    .gap-1 {
        gap: 0.5rem;
    }
</style>