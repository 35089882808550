<template>
  <dropdown-menu
    @open="handleOpenDropdown"
    @close="handleCloseDropdown"
    :width="width"
    :scopeClass="dropdownScopeClass"
  >
    <div slot="trigger" class="input" ref="trigger">
      <p v-if="!isOpen || (isOpen && !searchable)" class="text-truncate mb-0">
        {{ renderedLabel }}
      </p>
      <input
        v-if="isOpen && searchable"
        type="text"
        v-model="searchText"
        @input="handleSearch"
        placeholder="Search..."
        class="input-search"
        @click="handleClickSearchInput"
      />
      <span>
        <i class="fa fa-chevron-down" :class="{ 'rotate-180': isOpen }"></i>
      </span>
    </div>
    <div
      slot="content"
      :style="{ width: `${contentWidth}px` }"
    >
      <div v-if="options.length === 0" class="options">
        <span class="option-not-found">Options not found</span>
      </div>
      <div v-else class="options">
        <label
          v-for="(option, idx) in options"
          :key="idx"
          class="option"
        >
          <input
            type="checkbox"
            :checked="valueArray.includes(option.value)"
            @input="(e) => handleClickOption(option, e.target.checked)"
          />
          <span class="text-truncate">{{ option.label }}</span>
        </label>
      </div>
    </div>
  </dropdown-menu>
</template>

<script>
import DropdownMenu from './DropdownMenu/DropdownMenu.vue';
import { debounce } from 'debounce';

export default {
  components: {
    DropdownMenu,
  },
  props: {
    width: {
      type: String,
    },
    options: {
      type: Array,
      default: function () {
        return [];
      },
    },
    value: {
      type: Array,
      default: function () {
        return [];
      },
    },
    placeholder: {
      type: String,
      default: 'Select',
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    dropdownScopeClass: {
      type: String,
    },
  },
  data() {
    return {
      searchText: '',
      isOpen: false,
      contentWidth: 0,
    };
  },
  computed: {
    valueArray() {
      return this.value.map((item) => item.value);
    },
    renderedLabel() {
      if (this.value.length === 0) return this.placeholder;

      const optionValueArray = this.options.map((item) => item.value);
      if (
        optionValueArray.every((optionValue) =>
          this.valueArray.includes(optionValue)
        )
      )
        return 'All';

      return this.value.map((item) => item.label).join(', ');
    },
  },
  methods: {
    handleClickSearchInput(e) {
      e.stopPropagation();
    },
    handleOpenDropdown() {
      this.isOpen = true;
      this.contentWidth = this.$refs.trigger.offsetWidth;
    },
    handleCloseDropdown() {
      this.isOpen = false;
      this.searchText = '';
      this.$emit('search', '');
    },
    handleClickOption(option, isChecked) {
      if (isChecked) {
        this.$emit('input', [...this.value, option]);
      } else {
        this.$emit(
          'input',
          this.value.filter((item) => item.value !== option.value)
        );
      }
    },
    handleSearch: debounce(function () {
      this.$emit('search', this.searchText);
    }, 1000),
  },
};
</script>

<style scoped>
.input {
  width: 100%;
  height: 40px;
  padding-inline: 12px;
  border: 1px solid #bcc0d1;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

.input-search {
  border: none;
  outline: none;
  flex: 1;
  font-size: 14px;
  padding: 0;
  height: 100%;
}

.rotate-180 {
  transform: rotate(180deg);
}

.options {
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow: auto;
  padding: 0.5rem;
}

.option-not-found {
  padding-inline: 0.75rem;
  padding-block: 0.5rem;
}

.option {
  cursor: pointer;
  padding-inline: 0.75rem;
  padding-block: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.option:hover {
  background-color: #ddedfa;
  border-radius: 8px;
}
</style>
