<template>
    <div class="mw-100 p-2">
        <div class="card col-lg-6 col-xs-12 p-0">
            <div class="card-body">
                <h3 class="font-600 mb-2">Ubah Password</h3>
                <div class="row">
                    <div class="col-lg-2 col-xs-12 text-center mb-1">
                        <button class="btn btn-rounded btn-primary p-1">
                            <span class="material-icons" style="font-size: 35px;">lock</span>
                        </button>
                    </div>
                    <div class="col align-self-center">
                        <p class="f-14">Untuk melindungi keamanan akun Anda, masukkan kata sandi Anda saat ini. Password baru akan digunakan untuk login, mohon masukkan minimal 8 karakter</p>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col">
                        <ValidationObserver ref="observer2">
                            <form @submit.prevent="isChange()">
                                <ValidationProvider name="password saat ini" rules="required|min:8" v-slot="{ errors }" ref="oldPassword">
                                    <div class="form-group input-group mb-0" :class="{ 'validation-warning': errors[0] }">                            
                                        <input :type="show_oldPassword ? 'text' : 'password'" v-model="model.oldPassword" class="form-control border-right-0" placeholder="Masukkan password saat ini">
                                        <label class="form-control-placeholder" for="password">PASSWORD SAAT INI</label>
                                        <span class="input-group-append">
                                            <div class="input-group-text">
                                                <a href="javascript:;" @click="show_oldPassword = !show_oldPassword" class="text-blue">
                                                    <i :class="show_oldPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                                                </a>
                                            </div>
                                        </span> 
                                    </div>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </ValidationProvider>
                                <hr>
                                <ValidationProvider name="password baru" rules="required|min:8" v-slot="{ errors }" ref="newPassword">
                                    <div class="form-group input-group mb-0" :class="{ 'validation-warning': errors[0] }">                            
                                        <input :type="show_newPassword ? 'text' : 'password'" v-model="model.newPassword" class="form-control border-right-0" placeholder="Masukkan password baru" ref="password baru">
                                        <label class="form-control-placeholder" for="password">PASSWORD BARU</label>
                                        <span class="input-group-append">
                                            <div class="input-group-text">
                                                <a href="javascript:;" @click="show_newPassword = !show_newPassword" class="text-blue">
                                                    <i :class="show_newPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                                                </a>
                                            </div>
                                        </span> 
                                    </div>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </ValidationProvider>
                                <ValidationProvider name="konfirmasi password baru" rules="required|confirmed:password baru|min:8" v-slot="{ errors }" ref="conf_newPassword">
                                    <div class="form-group input-group mb-0 mt-2" :class="{ 'validation-warning': errors[0] }">                            
                                        <input :type="show_conf_newPassword ? 'text' : 'password'" v-model="model.conf_newPassword" class="form-control border-right-0" placeholder="Masukkan konfirmasi password baru" data-vv-as="password baru">
                                        <label class="form-control-placeholder" for="password">KONFIRMASI PASSWORD BARU</label>
                                        <span class="input-group-append">
                                            <div class="input-group-text">
                                                <a href="javascript:;" @click="show_conf_newPassword = !show_conf_newPassword" class="text-blue">
                                                    <i :class="show_conf_newPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                                                </a>
                                            </div>
                                        </span> 
                                    </div>
                                    <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                                </ValidationProvider>
                                <p class="mt-1"><small><b>Hint</b>: Password harus setidaknya 8 karakter. Untuk membuatnya lebih kuat, gunakan huruf besar dan kecil, angka, dan simbol seperti ! " ? $% ^ )</small></p>
                                <hr>
                                <div class="mt-1">
                                    <router-link to="/" class="btn text-danger">Batal</router-link>
                                    <button type="submit" class="btn btn-primary" :disabled="isLoading">
                                        <div v-if="isLoading">Menyimpan <i class="fa fa-spinner fa-spin"></i></div>
                                        <div v-else>Simpan</div>
                                    </button>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            model: {
                oldPassword: null,
                newPassword: null,
                conf_newPassword: null
            },
            show_oldPassword: false,
            show_newPassword: false,
            show_conf_newPassword: false,
            isLoading: false,
            state: 2
        }
    },
    methods: {
        save() {
            let _ = this
            _.isLoading = true
            _.axios.put('accounts/password', _.model)
                .then(resp => {
                    _.$swal.fire({
                        title: resp.data.message,
                        text: 'Silahkan Login Kembali',
                        icon: 'success',
                        confirmButtonColor: '#0036A0',
                        confirmButtonText: 'OK'
                    })
                    
                    _.$store.dispatch('logout')
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message,
                    })
                    _.isLoading = false

                    err.response.data.data.forEach(elm => {
                        this.$refs[elm.context.key].applyResult({
                            errors: [elm.message],
                            valid: false,
                            failedRules: {}
                        });
                    });
                })
        },
        async isChange(){
            let _ = this
            const isValid = await _.$refs.observer2.validate()
            if (isValid){
                _.$swal.fire({
                    title: 'Apakah Anda yakin ingin mengubah password Anda?',
                    html: '<p class="popup-text-normal">Anda akan keluar secara otomatis ketika Anda mengubah password Anda.</p>',
                    icon: 'question',
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                    confirmButtonColor: '#0036A0',
                    confirmButtonText: 'Lanjutkan',
                    }).then((result) => {
                        if (result.value) {
                            _.save()
                        }
                    })
            }
        }
    }
}
</script>