<template>
	<div class="section mw-100 p-2">
		<div class="card">
			<div class="card-body">
                <div class="row">
                    <!-- Table Schedule Group By Clinic -->
                    <div class="col-12 col-md-6">
                        <div class="table-responsive">
                            <table class="table mb-0">
                                <thead>
                                    <tr>
                                        <th scope="col" class="font-600 text-black text-left">Clinic Name</th>
                                        <th scope="col" class="font-600 text-black text-left">Schedule</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(schedule, key) in scheduleGroupByClinic" :key="key">
                                        <td class="text-left align-top">{{ schedule.clinic.name }}</td>
                                        <td class="text-left align-top">
                                            <div v-for="(listSchedule, listScheduleKey) in schedule.schedule" :key="listScheduleKey">
                                                {{ listSchedule }}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="!scheduleGroupByClinic.length">
                                        <td class="text-left">-</td>
                                        <td class="text-left">-</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- Table Day Off -->
                    <div class="col-12 col-md-6">
                        <div class="table-responsive">
                            <table class="table mb-0">
                                <thead>
                                    <tr>
                                        <th scope="col" class="font-600 text-black text-left">Day Off</th>
                                    </tr>
                                </thead>
                                <tbody>
									<tr v-for="(dayOff, dayOffKey) in defaultData.dayOff" :key="dayOffKey">
                                        <td class="text-left">
                                            {{ dayOff.startDate | moment("DD MMM YY HH:mm") }} - {{ dayOff.endDate | moment("DD MMM YY HH:mm") }}
                                        </td>
									</tr>
                                    <tr v-if="Array.isArray(defaultData.dayOff) && !defaultData.dayOff.length">
                                        <td class="text-left">-</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <hr>
                <ValidationObserver ref="observer" slim>
                    <form @submit.prevent="submitForm()">
                        <div class="row">
                            <!-- Weekly Availability Form -->
                            <div class="col-xl-6 col-lg-12" id="weekly-availability">
                                <p class="text-black font-600">Weekly Availability</p>
                                <div
                                    v-for="(availability, availabilityKey) in weeklyAvailabilityFormData"
                                    class="d-flex gap-1 align-items-center"
                                    :key="availabilityKey"
                                    :style="`margin-top: ${availabilityKey === 0 ? 0 : 12}px; min-width: 520px;`"
                                >
                                    <div
                                        v-if="availability.schedule.length > 0"
                                        style="flex: 1;"
                                    >
                                        <div
                                            class="d-flex align-items-center gap-1 position-relative"
                                            v-for="(_, scheduleKey) in availability.schedule"
                                            :key="scheduleKey"
                                            :style="`padding-left: 50px; margin-top: ${scheduleKey === 0 ? 0 : 12}px;`"
                                        >
                                            <div
                                                v-if="scheduleKey === 0"
                                                class="text-center text-black position-absolute"
                                                style="width: 50px; top: 50%; transform: translateY(-50%); left: 0;"
                                            >
                                                {{ uppercaseDay(availability.day).slice(0,3)  }}
                                            </div>
                                            <div class="d-flex align-items-center gap-1" style="flex: 1;">
                                                <ValidationProvider
                                                    :name="`${availability.day}.${scheduleKey}.startTime`"
                                                    v-slot="{ errors }"
                                                    rules="required"
                                                    slim
                                                >
                                                    <div
                                                        :class="{ 'error-input-multiselect': errors[0] }"
                                                        style="flex: 0 0 100px"
                                                    >
                                                        <multiselect
                                                            v-model="availability.schedule[scheduleKey].startTime"
                                                            placeholder="Start"
                                                            :options="generateHourOptions(availability.day, scheduleKey, 'start')"
                                                            label="name"
                                                            track-by="id"
                                                            select-label=""
                                                            deselect-label=""
                                                            :multiple="false"
                                                            :allow-empty="false"
                                                            @input="$forceUpdate()"
                                                        >
                                                            <span slot="noOptions">Exceed maximum time.</span>
                                                            <span slot="noResult">Oops! No data found.</span>
                                                        </multiselect>
                                                    </div>
                                                </ValidationProvider>
                                                <div style="flex: 0 0 8px" class="text-center">-</div>
                                                <ValidationProvider
                                                    :name="`${availability.day}.${scheduleKey}.endTime`"
                                                    v-slot="{ errors }"
                                                    rules="required"
                                                    slim
                                                >
                                                    <div
                                                        :class="{ 'error-input-multiselect': errors[0] }"
                                                        style="flex: 0 0 100px"
                                                    >
                                                        <multiselect
                                                            v-model="availability.schedule[scheduleKey].endTime"
                                                            placeholder="End"
                                                            :options="generateHourOptions(availability.day, scheduleKey, 'end')"
                                                            label="name"
                                                            track-by="id"
                                                            select-label=""
                                                            deselect-label=""
                                                            :multiple="false"
                                                            :allow-empty="false"
                                                            :disabled="!availability.schedule[scheduleKey].startTime"
                                                            @input="$forceUpdate()"
                                                        >
                                                            <span slot="noOptions">Exceed maximum time.</span>
                                                            <span slot="noResult">Oops! No data found.</span>
                                                        </multiselect>
                                                    </div>
                                                </ValidationProvider>

                                                <ValidationProvider
                                                    :name="`${availability.day}.${scheduleKey}.clinic`"
                                                    v-slot="{ errors }"
                                                    rules="required"
                                                    slim
                                                >
                                                    <div
                                                        :class="{ 'error-input-multiselect': errors[0] }"
                                                        style="flex: 1"
                                                    >
                                                        <multiselect
                                                            v-model="availability.schedule[scheduleKey].clinic"
                                                            placeholder="Select Clinic"
                                                            :options="clinicNameOptions"
                                                            label="name"
                                                            track-by="id"
                                                            select-label=""
                                                            deselect-label="Reset"
                                                            :multiple="false"
                                                            :allow-empty="false"
                                                            @input="$forceUpdate()"
                                                        >
                                                            <span slot="noResult">Oops! No data found.</span>
                                                        </multiselect>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div
                                                class="d-flex align-items-center gap-2 justify-content-end f-18"
                                                style="flex: 0 0 50px;"
                                            >
                                                <div
                                                    class="cursor-pointer"
                                                    style="width: fit-content;"
                                                    @click="removeSchedule(availability.day, scheduleKey)"
                                                >
                                                    <i class="fa fa-trash"></i>
                                                </div>
                                                <div
                                                    :class="{
                                                        'cursor-pointer': scheduleKey === availability.schedule.length - 1,
                                                        'invisible': scheduleKey !== availability.schedule.length - 1,
                                                    }"
                                                    @click="
                                                        // only allow to add new schedule
                                                        // when current row is completely selected
                                                        (scheduleKey === availability.schedule.length - 1)
                                                        && (availability.schedule[scheduleKey].startTime
                                                        && availability.schedule[scheduleKey].endTime
                                                        && availability.schedule[scheduleKey].clinic)
                                                        && addNewSchedule(availability.day)
                                                    "
                                                    :style="`
                                                        width: fit-content;
                                                        margin-top: 2px;
                                                        ${(!availability.schedule[scheduleKey].startTime
                                                        || !availability.schedule[scheduleKey].endTime
                                                        || !availability.schedule[scheduleKey].clinic)
                                                        && 'opacity: 0.55;'}
                                                    `"
                                                >
                                                    <i class="fa fa-plus"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <template v-else>
                                        <div
                                            class="text-center text-black"
                                            style="flex: 0 0 50px;"
                                        >
                                            {{ uppercaseDay(availability.day).slice(0,3)  }}
                                        </div>
                                        <div class="d-flex align-items-center gap-1 w-100">
                                            <div style="flex: 1; padding: 6px 0; opacity: 0.65;">Unavailable</div>
                                            <div
                                                class="d-flex align-items-center gap-2 justify-content-end f-18"
                                                style="flex: 0 0 50px;"
                                            >
                                                <div
                                                    class="cursor-pointer"
                                                    style="width: fit-content;"
                                                    @click="addNewSchedule(availability.day)"
                                                >
                                                    <i class="fa fa-plus"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <!-- Day Off Form -->
                            <div class="col-xl-6 col-lg-12" id="day-off">
                                <p class="text-black font-600">Day Off</p>
                                <div
                                    class="d-flex align-items-center gap-1"
                                    v-for="(dayOff, dayOffKey) in dayOffFormData"
                                    :key="dayOffKey"
                                    :style="`margin-top: ${dayOffKey === 0 ? 0 : 12}px; min-width: 520px;`"
                                >
                                    <div class="d-flex align-items-center gap-1" style="flex: 1;">
                                        <ValidationProvider
                                            :name="`dayOff.${dayOffKey}.startDate`"
                                            v-slot="{ errors }"
                                            :rules="{
                                                required: true,
                                                // dayOff.id indicates that this is an existing day off
                                                // if existed, ignore the min date validation
                                                // so it will not cause error when existing day off is under current time 
                                                min_date: 
                                                    (dayOffKey === 0 && dayOff.id) 
                                                    ? undefined 
                                                    : generateMinDateTimeOptions(dayOffKey, 'start'),
                                                max_date: generateMaxDateTimeOptions(dayOffKey, 'start'),
                                            }"
                                            slim
                                        >
                                            <div
                                                :class="{ 'error-input-datetime': errors[0] }"
                                                style="flex: 1 1 50%"
                                            >
                                                <datetime
                                                    type="datetime" 
                                                    input-class="form-control" 
                                                    v-model="dayOffFormData[dayOffKey].startDate"
                                                    :min-datetime="generateMinDateTimeOptions(dayOffKey, 'start')"
                                                    :max-datetime="generateMaxDateTimeOptions(dayOffKey, 'start')"
                                                    placeholder="Start Date and Time"
                                                    class="theme-grey"
                                                    format="MMM dd, yyyy, HH:mm"
                                                >
                                                </datetime>
                                            </div>
                                        </ValidationProvider>
                                        <div style="flex: 0 0 8px" class="text-center">-</div>
                                        <ValidationProvider
                                            :name="`dayOff.${dayOffKey}.endDate`"
                                            v-slot="{ errors }"
                                            :rules="{
                                                required: true,
                                                min_date: generateMinDateTimeOptions(dayOffKey, 'end'),
                                                max_date: generateMaxDateTimeOptions(dayOffKey, 'end'),
                                            }"
                                            slim
                                        >
                                            <div
                                                :class="{ 'error-input-datetime': errors[0] }"
                                                style="flex: 1 1 50%"
                                            >
                                                <datetime
                                                    type="datetime" 
                                                    input-class="form-control"  
                                                    v-model="dayOffFormData[dayOffKey].endDate" 
                                                    :min-datetime="generateMinDateTimeOptions(dayOffKey, 'end')"
                                                    :max-datetime="generateMaxDateTimeOptions(dayOffKey, 'end')"
                                                    :disabled="!dayOffFormData[dayOffKey].startDate"
                                                    placeholder="End Date and Time"
                                                    class="theme-grey"
                                                    format="MMM dd, yyyy, HH:mm"
                                                >
                                                </datetime>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div
                                        class="d-flex align-items-center gap-2 justify-content-end f-18"
                                        style="flex: 0 0 50px;"
                                    >
                                        <div
                                            class="cursor-pointer"
                                            style="width: fit-content;"
                                            @click="removeDayOff(dayOffKey)"
                                        >
                                            <i class="fa fa-trash"></i>
                                        </div>
                                        <div
                                            :class="{
                                                'cursor-pointer': dayOffKey === dayOffFormData.length - 1,
                                                'invisible': dayOffKey !== dayOffFormData.length - 1,
                                            }"
                                            @click="
                                                // only allow to add new day off
                                                // when current row is completely selected
                                                (dayOffKey === dayOffFormData.length - 1
                                                && dayOffFormData[dayOffKey].startDate
                                                && dayOffFormData[dayOffKey].endDate)
                                                && addNewDayOff()
                                            "
                                            :style="`
                                                width: fit-content;
                                                margin-top: 2px;
                                                ${(!dayOffFormData[dayOffKey].startDate
                                                || !dayOffFormData[dayOffKey].endDate)
                                                && 'opacity: 0.55;'}
                                            `"
                                        >
                                            <i class="fa fa-plus"></i>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="!dayOffFormData.length" class="d-flex align-items-center gap-1 w-100">
                                    <div style="flex: 1; padding: 6px 0; opacity: 0.65;">Unavailable</div>
                                    <div
                                        class="d-flex align-items-center gap-2 justify-content-end f-18"
                                        style="flex: 0 0 50px;"
                                    >
                                        <div
                                            class="cursor-pointer"
                                            style="width: fit-content;"
                                            @click="addNewDayOff()"
                                        >
                                            <i class="fa fa-plus"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="d-flex justify-content-end">
                            <router-link to="/offline-clinic-schedule" class="btn text-danger">Batal</router-link>
                            <button type="submit" class="btn btn-primary" :disabled="isLoading">
                                <div v-if="isLoading">Menyimpan <i class="fa fa-spinner fa-spin"></i></div>
                                <div v-else>Simpan</div>
                            </button>
                        </div>
                    </form>
                </ValidationObserver>
			</div>
		</div>
	</div>
</template>
  
<script>
    import { hourOptions } from './constants/options';
	export default {
    	components: {
    	},
		data() {
			return {
                isLoading: false,
                defaultData: {},
                scheduleGroupByClinic: [],
                // options
                hourOptions,
                // form data
                weeklyAvailabilityFormData: [
                    {
                        day: 'monday',
                        schedule: [],
                    },
                    {
                        day: 'tuesday',
                        schedule: [],
                    },
                    {
                        day: 'wednesday',
                        schedule: [],
                    },
                    {
                        day: 'thursday',
                        schedule: [],
                    },
                    {
                        day: 'friday',
                        schedule: [],
                    },
                    {
                        day: 'saturday',
                        schedule: [],
                    },
                    {
                        day: 'sunday',
                        schedule: [],
                    },
                ],
                dayOffFormData: [],
				clinicNameOptions: [],
			};
		},
		computed: {
			permissions() {
				return this.$store.getters.permissions;
			},
			hasEditPermission() {
				return this.permissions.includes('offline_clinic_schedule:edit');
			},
		},
        mounted() {
            if (this.hasEditPermission) {
                this.getClinicNameOptions();
                this.getDetail();
            } else {
                // If user has only offline_clinic_schedule:view permission 
                window.location.assign('/offline-clinic-schedule');
            }
        },
        beforeDestroy() {
            this.$root.$emit('addAdditionalInfo', '')
        },
		methods: {
			uppercaseDay(day) {
				return `${day.charAt(0).toUpperCase()}${day.slice(1)}`;
			},
            getClinicNameOptions() {
				this.axios
					.get(`/v2/misc/offline-clinics?offlineClinicTeamId=${this.$route.params.id}&isActive=true`)
					.then((res) => {
						this.clinicNameOptions = res.data.data.rows;
					})
					.catch((err) => {
						this.$notify({
							type: 'error',
							title: 'Error',
							text: err.response.data.message,
						});
				});
			},
            getDetail() {
                this.axios.get(`/v2/clinical/offline-clinic-schedule/${this.$route.params.id}`)
                    .then((res) => {
                        this.defaultData = res.data.data;
                        this.scheduleGroupByClinic = this.generateScheduleGroupByClinic();
                        this.weeklyAvailabilityFormData = this.generateWeeklyAvailabilityFormData();
                        this.dayOffFormData = this.generateDayOffFormData();

                        const additionalInfo = `${this.defaultData.userName}${this.defaultData.isTodayOff ? ' <i class="fa fa-ban text-danger"></i>' : ''}`;
                        this.$root.$emit('addAdditionalInfo', additionalInfo);
                    })
            },
            generateScheduleGroupByClinic() {
                // group schedule by clinic
                return this.defaultData.schedule.reduce((acc, curr) => {
                    const newAcc = [...acc];
                    const clinicIndex = newAcc.findIndex((find) => find.clinic.id === curr.clinic.id);
                    const scheduleInfo = `${this.uppercaseDay(curr.day)}, ${curr.startTime} - ${curr.endTime}`;
                    if (clinicIndex >= 0) {
                        newAcc[clinicIndex].schedule.push(scheduleInfo);
                    } else {
                        newAcc.push({
                            clinic: { id: curr.clinic.id, name: curr.clinic.name },
                            schedule: [scheduleInfo],
                        });
                    }
                    return newAcc;
                }, []);
            },
            generateWeeklyAvailabilityFormData() {
                // group schedule by day
                return this.defaultData.schedule
                    .reduce((acc, curr) => {
                        const newAcc = [...acc];
                        const dayIndex = newAcc.findIndex((find) => find.day === curr.day);
                        const hourStartIndex = hourOptions.findIndex((find) => find.name === curr.startTime);
                        const hourEndIndex = hourOptions.findIndex((find) => find.name === curr.endTime);
                        newAcc[dayIndex].schedule.push({
                            clinic: { id: curr.clinic.id, name: curr.clinic.name },
                            startTime: {
                                id: hourOptions[hourStartIndex].id,
                                name: hourOptions[hourStartIndex].name,
                            },
                            endTime: {
                                id: hourOptions[hourEndIndex].id,
                                name: hourOptions[hourEndIndex].name,
                            },
                        });
                        return newAcc;
                    }, [...this.weeklyAvailabilityFormData])
                    .map((scheduleData) => {
                        return {
                            ...scheduleData,
                            // order schedule by start date
                            schedule: scheduleData.schedule.sort((a, b) => a.startTime.id - b.startTime.id)
                        }
                    });
            },
            generateDayOffFormData() {
                return this.defaultData.dayOff.map((dayOff) => {
                    return {
                        ...dayOff,
                        startDate: this.$moment(dayOff.startDate).toISOString(),
                        endDate: this.$moment(dayOff.endDate).toISOString(),
                    }
                })
            },
            generateHourOptions(day, scheduleIndex, time = 'start') {
                const dayIndex = this.weeklyAvailabilityFormData.findIndex((find) => find.day === day);
                const currentDayState = this.weeklyAvailabilityFormData[dayIndex];
                if (time === 'start') {
                    const prevEndTimeHourOptions = currentDayState.schedule.filter((filtered, filteredIndex) => (filteredIndex < scheduleIndex) && filtered.endTime);
                    if (prevEndTimeHourOptions.length > 0) {
                        if (currentDayState.schedule[scheduleIndex].endTime) {
                            // both current row end time and previous row end time are selected
                            return hourOptions.filter((filtered) =>
                                (filtered.id < currentDayState.schedule[scheduleIndex].endTime.id)
                                && (filtered.id >= prevEndTimeHourOptions[prevEndTimeHourOptions.length - 1].endTime.id));
                        }
                        // only previous row end time is selected
                        return hourOptions.filter((filtered) => (filtered.id >= prevEndTimeHourOptions[prevEndTimeHourOptions.length - 1].endTime.id));
                    }
                    if (currentDayState.schedule[scheduleIndex].endTime) {
                        // only current row end time is selected
                        return hourOptions.filter((filtered) => (filtered.id < currentDayState.schedule[scheduleIndex].endTime.id));
                    }
                } else {
                    const nextStartTimeHourOptions = currentDayState.schedule.filter((filtered, filteredIndex) => (filteredIndex > scheduleIndex) && filtered.startTime);
                    if (nextStartTimeHourOptions.length > 0) {
                        if (currentDayState.schedule[scheduleIndex].startTime) {
                            // both current row start time and next row start time are selected
                            return hourOptions.filter((filtered) => 
                                (filtered.id > currentDayState.schedule[scheduleIndex].startTime.id)
                                && (filtered.id <= nextStartTimeHourOptions[0].startTime.id));
                        }
                        // only next row start time is selected
                        return hourOptions.filter((filtered) => (filtered.id <= nextStartTimeHourOptions[0].startTime.id));
                    }
                    if (currentDayState.schedule[scheduleIndex].startTime) {
                        // only current row start time is selected
                        return hourOptions.filter((filtered) => (filtered.id > currentDayState.schedule[scheduleIndex].startTime.id));
                    }
                }
                // default
                return hourOptions;
            },
            addNewSchedule(day) {
                const dayIndex = this.weeklyAvailabilityFormData.findIndex((find) => find.day === day);
                this.weeklyAvailabilityFormData[dayIndex].schedule.push({
                    clinic: null,
                    startTime: null,
                    endTime: null,
                });
                this.$forceUpdate();
            },
            removeSchedule(day, scheduleIndex) {
                const dayIndex = this.weeklyAvailabilityFormData.findIndex((find) => find.day === day);
                this.weeklyAvailabilityFormData[dayIndex].schedule.splice(scheduleIndex, 1);
                this.$forceUpdate();
            },
            generateMinDateTimeOptions(dayOffIndex, time = 'start') {
                if (time === 'start') {
                    const prevEndDateTime = this.dayOffFormData.filter((filtered, filteredIndex) => (filteredIndex < dayOffIndex) && filtered.endDate);
                    if (prevEndDateTime.length > 0) {
                        // previous row end date is selected
                        return this.$moment(prevEndDateTime[prevEndDateTime.length - 1].endDate).add(1, 'minutes').toISOString();
                    }
                } else {
                    if (this.dayOffFormData[dayOffIndex].startDate) {
                        // current row start date is selected
                        return this.$moment(this.dayOffFormData[dayOffIndex].startDate).add(1, 'minutes').toISOString();
                    }
                }
                // default
                return this.$moment().toISOString();
            },
            generateMaxDateTimeOptions(dayOffIndex, time = 'start') {
                if (time === 'start') {
                    if (this.dayOffFormData[dayOffIndex].endDate) {
                        // current row end date is selected
                        return this.$moment(this.dayOffFormData[dayOffIndex].endDate).toISOString();
                    }
                } else {
                    const nextStartDateTime = this.dayOffFormData.filter((filtered, filteredIndex) => (filteredIndex > dayOffIndex) && filtered.startDate);
                    if (nextStartDateTime.length > 0) {
                        // next row start date is selected
                        return this.$moment(nextStartDateTime[0].startDate).toISOString();
                    }
                }
            },
            addNewDayOff() {
                this.dayOffFormData.push({
                    startDate: null,
                    endDate: null,
                });
                this.$forceUpdate();
            },
            removeDayOff(dayOffIndex) {
                this.dayOffFormData.splice(dayOffIndex, 1);
                this.$forceUpdate();
            },
            generatePayload() {
                const schedule = this.weeklyAvailabilityFormData.reduce((acc, curr) => {
                    const newAcc = [...acc];
                    curr.schedule.forEach((schedule) => {
                        newAcc.push({
                            clinicId: schedule.clinic.id,
                            day: curr.day,
                            startTime: schedule.startTime.name,
                            endTime: schedule.endTime.name,
                        });
                    })
                    return newAcc;
                }, []);

                const dayOff = this.dayOffFormData.map((off) => ({
                    ...off,
                    startDate: this.$moment(off.startDate).format('YYYY-MM-DD HH:mm'),
                    endDate: this.$moment(off.endDate).format('YYYY-MM-DD HH:mm'),
                }));

                return {
                    schedule,
                    dayOff,
                }
            },
            async submitForm() {
                const isValid = await this.$refs.observer.validate()
                if (isValid) {
                    const payload = this.generatePayload();
                    this.isLoading = true;
                    this.axios
                        .put(`/v2/clinical/offline-clinic-schedule/${this.$route.params.id}`, { ...payload })
                        .then((res) => {
                            this.$swal.fire({
                                title: res.data.message,
                                text: 'Schedule Updated',
                                icon: 'success',
                                confirmButtonColor: '#0036A0',
                                confirmButtonText: 'OK'
                            })
                            this.$router.push('/offline-clinic-schedule');
                        })
                        .catch((err) => {
                            this.$notify({
                                type: 'error',
                                title: 'Error!',
                                text: err.response.data.message,
                            });
                        })
                        .finally(() => {
                            this.isLoading = false;
                        });
                }
            },
		},
	};
</script>
  
<style scoped>
	.gap-1 {
		gap: 0.5rem;
	}
	.gap-2 {
		gap: 0.75rem;
	}
    @media screen and (max-width: 768px) {
        #weekly-availability, #day-off {
            overflow-x: auto;
        }
    }
    @media screen and (max-width: 1200px) {
        #day-off {
            margin-top: 18px;
        }
    }
</style>
  
<style>
    .error-input-multiselect .multiselect .multiselect__tags {
        border: 1.5px solid #dc2626 !important;
    }
    .error-input-multiselect .multiselect .multiselect__placeholder {
        color: #dc2626 !important;
    }
    .error-input-multiselect .multiselect .multiselect__select:before {
        border-color: #dc2626 transparent transparent !important;
    }
    .error-input-datetime .form-control {
        border: 1.5px solid #dc2626 !important;
    }
	.theme-grey .vdatetime-popup__header {
		background: #333;
	}
	.theme-grey .vdatetime-calendar__month__day--selected > span > span,
	.theme-grey .vdatetime-calendar__month__day--selected:hover > span > span {
		background: #0036A0;
	}
	.theme-grey .vdatetime-year-picker__item--selected,
	.theme-grey .vdatetime-time-picker__item--selected,
	.theme-grey .vdatetime-popup__actions__button {
		color: #0036A0;
	}

    .theme-grey .vdatetime-calendar__navigation--next path,
    .theme-grey .vdatetime-calendar__navigation--previous path {
        vector-effect: unset! important;
    }

    .theme-grey .vdatetime-overlay, .theme-grey .vdatetime-popup {
        z-index: 9999;
    }
</style>