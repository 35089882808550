export function getOrderTypeLabel(type) {
  switch (type) {
    case 1:
      return 'Konsultasi';
    case 2:
      return 'Belanja Rx';
    case 3:
      return 'Konsultasi Belanja Rx';
    case 4:
      return 'Belanja OTC';
    case 5:
      return 'Reaktivasi Konsultasi';
    case 6:
      return 'Subscription';
    default:
      return '-';
  }
}

export function getOrderTypeIcon(type) {
  switch (type) {
    case 2: // Belanja Rx
    case 4: // Belanja OTC
      return '/assets/img/icon/shop-icon.svg';
    default:
      return '/assets/img/icon/consult-icon.svg';
  }
}