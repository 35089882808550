<template>
    <div class="mw-100 p-2">
        <div class="row">
            <div class="col-12 col-md-4">
                <div class="card">
                    <div class="card-body">
                        <p class="f-16 font-600">Info Transaksi</p>
                        <div class="row mb-1">
                            <div class="col-5 f-14 text-secondary">Kode Transaksi</div>
                            <div class="col-7 f-14 font-500 text-right pr-1">{{ model.orderNumber }}</div>
                        </div>
                        <div class="row mb-1" v-if="model.statusDelivery == 5">
                            <div class="col-5 f-14 text-secondary">Kode Refund</div>
                            <div class="col-7 f-14 font-500 text-right pr-1">{{ model.refundId }}</div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-5 f-14 text-secondary align-self-center">Status Pesanan</div>
                            <div class="col-7 f-14 font-500 text-right pr-1">
                                <div :class="'btn btn-sm ' + statusColor(model.statusDeliveryDetail.code)">{{ model.statusDeliveryDetail.name }}</div>
                            </div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-5 f-14 text-secondary">Invoice</div>
                            <div class="col-7 f-14 font-500 text-right pr-1 text-blue">{{ model.invoice }}</div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-5 f-14 text-secondary">Tanggal Bayar</div>
                            <div class="col-7 f-14 font-500 text-right pr-1">
                                <span v-if="model.paymentSuccessTime">{{ model.paymentSuccessTime | moment('ll, HH:mm') }}</span>
                                <span v-else>-</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5 f-14 text-secondary">Tipe Transaksi</div>
                            <div class="col-7 f-14 font-500 text-right pr-1">
                                <span>{{ getOrderTypeLabel(model.type) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <p class="f-16 font-600">Rincian Pembayaran</p>
                        <div class="row mb-1">
                            <div class="col-5 f-14 text-secondary">Metode Pembayaran</div>
                            <div class="col-7 f-14 font-500 text-right pr-1">
                                {{ model.paymentChannel ? model.paymentChannel.name : '-' }}
                                <div v-if="model.paymentReference">
                                    <span v-if="model.paymentReference.va_numbers"> {{model.paymentReference.va_numbers[0].bank.toUpperCase()}}</span>
                                    <span v-else-if="model.paymentReference.issuer"> {{model.paymentReference.issuer.toUpperCase()}}</span>
                                    <span v-else-if="model.paymentReference.acquirer"> {{model.paymentReference.acquirer.toUpperCase()}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-5 f-14 text-secondary align-self-center">Total Biaya Konsultasi</div>
                            <div v-if="model.consultationCost > 0" class="col-7 f-14 font-500 text-right pr-1">{{ model.consultationCost | currency }}</div>
                            <div v-else class="col-7 f-14 font-500 text-right pr-1">-</div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-5 f-14 text-secondary align-self-center">Total Biaya Barang</div>
                            <div v-if="model.subtotal > 0" class="col-7 f-14 font-500 text-right pr-1">{{ model.subtotal | currency }}</div>
                            <div v-else class="col-7 f-14 font-500 text-right pr-1">-</div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-6 f-14 text-secondary">Kredit Konsultasi</div>
                            <div v-if="model.creditAmount > 0" class="col-6 f-14 font-500 text-right pr-1">{{ -model.creditAmount | currency }}</div>
                            <div v-else class="col-6 f-14 font-500 text-right pr-1">-</div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-5 f-14 text-secondary">Total Harga Ongkir</div>
                            <div v-if="model.shippingCost + model.shippingDiscount > 0" class="col-7 f-14 font-500 text-right pr-1">{{ model.shippingCost + model.shippingDiscount | currency }}</div>
                            <div v-else class="col-7 f-14 font-500 text-right pr-1">-</div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-5 f-14 text-secondary">Biaya Asuransi Pengiriman</div>
                            <div v-if="model.shippingInsuranceCost > 0" class="col-7 f-14 font-500 text-right pr-1">{{ model.shippingInsuranceCost | currency }}</div>
                            <div v-else class="col-7 f-14 font-500 text-right pr-1">-</div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-5 f-14 text-secondary">Total Potongan Ongkir</div>
                            <div v-if="model.shippingDiscount > 0" class="col-7 f-14 font-500 text-right pr-1">{{ -model.shippingDiscount | currency }}</div>
                            <div v-else class="col-7 f-14 font-500 text-right pr-1">-</div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-6 f-14 text-secondary">Total Potongan Diskon <span v-if="model.discount > 0">({{model.promoCode}})</span></div>
                            <div v-if="model.discount > 0" class="col-6 f-14 font-500 text-right pr-1">{{ -model.discount | currency }}</div>
                            <div v-else class="col-6 f-14 font-500 text-right pr-1">-</div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-6 f-14 text-secondary">Total Potongan Subscription</div>
                            <div v-if="model.subscriptionDiscount > 0" class="col-6 f-14 font-500 text-right pr-1">{{ -model.subscriptionDiscount | currency }}</div>
                            <div v-else class="col-6 f-14 font-500 text-right pr-1">-</div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-5 f-14 text-secondary">Total Harga</div>
                            <div class="col-7 f-14 font-500 text-right pr-1 text-danger">{{ model.grandtotal | currency }}</div>
                        </div>
                    </div>
                </div>
                <div class="card" v-if="model.order_shipping_address">
                    <div class="card-body">
                        <p class="f-16 font-600">Info Pengiriman</p>
                        <p class="f-14 d-flex align-items-center gap-1 justify-content-between">
                            <span class="text-secondary flex-shrink-0">Nama Penerima</span>
                            <span class="font-500 text-right">{{ model.order_shipping_address.name }}</span>
                        </p>
                        <p class="f-14 d-flex align-items-center gap-1 justify-content-between">
                            <span class="text-secondary flex-shrink-0">Detail Alamat</span>
                            <span class="font-500 text-right">{{ model.order_shipping_address.destinationArea }}</span>
                        </p>
                        <p class="f-14 d-flex align-items-center gap-1 justify-content-between">
                            <span class="text-secondary flex-shrink-0">Catatan Kurir</span>
                            <span class="font-500 text-right">{{ model.order_shipping_address.address }}</span>
                        </p>
                        <p class="f-14 d-flex align-items-center gap-1 justify-content-between">
                            <span class="text-secondary flex-shrink-0">Kelurahan</span>
                            <span class="font-500 text-right">{{ model.order_shipping_address.area }}</span>
                        </p>
                        <p class="f-14 d-flex align-items-center gap-1 justify-content-between">
                            <span class="text-secondary flex-shrink-0">Kecamatan</span>
                            <span class="font-500 text-right">{{ model.order_shipping_address.suburb }}</span>
                        </p>
                        <p class="f-14 d-flex align-items-center gap-1 justify-content-between">
                            <span class="text-secondary flex-shrink-0">Kota</span>
                            <span class="font-500 text-right">{{ model.order_shipping_address.city }}</span>
                        </p>
                        <p class="f-14 d-flex align-items-center gap-1 justify-content-between">
                            <span class="text-secondary flex-shrink-0">Provinsi</span>
                            <span class="font-500 text-right">{{ model.order_shipping_address.province }}</span>
                        </p>
                        <p class="f-14 d-flex align-items-center gap-1 justify-content-between">
                            <span class="text-secondary flex-shrink-0">Kode Pos</span>
                            <span class="font-500 text-right">{{ model.order_shipping_address.zipCode }}</span>
                        </p>
                        <p class="f-14 d-flex align-items-center gap-1 justify-content-between">
                            <span class="text-secondary flex-shrink-0">No HP</span>
                            <span class="font-500 text-right">{{ model.order_shipping_address.mobileNumber }}</span>
                        </p>
                        <p class="f-14 d-flex align-items-center gap-1 justify-content-between">
                            <span class="text-secondary flex-shrink-0">Ekspedisi Pengiriman</span>
                            <span class="font-500 text-right">{{ `${model.order_shipping_address.expedition} (${model.order_shipping_address.shipperReference.rate.name})` }}</span>
                        </p>
                    </div>
                </div>
                <div class="card" v-if="model.user">
                    <div class="card-body">
                        <p class="f-16 font-600">Info Pembeli</p>
                        <div class="row mb-1">
                            <div class="col-5 f-14 text-secondary">Nama Akun</div>
                            <div class="col-7 f-14 font-500 text-right pr-1">
                                {{ model.user.isVip ? `👑 ${model.user.fullName}` : model.user.fullName }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3 f-14 text-secondary">Email</div>
                            <div class="col-9 f-14 font-500 text-right pr-1">{{ model.user.account.email }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="card">
                    <div class="card-body pb-0" v-if="!model.diagnosis">
                        <p class="f-16 font-600">Detail Pembelian</p>
                        <div class="row pr-1 mb-1" v-for="(data, index) in model.products" :key="index">
                            <div class="col align-self-center">
                                <p class="f-14 font-500 mb-0">
                                    {{ data.title }}
                                    <span v-if="data.deletedAt || data.isActive === false" class="medicine-label bg-danger">Discontinued</span>
                                </p>
                                <p class="mb-0 text-warning f-12 font-600">{{ data.order_product.price | currency }} <span class="font-400 text-secondary">({{data.order_product.quantity | numFormat}} Item )</span></p>
                            </div>
                            <img :src="data.images.length > 0 ? data.images[0] : 'https://via.placeholder.com/100'" alt="" style="width: 48px; height: 48px;" class="rounded">
                        </div>
                    </div>
                    <div class="card-body pb-0" v-else>
                        <p class="f-16 font-600">Detail Pembelian & Aturan Pakai</p>
                        <hr>
                        <div v-if="model.diagnosis.recipes.length > 0">
                            <div class="row">
                                <div class="ml-1 f-20 font-600 text-primary">R/</div>
                                <div class="col align-self-center">
                                    <div class="" v-for="(recipe, index) in model.diagnosis.recipes" :key="index">
                                        <p class="f-14 text-black font-500 mb-0">{{recipe.product.title}} 
                                            <span class="f-12 font-400 text-secondary"> - QTY: {{ recipe.product.order_product.quantity }}{{ recipe.usageInterval ? ` - ${recipe.usageInterval}` : '' }}</span>
                                            <span v-if="recipe.product.order_product.isAddOn" class="medicine-label bg-dark">Add-on</span>
                                            <span v-if="recipe.product.deletedAt || recipe.product.isActive === false" class="medicine-label bg-danger">Discontinued</span>
                                        </p>
                                        <p class="text-warning f-12 font-600">
                                            {{ recipe.product.order_product.price | currency }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr class="mt-0">
                        </div>
                        <div class="row">
                            <div class="ml-1 f-20 font-600">C&nbsp;</div>
                            <div class="col align-self-center">
                                <p class="f-14 mb-0" v-html="model.diagnosis.c"></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card" v-if="(model.type === 2 || model.type === 3 || model.type === 4) && model.specialRequest">
                    <div class="card-body">
                        <p class="f-16 font-600">Special Request</p>
                        <p class="f-14 font-500" v-html="formattedSpecialRequest"></p>
                        <p class="f-14 font-400 mt-1 text-secondary">Updated by {{ model.specialRequestUpdatedBy }} ({{ model.specialRequestUpdatedAt | moment("ll, HH:mm") }})</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="card">
                    <div class="card-body">
                        <p class="f-16 font-600">Notes</p>
                        <textarea name="" id="" cols="30" rows="3" class="form-control" placeholder="Tinggalkan komentar..." v-model="model.notes" :disabled="!hasEditPermission"></textarea>
                        <button v-if="hasEditPermission" class="btn btn-primary mt-1 w-100 rounded" @click="updateNotes()">Update Notes</button>
                    </div>
                </div>
                <div class="card" v-if="hasEditPermission && model.statusDelivery == 2">
                    <div class="card-body">
                        <p class="f-16 font-600">Jadwal Pickup</p>
                        <button class="btn btn-primary w-100 rounded" @click="updateStatus(3)">Request Pick Up</button>
                    </div>
                </div>
                <div class="card" v-if="model.statusDelivery >= 3 && model.statusDelivery != 5">
                    <div class="card-body">
                        <p class="f-16 font-600">Resi Pengiriman</p>
                        <div class="row mb-1">
                            <div class="col-5 f-14 text-secondary">Kurir</div>
                            <div class="col-7 f-14 font-500 text-right pr-1">
                                {{ model.order_shipping_address.expedition ? 
                                    model.order_shipping_address.expedition + ' (' + model.order_shipping_address.shipperReference.rate.name + ')'
                                : '-' }}
                            </div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-5 f-14 text-secondary">Resi Shipper</div>
                            <div class="col-7 f-14 font-500 text-right pr-1">{{ model.shipperId ? model.shipperId : '-' }}</div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-5 f-14 text-secondary">Resi Operator</div>
                            <div class="col-7 f-14 font-500 text-right pr-1">{{ model.awb ? model.awb : '-' }}</div>
                        </div>
                        <div class="eta" v-if="model.estimateTimeArrival && model.statusDelivery === 3">
                            <img src="/assets/img/icon/truck-gray-icon.svg" width="18" height="18" alt="" />
                            Estimasi diterima {{ formatETA(new Date(model.estimateTimeArrival)) }}
                        </div>
                        <!-- <div class="row">
                            <div class="col-5 f-14 text-secondary">Status</div>
                            <div class="col-7 f-14 font-500 text-right pr-1 " :class="model.statusDelivery == 3 ? 'text-warning' : 'text-primary' ">{{model.statusDelivery == 3 ? 'SEDANG DIKIRIM' : 'SAMPAI TUJUAN'}}</div>
                        </div> -->
                        <button class="btn btn-primary w-100 rounded mt-1" @click="downloadLabel()" v-if="shipperDetail.label_check_sum != null"><i class="fa fa-print"></i> Print Label</button>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body pb-0">
                        <button class="btn btn-primary mb-1 w-100 rounded" v-if="hasEditPermission && model.statusDelivery == 0" @click="updateStatus(1)">Terima Pesanan</button>
                        <button class="btn btn-primary mb-1 w-100 rounded" v-if="hasEditPermission && model.statusDelivery == 1" @click="updateStatus(2)">Pesanan Siap Kirim</button>
                        <button class="btn btn-danger mb-1 w-100 rounded" v-if="hasEditPermission && (model.statusDelivery === 2 || model.statusDelivery === 3)" @click="cancelShipping()">Batalkan Pengiriman</button>
                        <button class="btn btn-success mb-1 w-100 rounded" @click="downloadPdf()">Lihat Invoice PDF</button>
                        <button class="btn btn-outline-primary mb-1 w-100 rounded" v-if="model.type === 2" @click="downloadPrescription()">Download Resep</button>
                        <button class="btn btn-outline-danger mb-1 w-100 rounded" v-if="hasEditPermission && model.statusDelivery == 0" @click="rejectShipping()">Tolak Pesanan</button>
                    </div>
                </div>
                <div v-if="isActivityShown" class="card">
                    <div class="card-body pb-0">
                        <p class="f-16 font-600">Activity</p>
                        <div v-if="model.receivedBy" class="row">
                            <p class="col f-14"><b class="text-primary">Diterima</b> oleh {{ model.receivedBy }} pada {{ model.receivedAt | moment('dddd, DD MMMM YYYY HH:mm') }} WIB</p>
                        </div>
                        <div v-if="model.readyToSendBy" class="row">
                            <p class="col f-14"><b class="text-primary">Pesanan Siap Kirim</b> oleh {{ model.readyToSendBy }} pada {{ model.readyToSendAt | moment('dddd, DD MMMM YYYY HH:mm') }} WIB</p>
                        </div>
                        <div v-if="model.pickedupBy" class="row">
                            <p class="col f-14"><b class="text-primary">Pick up request</b> oleh {{ model.pickedupBy }} pada {{ model.pickedupAt | moment('dddd, DD MMMM YYYY HH:mm') }} WIB</p>
                        </div>
                        <div v-if="model.cancelledBy" class="row">
                            <p class="col f-14"><b class="text-primary">Pesanan dibatalkan</b> oleh {{ model.cancelledBy }} pada {{ model.cancelledAt | moment('dddd, DD MMMM YYYY HH:mm') }} WIB</p>
                        </div>
                    </div>
                </div>
                <div class="card" v-if="trackings.length > 0">
                    <div class="card-body">
                        <p class="f-16 font-600">Informasi Pengiriman</p>
                        <div class="row mb-1" v-for="(tracking, index) in trackings" :key="index">
                            <div class="col-6 f-14 text-secondary" style="border-right: 1px solid #ccc;">
                                <p class="f-14 text-primary mb-0 font-600">{{tracking.logistic_status.name}}</p>
                                <p class="f-12 text-secondary font-300 mb-0">{{ tracking.created_date | moment('dddd, DD MMMM YYYY HH:mm') }} WIB</p>
                            </div>
                            <div class="col-6 f-14 font-500 pr-1">
                                <p class="f-12 text-secondary font-400 mb-0">
                                    {{ tracking.logistic_status.description}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getOrderTypeLabel } from '@/lib/order';

export default {
    data() {
        return {
            model: {
                statusDeliveryDetail: {},
                paymentChannel: {},
                diagnosis: null
            },
            trackings: [],
            shipperDetail: {
                label_check_sum: null
            }
        }
    },
    computed: {
        isActivityShown() {
            return (
                this.model.receivedBy ||
                this.model.readyToSendBy ||
                this.model.pickedupBy ||
                this.model.cancelledBy
            );
        },
        formattedSpecialRequest() {
            return this.model.specialRequest.replace(/\n/g, '<br />');
        },
        permissions() {
            return this.$store.getters.permissions;
        },
        hasEditPermission() {
            return this.permissions.includes('pharmacy:edit');
        },
    },
    beforeDestroy() {
        let _ = this
        _.$root.$emit('addAdditionalInfo', '')
    },
    methods: {
        formatETA(date) {
            if (!date) return '-';
            return new Intl.DateTimeFormat('id-ID', {
                weekday: 'long',
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            }).format(date);
        },
        getOrderTypeLabel,
        get() {
            let _ = this
            _.axios.get('orders/' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data

                    _.$root.$emit('addAdditionalInfo', _.model.orderNumber)
                })
        },
        getTrackingDetail() {
            let _ = this
            _.axios.get('orders/' + _.$route.params.id + '/shipping')
                .then(resp => {
                    if (resp.data.data) {
                        _.trackings = resp.data.data.data.trackings
                        _.shipperDetail = resp.data.data.data
                    }
                })
        },
        textFormat(x) {
            let str = ''
            for (let i = 0; i < x.length; i++) {
                str = str + x[i] + ', '
            }
            let clean = ''
            for (let i = 0; i < str.length - 2; i++) {
                clean = clean + str[i]
            }
            return clean;
        },
        statusColor(x) {
            let color = ''
            switch (x) {
                case 0:
                    color = 'btn-success'
                    break;
                case 1:
                    color = 'btn-warning'
                    break;
                case 2:
                    color = 'btn-primary'
                    break;
                case 3:
                    color = 'btn-info'
                    break;
                case 4:
                    color = 'btn-outline-primary'
                    break;
                case 5:
                    color = 'btn-danger'
                    break;
                default:
                    break;
            }
            return color;
        },
        updateNotes() {
            let _ = this
            _.axios.put('orders/' + _.$route.params.id + '/notes', { notes: _.model.notes })
                .then(resp => {
                    _.$swal.fire({
                        title: resp.data.message,
                        text: 'Notes Updated',
                        icon: 'success',
                        confirmButtonColor: '#0036A0',
                        confirmButtonText: 'OK'
                    })
                })
        },
        updateStatus( statusCode ) {
            let _ = this

            _.axios.put('orders/' + _.$route.params.id + '/shipping', {
                    status: statusCode
                })
                .then(resp => {
                    console.log(resp.data.message)
                    _.get()
                    _.getTrackingDetail()
                })
                .catch(error => {
                    _.$swal.fire({
                        title: 'Gagal',
                        text: error.response.data.message,
                        icon: 'error',
                        confirmButtonColor: '#0036A0',
                        confirmButtonText: 'OK'
                    })
                });
        },
        cancelShipping() {
            this.$swal.fire({
                title: 'Anda yakin batalkan pengiriman ini?',
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Tidak',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Ya',
            })
            .then((result) => {
                if (result.value) {
                    this.updateStatus(5);
                }
            });
        },
        rejectShipping() {
            this.$swal.fire({
                title: 'Anda yakin menolak pesanan ini?',
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Tidak',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Ya',
            })
            .then((result) => {
                if (result.value) {
                    this.updateStatus(5);
                }
            });
        },
        downloadPdf(){
            let _ = this
            // _.isLoading = true

            _.axios.get('/orders/' + _.$route.params.id + '/invoice'
                ,{responseType: 'arraybuffer'})
                .then(resp => {
                    let blob        = new Blob([resp.data], { type: 'application/pdf' })
                    let link        = document.createElement('a')
                    link.href       = window.URL.createObjectURL(blob)
                    link.download   = 'ORDER-INVOICE-' + _.model.orderNumber + '.pdf'
                    link.click()
                    // _.isLoading = false
                })
                .catch(err => {
                    console.log(err.response)
                    // _.isLoading = false
                })
        },
        downloadPrescription(){
            this.axios.post(`/diagnoses/${this.model.diagnosisId}/download`, undefined, { responseType: 'arraybuffer' })
                .then(res => {
                    const blob = new Blob([res.data], { type: 'application/pdf' });
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `Prescription_${this.model.user.fullName}_${this.model.consult.consultId}_${this.model.diagnosisId}.pdf`;
                    link.click();
                })
                .catch(err => {
                    this.$notify({
                        type: 'error',
                        title: 'Error',
                        text: err.response.data.message
                    });
                });
        },
        downloadLabel(){
            let _ = this
            // _.isLoading = true

            _.axios.get('/orders/print-label?shipperId=' + _.model.shipperId + '&labelCheckSum=' + _.shipperDetail.label_check_sum
                ,{responseType: 'arraybuffer'})
                .then(resp => {
                    let blob        = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                    let link        = document.createElement('a')
                    link.href       = window.URL.createObjectURL(blob)
                    link.download   = 'LABEL-CHECKSUM-' + _.shipperDetail.label_check_sum + '.pdf'
                    link.click()
                    // _.isLoading = false
                })
                .catch(err => {
                    console.log(err.response)
                    // _.isLoading = false
                })
        }
    },
    mounted() {
        let _ = this

        _.$moment.locale('id')
        
        _.get()
        _.getTrackingDetail()
    }
}
</script>

<style scoped>
.medicine-label {
    color: white;
    padding: 2px 4px;
    font-size: .75rem;
    margin-left: 8px;
    border-radius: 5px;
}

.eta {
    background-color: #f3f4f6;
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: .75rem;
}

.gap-1 {
  gap: 1rem;
}
</style>