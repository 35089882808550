<template>
  <div class="d-flex flex-column" style="max-height: 60vh">
    <div class="flex-1 d-flex flex-column gap-1 overflow-auto pb-1">
      <div v-if="shownFilters.includes(FILTER_PREFIX.WAITING_TIME)">
        <p class="f-12 font-500 text-dark mb-0">Waiting time</p>
        <label class="f-12 d-flex align-items-start gap-1 mb-0">
          <input type="checkbox" style="margin-top: 2px;" v-model="tempFilters[`${FILTER_PREFIX.WAITING_TIME}:overSLA`]">
          Over SLA
        </label>
      </div>
      <div v-if="polyclinics.length > 0 && shownFilters.includes(FILTER_PREFIX.POLYCLINIC)">
        <p class="f-12 font-500 text-dark mb-0">Polyclinic</p>
        <label class="f-12 d-flex align-items-start gap-1 mb-0" v-for="(polyclinic, idx) in polyclinics" :key="idx">
          <input type="checkbox" style="margin-top: 2px;" v-model="tempFilters[`${FILTER_PREFIX.POLYCLINIC}:${polyclinic}`]">
          {{ polyclinic }}
        </label>
      </div>
      <div v-if="shownFilters.includes(FILTER_PREFIX.SCORE)">
        <p class="f-12 font-500 text-dark mb-0">Priority Score</p>
        <label class="f-12 d-flex align-items-start gap-1 mb-0">
          <input type="checkbox" style="margin-top: 2px;" v-model="tempFilters[`${FILTER_PREFIX.SCORE}:0-25`]">
          0-25
        </label>
        <label class="f-12 d-flex align-items-start gap-1 mb-0">
          <input type="checkbox" style="margin-top: 2px;" v-model="tempFilters[`${FILTER_PREFIX.SCORE}:26-50`]">
          26-50
        </label>
        <label class="f-12 d-flex align-items-start gap-1 mb-0">
          <input type="checkbox" style="margin-top: 2px;" v-model="tempFilters[`${FILTER_PREFIX.SCORE}:51-75`]">
          51-75
        </label>
        <label class="f-12 d-flex align-items-start gap-1 mb-0">
          <input type="checkbox" style="margin-top: 2px;" v-model="tempFilters[`${FILTER_PREFIX.SCORE}:76-100`]">
          76-100
        </label>
      </div>
      <div v-if="shownFilters.includes(FILTER_PREFIX.RESPONSE_TIME_GROUP)">
        <p class="f-12 font-500 text-dark mb-0">Response time group</p>
        <label class="f-12 d-flex align-items-start gap-1 mb-0">
          <input type="checkbox" style="margin-top: 2px;" v-model="tempFilters[`${FILTER_PREFIX.RESPONSE_TIME_GROUP}:1`]">
          &lt; 45 mins
        </label>
        <label class="f-12 d-flex align-items-start gap-1 mb-0">
          <input type="checkbox" style="margin-top: 2px;" v-model="tempFilters[`${FILTER_PREFIX.RESPONSE_TIME_GROUP}:2`]">
          45 mins - 3 hours
        </label>
        <label class="f-12 d-flex align-items-start gap-1 mb-0">
          <input type="checkbox" style="margin-top: 2px;" v-model="tempFilters[`${FILTER_PREFIX.RESPONSE_TIME_GROUP}:3`]">
          &gt; 3 hours
        </label>
      </div>
      <div v-if="shownFilters.includes(FILTER_PREFIX.STATUS)">
        <p class="f-12 font-500 text-dark mb-0">Status</p>
        <label class="f-12 d-flex align-items-start gap-1 mb-0">
          <input type="checkbox" style="margin-top: 2px;" v-model="tempFilters[`${FILTER_PREFIX.STATUS}:0`]">
          Review
        </label>
        <label class="f-12 d-flex align-items-start gap-1 mb-0">
          <input type="checkbox" style="margin-top: 2px;" v-model="tempFilters[`${FILTER_PREFIX.STATUS}:4`]">
          Review TO
        </label>
        <label class="f-12 d-flex align-items-start gap-1 mb-0">
          <input type="checkbox" style="margin-top: 2px;" v-model="tempFilters[`${FILTER_PREFIX.STATUS}:1`]">
          Aktif
        </label>
      </div>
      <div v-if="shownFilters.includes(FILTER_PREFIX.GENDER)">
        <p class="f-12 font-500 text-dark mb-0">Gender and Preference</p>
        <label class="f-12 d-flex align-items-start gap-1 mb-0">
          <input type="checkbox" style="margin-top: 2px;" v-model="tempFilters[`${FILTER_PREFIX.GENDER}:MALE_PATIENT`]">
          Male patient
        </label>
        <label class="f-12 d-flex align-items-start gap-1 mb-0">
          <input type="checkbox" style="margin-top: 2px;" v-model="tempFilters[`${FILTER_PREFIX.GENDER}:FEMALE_PATIENT`]">
          Female patient - no preference
        </label>
        <label class="f-12 d-flex align-items-start gap-1 mb-0" :class="{ 'text-light': userGender === 'M' }">
          <input type="checkbox" style="margin-top: 2px;" v-model="tempFilters[`${FILTER_PREFIX.GENDER}:FEMALE_PATIENT_FEMALE_DOCTOR`]" :disabled="userGender === 'M'">
          Female patient - female doctor preference
        </label>
      </div>
      <div v-if="shownFilters.includes(FILTER_PREFIX.DIRI_CLINIC)">
        <p class="f-12 font-500 text-dark mb-0">Diri Clinic</p>
        <label class="f-12 d-flex align-items-start gap-1 mb-0">
          <input type="checkbox" style="margin-top: 2px;" v-model="tempFilters[`${FILTER_PREFIX.DIRI_CLINIC}:DIRI_CLINIC`]">
          Diri Clinic
        </label>
        <label class="f-12 d-flex align-items-start gap-1 mb-0">
          <input type="checkbox" style="margin-top: 2px;" v-model="tempFilters[`${FILTER_PREFIX.DIRI_CLINIC}:DIRI_PLUS_ONLY`]">
          Diri+ Only
        </label>
      </div>
      <div v-if="shownFilters.includes(FILTER_PREFIX.CUSTOMER_TYPE)">
        <p class="f-12 font-500 text-dark mb-0">Customer Type</p>
        <label v-if="shownOptions[FILTER_PREFIX.CUSTOMER_TYPE].includes('VIP')" class="f-12 d-flex align-items-start gap-1 mb-0">
          <input type="checkbox" style="margin-top: 2px;" v-model="tempFilters[`${FILTER_PREFIX.CUSTOMER_TYPE}:VIP`]">
          VIP
        </label>
        <label v-if="shownOptions[FILTER_PREFIX.CUSTOMER_TYPE].includes('XTRA')" class="f-12 d-flex align-items-start gap-1 mb-0">
          <input type="checkbox" style="margin-top: 2px;" v-model="tempFilters[`${FILTER_PREFIX.CUSTOMER_TYPE}:XTRA`]">
          XTRA
        </label>
        <label v-if="shownOptions[FILTER_PREFIX.CUSTOMER_TYPE].includes('OTHERS')" class="f-12 d-flex align-items-start gap-1 mb-0">
          <input type="checkbox" style="margin-top: 2px;" v-model="tempFilters[`${FILTER_PREFIX.CUSTOMER_TYPE}:OTHERS`]">
          Others
        </label>
        <label v-if="shownOptions[FILTER_PREFIX.CUSTOMER_TYPE].includes('NORMAL')" class="f-12 d-flex align-items-start gap-1 mb-0">
          <input type="checkbox" style="margin-top: 2px;" v-model="tempFilters[`${FILTER_PREFIX.CUSTOMER_TYPE}:NORMAL`]">
          Normal
        </label>
        <label v-if="shownOptions[FILTER_PREFIX.CUSTOMER_TYPE].includes('LOW_VALUE')" class="f-12 d-flex align-items-start gap-1 mb-0">
          <input type="checkbox" style="margin-top: 2px;" v-model="tempFilters[`${FILTER_PREFIX.CUSTOMER_TYPE}:LOW_VALUE`]">
          Low Value
        </label>
      </div>
    </div>
    <div class="d-flex flex-row gap-1 bg-white pt-1 border-top">
      <button class="btn btn-sm btn-outline-primary rounded" style="flex: 1;" @click="resetFilter">Reset</button>
      <button class="btn btn-sm btn-primary rounded" style="flex: 1;" @click="applyFilter">Filter</button>
    </div>
  </div>
</template>

<script>

export const FILTER_PREFIX = {
  WAITING_TIME: 'waitingTime',
  POLYCLINIC: 'polyclinic',
  SCORE: 'score',
  RESPONSE_TIME_GROUP: 'responseTimeGroup',
  STATUS: 'status',
  GENDER: 'gender',
  DIRI_CLINIC: 'diriClinic',
  CUSTOMER_TYPE: 'customerType',
}

const INITIAL_FILTER = {
  [`${FILTER_PREFIX.WAITING_TIME}:overSLA`]: false,
  [`${FILTER_PREFIX.SCORE}:0-25`]: false,
  [`${FILTER_PREFIX.SCORE}:26-50`]: false,
  [`${FILTER_PREFIX.SCORE}:51-75`]: false,
  [`${FILTER_PREFIX.SCORE}:76-100`]: false,
  [`${FILTER_PREFIX.RESPONSE_TIME_GROUP}:1`]: false, // < 45 mins
  [`${FILTER_PREFIX.RESPONSE_TIME_GROUP}:2`]: false, // 45 mins - 3 hours
  [`${FILTER_PREFIX.RESPONSE_TIME_GROUP}:3`]: false, // > 3 hours
  [`${FILTER_PREFIX.STATUS}:0`]: false, // review
  [`${FILTER_PREFIX.STATUS}:1`]: false, // active
  [`${FILTER_PREFIX.STATUS}:4`]: false, // review TO
  [`${FILTER_PREFIX.GENDER}:MALE_PATIENT`]: false, // Male Patient
  [`${FILTER_PREFIX.GENDER}:FEMALE_PATIENT`]: false, // Female Patient
  [`${FILTER_PREFIX.GENDER}:FEMALE_PATIENT_FEMALE_DOCTOR`]: false, // Female Patient - Female Doctor
  [`${FILTER_PREFIX.DIRI_CLINIC}:DIRI_CLINIC`]: false, // Diri Clinic
  [`${FILTER_PREFIX.DIRI_CLINIC}:DIRI_PLUS_ONLY`]: false, // Diri+ Only
  [`${FILTER_PREFIX.CUSTOMER_TYPE}:VIP`]: false, // Customer Type: VIP
  [`${FILTER_PREFIX.CUSTOMER_TYPE}:XTRA`]: false, // Customer Type: XTRA
  [`${FILTER_PREFIX.CUSTOMER_TYPE}:OTHERS`]: false, // Customer Type: Others
  [`${FILTER_PREFIX.CUSTOMER_TYPE}:NORMAL`]: false, // Customer Type: Normal
  [`${FILTER_PREFIX.CUSTOMER_TYPE}:LOW_VALUE`]: false, // Customer Type: Low Value
};

export default {
  props: {
    initValue: {
      type: Object,
      default: null,
    },
    userGender: {
      type: String,
      default: undefined,
    },
    shownFilters: {
      type: Array,
      default: function() {
        return [
          FILTER_PREFIX.WAITING_TIME,
          FILTER_PREFIX.POLYCLINIC,
          FILTER_PREFIX.SCORE,
          FILTER_PREFIX.RESPONSE_TIME_GROUP,
          FILTER_PREFIX.STATUS,
          FILTER_PREFIX.GENDER,
          FILTER_PREFIX.DIRI_CLINIC,
          FILTER_PREFIX.CUSTOMER_TYPE,
        ];
      }
    },
    shownOptions: {
      type: Object,
      default: function() {
        return {
          [FILTER_PREFIX.CUSTOMER_TYPE]: ['VIP', 'XTRA', 'NORMAL', 'LOW_VALUE'], // or ['VIP', 'XTRA', 'OTHERS'] 
        };
      }
    },
  },
  data() {
    return {
      FILTER_PREFIX,
      initialFilter: { ...INITIAL_FILTER },
      filters: { ...INITIAL_FILTER },
      tempFilters: { ...INITIAL_FILTER },
      polyclinics: [],
    }
  },
  computed: {
      isFilterActive() {
        return Object.keys(this.filters).some(key => this.filters[key] === true);
      },
    },
  methods: {
    applyFilter() {
      this.$emit('apply', this.getParamsObject(this.tempFilters));
      this.filters = { ...this.tempFilters };
    },
    resetFilter() {
      this.$emit('reset');
      this.filters = { ...INITIAL_FILTER };
      this.tempFilters = { ...INITIAL_FILTER };
      this.polyclinics.forEach(name => {
        const key = `${FILTER_PREFIX.POLYCLINIC}:${name}`;
        this.filters[key] = false;
        this.tempFilters[key] = false;
      });
    },
    cancel() {
      this.tempFilters = { ...this.filters };
    },
    getParamsObject(filters) {
      const activePolyclinics = this.polyclinics.filter(polyclinic => filters[`${FILTER_PREFIX.POLYCLINIC}:${polyclinic}`] === true);
      const activeScores = Object.keys(filters)
        .filter(key => key.startsWith(FILTER_PREFIX.SCORE))
        .filter(key => filters[key] === true)
        .map(key => key.split(':')[1]);
      const activeResponseTimeGroups = Object.keys(filters)
        .filter(key => key.startsWith(FILTER_PREFIX.RESPONSE_TIME_GROUP))
        .filter(key => filters[key] === true)
        .map(key => key.split(':')[1]);
      const activeStatus = Object.keys(filters)
        .filter(key => key.startsWith(FILTER_PREFIX.STATUS))
        .filter(key => filters[key] === true)
        .map(key => key.split(':')[1]);
      const activeGenderPreferences = Object.keys(filters)
        .filter(key => key.startsWith(FILTER_PREFIX.GENDER))
        .filter(key => filters[key] === true)
        .map(key => key.split(':')[1]);
      const activeDiriClinic = Object.keys(filters)
        .filter(key => key.startsWith(FILTER_PREFIX.DIRI_CLINIC))
        .filter(key => filters[key] === true)
        .map(key => key.split(':')[1]);
      const activeCustomerType = Object.keys(filters)
        .filter(key => key.startsWith(FILTER_PREFIX.CUSTOMER_TYPE))
        .filter(key => filters[key] === true)
        .map(key => key.split(':')[1]);

      return {
        withinSla: filters[`${FILTER_PREFIX.WAITING_TIME}:overSLA`] === true ? 'false' : undefined,
        '$consult.sub_clinic$': activePolyclinics.length > 0 ? `in:${activePolyclinics.join(',')}` : undefined,
        score: activeScores.length > 0 ? `between:${activeScores.join(',')}` : undefined,
        responseTimeGroup: activeResponseTimeGroups.length > 0 ? `in:${activeResponseTimeGroups.join(',')}` : undefined,
        status: activeStatus.length > 0 ? `in:${activeStatus.join(',')}` : undefined,
        gender: activeGenderPreferences.length > 0 ? `in:${activeGenderPreferences.join(',')}` : undefined,
        diriClinic: activeDiriClinic.length > 0 ? `in:${activeDiriClinic.join(',')}` : undefined,
        customerType: activeCustomerType.length > 0 ? `in:${activeCustomerType.join(',')}` : undefined,
      };
    },
    parseParamsObject(paramsObject) {
      if (!paramsObject) {
        return {};
      }

      const {
        withinSla,
        '$consult.sub_clinic$': subClinics,
        score,
        responseTimeGroup,
        status,
        gender,
        diriClinic,
        customerType,
      } = paramsObject;

      const filterObj = {};

      // Waiting time filter
      if (typeof withinSla !== 'undefined') {
        filterObj[`${FILTER_PREFIX.WAITING_TIME}:overSLA`] = withinSla === 'false' ? true : false;
      }

      // Priority score filter
      if (typeof score !== 'undefined') {
        const ranges = score.split(':')[1].split(','); // [0-25,26-50,...]
        ranges.forEach(range => {
          filterObj[`${FILTER_PREFIX.SCORE}:${range}`] = true;
        })
      }

      // Response time group filter
      if (typeof responseTimeGroup !== 'undefined') {
        const groups = responseTimeGroup.split(':')[1].split(','); // [0,1,2,...]
        groups.forEach(group => {
          filterObj[`${FILTER_PREFIX.RESPONSE_TIME_GROUP}:${group}`] = true;
        })
      }

      // Status filter
      if (typeof status !== 'undefined') {
        const statusAsArray = status.split(':')[1].split(','); // [0,1,2,...]
        statusAsArray.forEach(stat => {
          filterObj[`${FILTER_PREFIX.STATUS}:${stat}`] = true;
        })
      }

      // Subclinics filter
      if (typeof subClinics !== 'undefined') {
        const subClinicsAsArray = subClinics.split(':')[1].split(','); // ['Rambut','Kecantikan Kulit',...]
        subClinicsAsArray.forEach(subClinic => {
          filterObj[`${FILTER_PREFIX.POLYCLINIC}:${subClinic}`] = true;
        });
      }

      // Gender & preference
      if (typeof gender !== 'undefined') {
        const genderAsArray = gender.split(':')[1].split(','); // [MALE_PATIENT, FEMALE_PATIENT,...]
        genderAsArray.forEach(gend => {
          filterObj[`${FILTER_PREFIX.GENDER}:${gend}`] = true;
        })
      }

      // Diri clinic
      if (typeof diriClinic !== 'undefined') {
        const diriClinicAsArray = diriClinic.split(':')[1].split(','); // [DIRI_CLINIC, DIRI_PLUS_ONLY]
        diriClinicAsArray.forEach(clinic => {
          filterObj[`${FILTER_PREFIX.DIRI_CLINIC}:${clinic}`] = true;
        })
      }

      // Customer type
      if (typeof customerType !== 'undefined') {
        const customerTypeAsArray = customerType.split(':')[1].split(','); // [VIP, XTRA, OTHERS, NORMAL, LOW_VALUE]
        customerTypeAsArray.forEach(type => {
          filterObj[`${FILTER_PREFIX.CUSTOMER_TYPE}:${type}`] = true;
        })
      }

      return filterObj;
    },
  },
  mounted() {
    this.axios.get('/misc/poly-clinic')
      .then(res => {
        res.data.data.forEach(item => {
          const key = `${FILTER_PREFIX.POLYCLINIC}:${item.namePage}`;
          this.initialFilter[key] = false;
          this.filters[key] = false;
          this.tempFilters[key] = false;
        });
        this.polyclinics = res.data.data.map(item => item.namePage);

        const filterInitValue = this.parseParamsObject(this.initValue);
        // remove invalid keys
        const validPoliclinicsFilterKeys = this.polyclinics.map(name => `${FILTER_PREFIX.POLYCLINIC}:${name}`);
        const validFilterInitValue = Object.fromEntries(
          Object
            .entries(filterInitValue)
            .filter(([key,]) => Object.keys(INITIAL_FILTER).includes(key) || validPoliclinicsFilterKeys.includes(key))
        );

        this.initialFilter = { ...this.initialFilter, ...validFilterInitValue };
        this.filters = { ...this.filters, ...validFilterInitValue };
        this.tempFilters = { ...this.tempFilters, ...validFilterInitValue };
      })
  },
}

</script>
<style scoped>
.gap-1 {
  gap: 0.5rem;
}
</style>