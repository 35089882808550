<template>
  <div class="d-flex flex-column gap-1">
    <p class="f-12 font-500 text-dark mb-0">Assignee</p>
    <p v-if="isLoading" class="f-12 mb-0">
      Loading...
    </p>
    <template v-else>
      <label class="f-12 d-flex align-items-center gap-1 mb-0" v-for="(assignee, idx) in assignees" :key="idx">
        <input type="checkbox" :value="assignee.adminId" v-model="tempFilters">
        {{ assignee.name }}
      </label>
      <label v-if="showNotAssignedOption" class="f-12 d-flex align-items-center gap-1 mb-0">
        <input type="checkbox" value="null" v-model="tempFilters">
        Not assigned
      </label>
    </template>
    <p v-if="isOptionEmpty" class="f-12">No one is clocked-in</p>
    <div class="d-flex flex-row gap-1">
      <button class="btn btn-sm btn-outline-primary rounded" style="flex: 1;" @click="resetFilter">Reset</button>
      <button class="btn btn-sm btn-primary rounded" style="flex: 1;" @click="applyFilter" :disabled="isOptionEmpty">Filter</button>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    showNotAssignedOption: Boolean,
  },
  data() {
    return {
      initialFilter: [],
      filters: [],
      tempFilters: [],
      assignees: [],
      isLoading: false,
    }
  },
  computed: {
    isFilterActive() {
      return this.filters.length > 0;
    },
    isOptionEmpty() {
      return !this.isLoading && this.assignees.length === 0 && !this.showNotAssignedOption;
    },
  },
  methods: {
    applyFilter() {
      this.$emit('apply', this.getParamsObject(this.tempFilters));
      this.filters = [...this.tempFilters];
    },
    resetFilterView() {
      this.filters = [...this.initialFilter];
      this.tempFilters = [...this.initialFilter];
    },
    resetFilter() {
      this.$emit('reset');
      this.resetFilterView();
    },
    cancel() {
      this.tempFilters = [...this.filters];
    },
    getParamsObject(filters) {
      return {
        'assignedTo': filters.length > 0 ? `in:${filters.join(',')}` : undefined,
      };
    },
    getAssignees() {
      this.isLoading = true;
      this.axios.get('/admins/clinicals', { params: { isClockedIn: true } })
        .then(res => {
          this.assignees = res.data.data.rows;
          const assigneeAdminIds = res.data.data.rows.map(assignee => assignee.adminId);
          // remove obsolete data
          this.initialFilter = this.initialFilter.filter(item => assigneeAdminIds.includes(item) || item === 'null');
          this.filters = this.filters.filter(item => assigneeAdminIds.includes(item) || item === 'null');
          this.tempFilters = this.tempFilters.filter(item => assigneeAdminIds.includes(item) || item === 'null');
          this.$emit('refresh', this.getParamsObject(this.filters));
          this.isLoading = false;
        });
    },
  },
}

</script>
<style scoped>
.gap-1 {
  gap: 0.5rem;
}
</style>