<template>
  <div class="mw-100 p-2">
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table mb-0">
            <thead>
              <tr>
                <th class="font-600 text-black">Nama Dokter</th>
                <th class="font-600 text-black">Status</th>
                <th class="font-600 text-black">Role</th>
                <th class="font-600 text-black">Auto Assignment Filter</th>
                <th v-if="hasEditPermission" class="font-600 text-black">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in clinicalUsers.rows" :key="user.adminId">
                <td>
                  <span>{{ user.name }}</span>
                </td>
                <td>
                  <p v-if="user.isClockedIn" class="mb-0">
                    <span class="text-success">Clocked-in</span>
                    <br>
                    <span class="f-12"> (Since {{ user.clockedInAt | moment('D MMM, HH:mm') }})</span>
                  </p>
                  <span v-else class="text-danger">Clocked-out</span>
                </td>
                <td>
                  <span v-if="user.isClockedIn">{{ user.subRoleName }}</span>
                  <span v-else>-</span>
                </td>
                <td>
                  <p class="mb-0 f-12" v-html="renderFilter(user.scopeAssignment)"></p>
                </td>
                <td v-if="hasEditPermission">
                  <div class="d-flex flex-row justify-content-center" style="gap: .5rem;">
                    <VTooltip>
                      <span
                        @click="openActiveFilterModal(user)"
                        class="btn btn-sm btn-warning rounded cursor-pointer"
                      >
                        <i class="fa fa-filter"></i>
                      </span>
                      <template #popper>
                        Edit auto assignment filter
                      </template>
                    </VTooltip>
                    <VTooltip>
                      <span
                        @click="openForceClockOutModal(user)"
                        :class="{
                          'cursor-pointer btn-danger': user.isClockedIn,
                          'btn-secondary': !user.isClockedIn,
                        }"
                        class="btn btn-sm rounded"
                      >
                        <i class="fa fa-power-off"></i>
                      </span>
                      <template #popper>
                        Force clock-out
                      </template>
                    </VTooltip>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
      </div>
      </div>
    </div>
    <div class="card" v-if="clinicalUsers.totalPages > 1">
      <div class="card-body d-flex flex-row">
        <PaginateList :data="clinicalUsers" />
      </div>
    </div>

    <!-- Clock-In Modal -->
    <modal :name="ACTIVE_FILTER_MODAL" :adaptive="true" height="auto" :width="400" style="z-index: 2000;">
      <div class="p-1" v-if="selectedUser">
        <p class="f-16 text-black font-600">Auto Assignment Filter - {{ selectedUser.name }}</p>
        <ChannelFilter
          :initValue="selectedUser.scopeAssignment"
          :userGender="selectedUser.gender"
          @apply="(filterParamsObject) => applyFilter(filterParamsObject, selectedUser)"
          @reset="resetFilter"
          :shownOptions="{ [FILTER_PREFIX.CUSTOMER_TYPE]: ['VIP', 'XTRA', 'OTHERS'] }"
        />
      </div>
    </modal>
  </div>
</template>
<script>
import PaginateList from '@/components/PaginateList';
import ChannelFilter, { FILTER_PREFIX } from '@/components/ChannelFilter.vue';

const ACTIVE_FILTER_MODAL = 'active-filter-modal';

export default {
  components: {
    PaginateList,
    ChannelFilter,
  },
  data() {
    return {
      FILTER_PREFIX,
      ACTIVE_FILTER_MODAL,
      limit: 10,
      clinicalUsers: [],
      selectedUser: null,
    }
  },
  computed: {
    permissions() {
      return this.$store.getters.permissions;
    },
    hasEditPermission() {
      return this.permissions.includes('clinical_user:edit');
    },
  },
  methods: {
    getData(page) {
      this.axios.get('/admins/clinicals', {
        params: {
          page: page || 1,
          limit: this.limit,
        }
      }).then(res => {
        this.clinicalUsers = res.data.data;
      });
    },
    openForceClockOutModal(user) {
      if (user.isClockedIn) {
        this.$swal.fire({
          title: 'Konfirmasi Force Clock-out',
          text: `Clinical user ${user.name} akan ter-clock-out secara otomatis`,
          icon: 'question',
          showCancelButton: true,
          cancelButtonText: 'Batal',
          confirmButtonColor: '#0036A0',
          confirmButtonText: 'Ya',
        }).then((result) => {
          if (result.value) {
              this.forceClockOut(user);
          }
        });
      }
    },
    forceClockOut(user) {
      this.axios.post('/admins/force-clock-out', {
        adminId: user.adminId,
      }).then(() => {
        this.$notify({
          type: 'success',
          title: 'Success',
          text: `${user.name} is successfully clocked-out`
        })
        this.getData();
      }).catch(() => {
        this.getData();
      });
    },
    renderFilter(filter) {
      if (filter) {
        const STATUS = {
          0: 'Review',
          1: 'Active',
          4: 'Review TO',
        };

        const GENDER = {
          MALE_PATIENT: 'Male patient',
          FEMALE_PATIENT: 'Female patient - no preference',
          FEMALE_PATIENT_FEMALE_DOCTOR: 'Female patient - female doctor preference',
        };

        const DIRI_CLINIC = {
          DIRI_CLINIC: 'Diri Clinic',
          DIRI_PLUS_ONLY: 'Diri+ Only',
        };

        const CUSTOMER_TYPE = {
          VIP: 'VIP',
          XTRA: 'XTRA',
          OTHERS: 'Others',
        };

        const {
          withinSla,
          '$consult.sub_clinic$': subClinics,
          score,
          responseTimeGroup,
          status,
          gender,
          diriClinic,
          customerType,
        } = filter;

        const filterStringAsArray = [];

        // Waiting time
        if (typeof withinSla !== 'undefined' && withinSla === 'false') {
          filterStringAsArray.push('Over SLA');
        }

        // Subclinics
        if (typeof subClinics !== 'undefined') {
          filterStringAsArray.push(`Polyclinics: ${subClinics.split(':')[1].split(',').join(', ')}`);
        }

        // Priority score
        if (typeof score !== 'undefined') {
          filterStringAsArray.push(`Priority scores: ${score.split(':')[1].split(',').join(', ')}`);
        }

        // Status
        if (typeof status !== 'undefined') {
          filterStringAsArray.push(`Status: ${status.split(':')[1].split(',').map(stat => STATUS[stat]).join(', ')}`);
        }

        // Response time group
        if (typeof responseTimeGroup !== 'undefined') {
          filterStringAsArray.push(`Response time groups: ${responseTimeGroup.split(':')[1].split(',').join(', ')}`);
        }

        // Gender and Preference
        if (typeof gender !== 'undefined') {
          filterStringAsArray.push(`Gender and preference: ${gender.split(':')[1].split(',').map(gen => GENDER[gen]).join(', ')}`);
        }

        // Diri Clinic
        if (typeof diriClinic !== 'undefined') {
          filterStringAsArray.push(`Diri Clinic: ${diriClinic.split(':')[1].split(',').map(clinic => DIRI_CLINIC[clinic]).join(', ')}`);
        }

        // Customer Type
        if (typeof customerType !== 'undefined') {
          filterStringAsArray.push(`Customer Type: ${customerType.split(':')[1].split(',').map(type => CUSTOMER_TYPE[type]).join(', ')}`);
        }

        return filterStringAsArray.join('<br>');
      }
      return '-';
    },
    openActiveFilterModal(user) {
      this.selectedUser = user;
      this.$nextTick(() => {
        this.$modal.show(ACTIVE_FILTER_MODAL);
      });
    },
    applyFilter(filterParamsObject, user) {
      this.axios.post(`/admins/clinicals/${user.adminId}/scope-assignment`, {
        ...filterParamsObject
      }).then(() => {
        this.$notify({
          type: 'success',
          title: 'Success',
          text: 'Active filter is successfully updated'
        })
        this.getData(this.$router.currentRoute.query?.page ?? 1);
      });
      this.$modal.hide(ACTIVE_FILTER_MODAL);
    },
    resetFilter() {
      this.applyFilter(null, this.selectedUser);
    },
  },
  mounted() {
    this.getData();
    this.$root.$on('paginate', (data) => {
      if (data === 1) {
        this.$router.push({ query: { page: undefined } }).catch(() => {});
      } else {
        this.$router.push({ query: { page: data } }).catch(() => {});
      }
      this.getData(data);
    });
  }
}
</script>
<style scoped>

</style>