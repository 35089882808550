<template>
    <div class="mw-100 p-2">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-2 text-center profile-avatar">
                        <img :src="model.avatar ? model.avatar : '/assets/img/icon/profile.png'" alt="" class="">
                        <!-- <button class="btn btn-danger btn-sm rounded mt-3 mb-1">Reset Password</button> -->
                    </div>
                    <div class="col-lg-10">
                        <div class="row">
                            <div class="form-group col-lg-6">
                                <label for="" class="font-600 f-16">Nama Depan</label>
                                <input type="text" class="form-control" placeholder="Masukkan nama depan" v-model="model.firstName" disabled>
                            </div>
                            <div class="form-group col-lg-6">
                                <label for="" class="font-600 f-16">Nama Belakang</label>
                                <input type="text" class="form-control" placeholder="Masukkan nama belakang" v-model="model.lastName" disabled>
                            </div>
                            <div class="form-group col-lg-6">
                                <label for="" class="font-600 f-16">Tanggal Lahir</label>
                                <div class="position-relative has-icon-right">
                                    <date-picker
                                        v-model="model.dateOfBirth"
                                        format="DD/MM/YYYY"
                                        type="date"
                                        input-class="form-control"
                                        value-type="YYYY-MM-DD"
                                        placeholder="Masukkan tanggal lahir"
                                        style="width: 100%;"
                                        disabled>
                                    </date-picker>
                                </div>
                            </div>
                            <div class="form-group col-lg-6">
                                <label for="" class="font-600 f-16">Jenis Kelamin</label>
                                <input type="radio" style="margin-right: 10px;" v-model="model.gender" value="1"> Laki-laki
                                <input type="radio" class="ml-1" style="margin-right: 10px;" v-model="model.gender" value="0"> Perempuan
                            </div>
                            <div class="form-group col-lg-6">
                                <label for="" class="font-600 f-16">Provinsi</label>
                                <input type="text" class="form-control" placeholder="Masukkan provinsi" v-model="model.province" disabled>
                            </div>
                            <div class="form-group col-lg-6">
                                <label for="" class="font-600 f-16">Email</label>
                                <input type="text" class="form-control" placeholder="Masukkan email" v-model="model.account.email" disabled>
                            </div>
                            <div class="form-group col-lg-6">
                                <label for="" class="font-600 f-16">Kode Pos</label>
                                <input type="text" class="form-control" placeholder="Masukkan kode pos" v-model="model.account.zipCode" disabled>
                            </div>
                            <div class="form-group col-lg-6">
                                <label for="" class="font-600 f-16">Kota</label>
                                <input type="text" class="form-control" placeholder="Masukkan kota" v-model="model.account.city" disabled>
                            </div>
                            <div class="form-group col-lg-6">
                                <label for="" class="font-600 f-16">Alamat Lengkap</label>
                                <textarea name="" id="" cols="30" rows="4" class="form-control" v-model="model.address" placeholder="Masukkan alamat lengkap" disabled></textarea>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="" class="font-600 f-16">Desa</label>
                                    <input type="text" class="form-control" placeholder="Masukkan kota" v-model="model.account.suburb" disabled>
                                </div>
                                <div class="form-group">
                                    <label for="" class="font-600 f-16">Pin Lokasi Maps</label>
                                    <div class="form-control" style="height:55px;">
                                        <div class="row">
                                            <div class="col-8 align-self-center">
                                                <img src="/assets/img/icon/map.png" alt="">
                                                <span class="f-16 font-500 text-black"> Lokasi Maps</span>
                                            </div>
                                            <div class="col">
                                                <button class="btn btn-primary btn-sm pull-right rounded" disabled>Lihat</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button class="btn btn-primary pull-right mt-4" disabled>Simpan Perubahan</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            model: {
                account: {}
            },
            orders: []
        }
    },
    beforeDestroy() {
        let _ = this
        _.$root.$emit('addAdditionalInfo', '')
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('users/' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data

                    _.$root.$emit('addAdditionalInfo', _.model.fullName)
                })
        },
        getOrders() {
            let _ = this
            _.axios.get('orders?sortBy=createdAt.DESC&userId=' + _.$route.params.id)
                .then(resp => {
                    _.orders = resp.data.data.rows
                })
        },
        textFormat(x) {
            let str = ''
            for (let i = 0; i < x.length; i++) {
                str = str + x[i] + ', '
            }
            let clean = ''
            for (let i = 0; i < str.length - 2; i++) {
                clean = clean + str[i]
            }
            return clean;
        },
        statusColor(x) {
            let color = ''
            switch (x) {
                case 0:
                    color = 'btn-primary'
                    break;
                case 1:
                    color = 'btn-secondary'
                    break;
                case 2:
                    color = 'btn-success'
                    break;
                case 3:
                    color = 'btn-danger'
                    break;
                case 4:
                    color = 'btn-warning'
                    break;
                default:
                    break;
            }
            return color;
        }
    },
    mounted() {
        let _ = this
        _.get()
        _.getOrders()
    }
}
</script>