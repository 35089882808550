<template>
  <div id="app">
    <portal-target name="prescriptionTemplatePortal" />
    <router-view/>
    <notifications/>
  </div>
</template>

<script>
const vh = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

export default {
  mounted() {
    window.addEventListener('resize', vh);
    vh();
  },
  beforeDestroy() {
    window.removeEventListener('resize', vh);
  }
}
</script>