<template>
    <div :class="{'d-flex flex-row wrapper': true, 'wrapper__hidden-navbar': !isNavbarShow}">
        <!-- Channel List -->
        <div :class="{'d-none': channel,'d-md-block bg-white h-100 overflow-auto': true}" style="flex: 1 0 25%; border-right: 1px solid #BCC0D1;">
            <div class="py-1 bg-white border-bottom" style="position: sticky; top: 0; z-index: 10;">
                <div v-if="showSearchBar || showFilter" class="d-flex align-items-end gap-1 pl-1 pr-1 mb-1">
                    <div v-if="showSearchBar" style="flex: 1;">
                        <p class="f-10 font-600" style="line-height: 1.25rem; margin-bottom: 2px;">Parameter pencarian</p>
                        <div class="d-flex align-items-center gap-1">
                            <multiselect
                                v-model="searchParam"
                                :options="[
                                    { value: 'consultNumber', label: 'Konsul ID' },
                                    { value: 'userName', label: 'Username' },
                                    { value: 'email', label: 'Email' },
                                    { value: 'mobileNumber', label: 'Phone' },
                                ]"
                                label="label"
                                track-by="value"
                                :show-labels="false"
                                :multiple="false"
                                :searchable="false"
                                :allow-empty="false"
                                style="width: 160px; max-width: 50%;"
                            >
                            </multiselect>
                            <div class="input-group" style="flex: 1;">
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="searchMessage"
                                    @input="handleSearchMessage"
                                    placeholder="Cari konsul..."
                                >
                            </div>
                        </div>
                    </div>
                    <div v-if="isAutoAssignmentActive && showFilter && TABS[activeTab].showAssigneeFilter" class="d-flex align-items-center mb-1">
                        <dropdown-menu
                            position="right"
                            @open="$refs.assigneeFilter.getAssignees()"
                            @clickOutside="$refs.assigneeFilter.cancel()"
                            ref="assigneeFilterDropdown"
                        >
                            <div slot="trigger" class="position-relative">
                                <img src="/assets/img/icon/doctor-outlined-icon.svg" class="cursor-pointer" width="20" alt="" />
                                <div v-if="$refs.assigneeFilter && $refs.assigneeFilter.isFilterActive" class="filter-indicator"></div>
                            </div>
                            <div slot="content" class="d-flex flex-column gap-1 p-1" style="width: 210px;">
                                <AssigneeFilter
                                    @apply="handleChangeAssigneeFilter"
                                    @reset="resetAssigneeFilter"
                                    @refresh="refreshAssigneeFilter"
                                    :showNotAssignedOption="TABS[activeTab].showNotAssignedOptionOnAssigneeFilter"
                                    ref="assigneeFilter"
                                    :key="isClockedIn"
                                />
                            </div>
                        </dropdown-menu>
                    </div>
                    <div v-if="showFilter" class="d-flex align-items-center mb-1">
                        <dropdown-menu
                            position="right"
                            @clickOutside="$refs.channelFilter.cancel()"
                            ref="filterDropdown"
                        >
                            <div slot="trigger" class="position-relative">
                                <img src="/assets/img/icon/filter-icon.svg" class="cursor-pointer" width="18" alt="" />
                                <div v-if="$refs.channelFilter && $refs.channelFilter.isFilterActive" class="filter-indicator"></div>
                            </div>
                            <div slot="content" class="d-flex flex-column gap-1 p-1" style="width: 210px;">
                                <ChannelFilter @apply="handleChangeFilter" @reset="resetFilter" ref="channelFilter" :key="isClockedIn" />
                            </div>
                        </dropdown-menu>
                    </div>
                </div>
                <div class="pl-1 pr-1" style="display:flex; overflow: auto; flex-wrap: unset;">
                    <button
                        v-for="TAB_KEY in ORDERED_TABS_KEYS"
                        :key="TAB_KEY"
                        class="btn btn-sm rounded"
                        :class="{ 'btn-primary': activeTab === TAB_KEY, 'btn-outline-secondary': activeTab !== TAB_KEY }"
                        @click="handleChangeTab(activeTab, TAB_KEY)"
                        style="margin-right: 5px; white-space: nowrap;"
                    >
                        {{ TABS[TAB_KEY].label }}
                        <span
                            v-if="tabCount[TAB_KEY] > 0"
                            class="badge"
                            :class="{ 'badge-light bg-white text-blue': activeTab === TAB_KEY, 'badge-danger': activeTab !== TAB_KEY }"
                        >
                            {{ tabCount[TAB_KEY] }}
                        </span>
                    </button>
                </div>
            </div>
            <div style="overflow: auto;" id="chat-list">
                <div class="pl-1 pr-1 pt-1 pointer chat " :class="{'active': channel && channel.id === data.id }" v-for="(data, index) in channels" :key="index"
                    @click="subscribe(data)">
                    <div style="border-bottom: 0.5px solid #BCC0D1;" class="pb-1" v-if="data.consult">
                        <div class="d-flex align-items-center justify-content-between gap-1" style="margin-bottom: 0.5rem;">
                            <div class="d-flex flex-row align-items-center gap-1 overflow-hidden" style="flex: 1;">
                                <div class="f-14 text-truncate d-flex align-items-center" style="gap: 4px;" >
                                    <span v-if="data.consult.user.isVip">👑</span>
                                    <img
                                        v-if="data.consult.user.isSubscribed && !data.consult.user.isVip"
                                        src="/assets/img/icon/diri-xtra-logo.svg"
                                        width="20"
                                        height="20"
                                        alt=""
                                    />
                                    <img
                                        v-if="data.consult.user.isLowValueCustomer"
                                        src="/assets/img/icon/low-value-customer.png"
                                        width="18"
                                        height="18"
                                        alt=""
                                    />
                                    <span class="font-600 text-truncate">{{ data.consult.user.fullName }}</span>
                                </div>
                                <span v-if="hasEditPermission && data.unreadAdminCount > 0" class="badge badge-danger">{{data.unreadAdminCount}}</span>
                                <VTooltip v-if="data.consult.user.userTags && data.consult.user.userTags.redFlag">
                                    <img :src="USER_TAGS.redFlag.icon.active" width="20" height="20" :alt="USER_TAGS.redFlag.label" />
                                    <template #popper>
                                        {{ USER_TAGS.redFlag.label }}
                                    </template>
                                </VTooltip>
                                <VTooltip v-if="data.consult.user.userTags && data.consult.user.userTags.preferFemaleDoctor">
                                    <img :src="USER_TAGS.preferFemaleDoctor.icon.active" width="20" height="20" :alt="USER_TAGS.preferFemaleDoctor.label" />
                                    <template #popper>
                                        {{ USER_TAGS.preferFemaleDoctor.label }}
                                    </template>
                                </VTooltip>
                            </div>
                            <div class="d-flex flex-row align-items-center" style="flex-shrink: 0;">
                                <span class="f-12 font-400" v-if="data.lastMessage">{{data.lastMessage.timestamp | moment('D MMM Y, HH:mm')}}</span>
                                <div v-if="hasEditPermission" style="margin: 0 0 2px 5px;">
                                    <dropdown-menu position="right">
                                        <div slot="trigger">
                                            <span class="f-12">
                                                <i class="fa fa-chevron-down"></i>
                                            </span>
                                        </div>
                                        <template #content="contentProps">
                                            <div slot="content" class="d-flex flex-column gap-1 p-1">
                                                <span
                                                    class="cursor-pointer"
                                                    :class="{'text-disabled cursor-not-allowed': data.unreadAdminCount > 0}"
                                                    @click="(e) => {
                                                        e.stopPropagation();
                                                        contentProps.closeDropdown();
                                                        handleUnreadChannel(index);
                                                    }"
                                                >
                                                    Mark as unread
                                                </span>
                                                <span
                                                    v-if="isClockedIn && !data.consult.user.isLowValueCustomer && data.consult.user.isMarkableAsLowValueCustomer"
                                                    class="cursor-pointer"
                                                    @click="(e) => {
                                                        e.stopPropagation();
                                                        contentProps.closeDropdown();
                                                        openLowValueCustomerModal(data.id);
                                                    }"
                                                >
                                                    Mark as Low Value Customer
                                                </span>
                                                <span
                                                    v-if="isClockedIn && data.consult.user.isLowValueCustomer"
                                                    class="cursor-pointer"
                                                    @click="(e) => {
                                                        e.stopPropagation();
                                                        contentProps.closeDropdown();
                                                        unmarkAsLowValueCustomer(data.id);
                                                    }"
                                                >
                                                    Unmark as Low Value Customer
                                                </span>
                                            </div>
                                        </template>
                                    </dropdown-menu>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-column gap-1">
                                <div class="d-flex flex-row flex-wrap align-items-center gap-1">
                                    <button class="btn btn-sm btn-outline-primary rounded" style="background: inherit;">{{ data.consult.flow.title }}</button>
                                    <button class="btn btn-sm rounded" :class="consultColor(data.status, data.consult.activationDate)">{{ consultStatus(data.status, data.consult.activationDate) }}</button>
                                <div v-if="data.assignee" class="btn btn-sm btn-dark rounded">{{ data.assignee.fullName }}</div>
                            </div>
                            <div class="f-12 font-400 mb-0" v-if="data.lastMessage">
                                <p class="f-12 mb-0" style="overflow-wrap: anywhere; word-break: normal; margin-bottom: .5rem;">
                                    <span v-if="data.consult.user.accountId !== data.lastMessage.accountId" class="text-blue font-600">{{ data.lastMessage.name }}: </span>
                                    <template v-if="data.lastMessage.message">
                                        {{ data.lastMessage.message.length > 100 ? htmlStripTag(data.lastMessage.message).substring(0, 100) + '...' : htmlStripTag(data.lastMessage.message) }}
                                    </template>
                                </p>
                                <div v-if="data.lastMessage.attachment && data.lastMessage.attachment.length > 0" class="d-flex flex-row gap-1" style="margin-top: .5rem;">
                                    <img :src="image" alt="" v-for="(image, idx_image) in data.lastMessage.attachment" :key="idx_image" style="width: 50px; height: 50px; object-fit: cover;" class="rounded">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p v-if="!isLoadingChannels && channels.length === 0 && page === 1" class="text-blue text-center f-12 my-1">
                    Data tidak ditemukan
                </p>
                <p v-if="isLoadingChannels" class="text-blue text-center f-12 my-1">
                    Loading...
                </p>
                <template v-else-if="TABS[activeTab].isPaginated && page < lastPage && !isLoadingChannels">
                    <p
                        class="text-blue text-center f-12 my-1 pointer"
                        @click="nextChannelsPage"
                    >
                        Muat lebih banyak <span class="fa fa-arrow-down"></span>
                    </p>
                </template>
            </div>
        </div>

        <!-- Chatroom - empty state -->
        <div v-if="!channel" class="d-none d-md-flex flex-column bg-white h-100 align-items-center justify-content-center" style="flex: 3 0 75%; gap: 1rem;">
            <img src="/assets/img/consult-illustration.png" class="" alt="" />
            <p class="text-dark">Pilih konsultasi untuk membuka chat dan detail konsultasi.</p>
        </div>

        <!-- Chatroom -->
        <div v-if="channel" class="bg-white h-100" style="flex: 3 0 75%;">
            <div class="d-flex flex-column h-100">
                <!-- Chatroom Header -->
                <div class="p-1 d-flex justify-content-between align-items-center" style="border-bottom: 1px solid #BCC0D1">
                    <div class="d-flex justify-content-start" style="gap: .75rem">
                        <img src="/assets/img/icon/arrow-left-icon.svg" class="cursor-pointer d-block d-md-none" width="26" height="26" @click="closeChatRoom" alt="" />
                        <div>
                            <div class="d-flex flex-row align-items-center flex-wrap" style="gap: .5rem; margin-bottom: .5rem;">
                                <div class="f-14 text-truncate d-flex align-items-center" style="gap: 4px;" >
                                    <span v-if="channel.consult.user.isVip">👑</span>
                                    <img
                                        v-if="channel.consult.user.isSubscribed && !channel.consult.user.isVip"
                                        src="/assets/img/icon/diri-xtra-logo.svg"
                                        width="24"
                                        height="24"
                                        alt=""
                                    />
                                    <img
                                        v-if="channel.consult.user.isLowValueCustomer"
                                        src="/assets/img/icon/low-value-customer.png"
                                        width="18"
                                        height="18"
                                        alt=""
                                    />
                                    <span class="text-dark text-truncate">{{ channel.consult.user.fullName }}</span>
                                </div>
                                <div class="d-flex flex-row align-items-center" style="gap: .5rem">
                                    <div
                                        v-for="tag in Object.keys(USER_TAGS)"
                                        :key="tag"
                                        :class="{ 'pointer': hasEditPermission }"
                                        @click="hasEditPermission ? toggleUserTag(tag, channel.consult.user.userTags ? channel.consult.user.userTags[tag] : false) : undefined"
                                    >
                                        <VTooltip>
                                            <img
                                                :src="channel.consult.user.userTags && channel.consult.user.userTags[tag] === true ? USER_TAGS[tag].icon.active : USER_TAGS[tag].icon.inactive"
                                                width="20"
                                                height="20"
                                                :alt="USER_TAGS[tag].label"
                                            />
                                            <template #popper>
                                                {{ USER_TAGS[tag].label }}
                                            </template>
                                        </VTooltip>
                                    </div>
                                    <VTooltip>
                                        <dropdown-menu
                                            position="right"
                                            @clickOutside="allergic = channel.consult.user.userTags && channel.consult.user.userTags.allergic ? channel.consult.user.userTags.allergic : ''"
                                        >
                                            <div slot="trigger">
                                                <img
                                                    :src="channel.consult.user.userTags && channel.consult.user.userTags.allergic ? AllergicActiveIcon : AllergicInactiveIcon"
                                                    width="20"
                                                    height="20"
                                                    alt="Alergi"
                                                />
                                            </div>
                                            <template #content="contentProps">
                                                <div class="p-1">
                                                    <textarea
                                                        cols="20"
                                                        rows="2"
                                                        v-model="allergic"
                                                        @input="handleInputAllergic"
                                                        class="form-control"
                                                        :class="errorAllergic ? 'border-danger' : ''"
                                                        placeholder="Alergi..."
                                                        :disabled="!hasEditPermission"
                                                    >
                                                    </textarea>
                                                    <p v-if="errorAllergic" class="text-danger f-10" style="margin-bottom: 0;">{{ errorAllergic }}</p>
                                                    <button v-if="hasEditPermission" class="btn btn-sm rounded btn-primary w-100 mt-1" @click="saveAllergic(contentProps.closeDropdown)" :disabled="errorAllergic !== ''">Simpan</button>
                                                </div>
                                            </template>
                                        </dropdown-menu>
                                        <template #popper>Alergi</template>
                                    </VTooltip>
                                </div>
                            </div>
                            <div class="d-flex flex-wrap" style="gap: .5rem">
                                <span class="btn btn-sm btn-outline-primary rounded">{{ channel.consult.flow.title }}</span>
                                <span class="btn btn-sm rounded" :class="consultColor(channel.status, channel.consult.activationDate)">{{ consultStatus(channel.status, channel.consult.activationDate) }}</span>
                                <span class="btn btn-sm btn-dark rounded" v-if="channel.status === 0 && channel.consult.reactivationDate !== null">Reactivation</span>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-row flex-wrap justify-content-end" style="gap: 1rem">
                        <VTooltip
                            v-if="channel.status !== CHANNEL_STATUS.END && isClockedIn && TABS[activeTab].showToggleReadyForDoctors"
                            class="d-flex align-items-center"
                        >
                            <switches
                                @input="toggleReadyForDoctors(channel.isReady)"
                                :emit-on-mount="false"
                                v-model="channel.isReady"
                                :color="channel.isReady ? 'blue' : 'default'"
                            >
                            </switches>
                            <template #popper>
                                Ready for Doctors
                            </template>
                        </VTooltip>
                        <img :src="isInfoOpen ? '/assets/img/icon/info-circle-active-icon.svg' : '/assets/img/icon/info-circle-icon.svg'" class="cursor-pointer" width="24" @click="toggleInfo" alt="" />
                        <img v-if="channel ? channel.consult.user.hasDiagnose : false" :src="isMedicalRecordOpen ? '/assets/img/icon/medical-record-active-icon.svg' : '/assets/img/icon/medical-record-icon.svg'" class="cursor-pointer" width="24" @click="toggleMedicalRecord" alt="" />
                        <img v-if="isClockedIn" :src="isPrescriptionOpen ? '/assets/img/icon/prescription-active-icon.svg' : '/assets/img/icon/prescription-icon.svg'" class="cursor-pointer" width="24" @click="togglePrescription" alt="" />
                    </div>
                </div>
                <div class="d-flex flex-row overflow-hidden" style="flex: 1;">
                    <!-- Chatroom window -->
                    <div class="d-flex flex-column h-100 chatroom-window">
                        <!-- Chatroom - chat list -->
                        <div :class="chatWindowScrollDisabled ? 'overflow-hidden' : 'overflow-auto'" style="flex: 1; max-width: 100vw;" id="chat-window" ref="chatWindow">
                            <p v-if="isMessagesLoading" class="mb-0 mt-1 text-center f-12">Loading...</p>
                            <p
                                v-if="isMessagesFailed && !isMessagesLoading"
                                @click="getMessages(channel.id)"
                                class="mb-0 mt-1 text-center f-12 text-danger cursor-pointer"
                                style="text-decoration: underline"
                            >
                                Failed to load messages. Try Again
                            </p>
                            <div class="chat-content p-1 d-flex flex-column justify-content-end" style="min-height: 100%" v-if="messages.length > 0">
                                <div v-for="(message, index) in messages" :key="message.id">
                                    <div
                                        v-if="index === MESSAGES_INTERSECT_OBSERVER_POSITION && !isMessagesLoading && !isMessagesFailed && messagesTotalPages > messagesPage"
                                        v-waypoint="{ active: true, callback: handleIntersectObserverMessages }"
                                    >
                                    </div>
                                    <div v-if="message.accountId != channel.consult.user.accountId">
                                        <div class="chat-item right position-relative">
                                            <div class="chat-even" v-if="message.attachment && message.attachment.length > 0" v-viewer>
                                                <div class="d-flex flex-row gap-1 mb-1">
                                                    <img
                                                        :src="image"
                                                        alt=""
                                                        v-for="(image, idx_image) in message.attachment"
                                                        :key="idx_image"
                                                        style="width: 64px; height: 64px; object-fit: cover;"
                                                        class="rounded pointer"
                                                    />
                                                </div>
                                                <span v-html="message.message"></span>
                                            </div>
                                            <div class="chat-even" style="overflow-wrap: anywhere; word-break: normal; white-space: pre-line;" v-else-if="message.message != ''" v-html="message.message" />
                                            <template v-if="isClockedIn">
                                                <div v-if="!message.isSending && !message.isFailed" class="position-absolute" style="right: 0.5rem; top: 0.125rem;">
                                                    <dropdown-menu position="right">
                                                        <div slot="trigger">
                                                            <span class="f-12">
                                                                <i class="fa fa-chevron-down"></i>
                                                            </span>
                                                        </div>
                                                        <template #content="contentProps">
                                                            <div class="p-1">
                                                                <span
                                                                    class="cursor-pointer"
                                                                    @click="() => {
                                                                        contentProps.closeDropdown();
                                                                        handleDeleteChat(index);
                                                                    }"
                                                                >
                                                                    Delete
                                                                </span>
                                                            </div>
                                                        </template>
                                                    </dropdown-menu>
                                                </div>
                                            </template>
                                        </div>
                                        <p class="text-right f-12" style="margin-top:5px;">
                                            <b>{{ message.name }} - <span class="text-blue">{{ message.role }}</span></b><br>
                                            <span v-if="message.isFailed" class="text-danger text-italic d-flex flex-row justify-content-end align-items-center" style="gap: .25rem;">
                                                <i class="fa fa-exclamation-circle f-16"></i>
                                                Pesan tidak terkirim
                                            </span>
                                            <span v-else-if="message.isSending" style="color:#8C93A0">Mengirim...</span>
                                            <span v-else style="color:#8C93A0">
                                                {{ message.timestamp | moment('DD MMM YYYY - HH:mm')}} WIB
                                            </span>
                                        </p>
                                    </div>
                                    <div v-else>
                                        <div class="chat-item left">
                                            <div class="chat-odd" v-if="message.attachment && message.attachment.length > 0" v-viewer>
                                                <div class="d-flex flex-row gap-1 mb-1">
                                                    <img
                                                        :src="image"
                                                        alt=""
                                                        v-for="(image, idx_image) in message.attachment"
                                                        :key="idx_image"
                                                        style="width: 64px; height: 64px; object-fit: cover;"
                                                        class="rounded pointer"
                                                    />
                                                </div>
                                                <span v-html="message.message"></span>
                                            </div>
                                            <div class="chat-odd" style="overflow-wrap: anywhere; word-break: normal; white-space: pre-line;" v-else-if="message.message != ''" v-html="message.message" />
                                        </div>
                                        <p class="f-12" style="margin-top:5px;">
                                            <span style="color:#8C93A0">{{ message.timestamp | moment('DD MMM YYYY - HH:mm')}} WIB</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Chatroom - input chat -->
                        <div class="d-flex flex-row bg-white w-100" v-if="isClockedIn && channel.status !== CHANNEL_STATUS.END">
                            <div class="p-1 w-100" style="border-top: 1px solid #BCC0D1;">
                                <div class="row">
                                    <div v-for="(image, index) in uploadedImages" :key="index">
                                        <div v-if="image != null" class="text-center profile-avatar">
                                            <img :src="image" class="img-fluid rounded m-1" alt="" style="width: 50px; height: 50px;"><br>
                                            <div class="edit-avatar">
                                                <button class="btn btn-rounded btn-danger" 
                                                    title="Delete Image" @click="deleteFile(index)"
                                                    type="button">
                                                    <i class="fa fa-times"></i>
                                                </button>
                                            </div>
                                        </div>                                                        
                                    </div>
                                </div>
        
                                <div class="d-flex flex-row justify-content-center align-items-stretch" style="gap: .75rem;">
                                    <quill-editor 
                                        v-model="message" 
                                        v-on:keyup.enter="sendMessage()"
                                        :options="editorOption"
                                        ref="inputChat"
                                        class="flex-1"
                                    />
                                    <emoji-picker @emoji="append" class="position-relative d-flex align-items-center">
                                        <div
                                            class="emoji-invoker"
                                            slot="emoji-invoker"
                                            slot-scope="{ events: { click: clickEvent } }"
                                            @click.stop="clickEvent">
                                            <img src="/assets/img/icon/emoji-icon.svg" class="cursor-pointer" width="21" height="21" alt="" />
                                        </div>
        
                                        <div slot="emoji-picker" slot-scope="{ emojis, insert }">
                                            <div class="emoji-picker" style="top: 0; right: 0; transform: translateY(-100%);">
                                            <div>
                                                <div v-for="(emojiGroup, category) in emojis" :key="category">
                                                    <h5>{{ category }}</h5>
                                                    <div class="emojis">
                                                        <span
                                                            v-for="(emoji, emojiName) in emojiGroup"
                                                            :key="emojiName"
                                                            @click="insert(emoji)"
                                                            :title="emojiName">
                                                            {{ emoji }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </emoji-picker>
                                    <div class="border-left-secondary border-right-secondary d-flex align-items-center" style="margin: 5px 0; padding: 0 .75rem;">
                                        <label for="file-upload" class="cursor-pointer">
                                            <img src="/assets/img/icon/pick-image-icon.svg" width="21" height="21" alt="" />
                                        </label>
                                        <input id="file-upload" ref="fileUpload" class="input-file-avatar d-none" type="file" accept="image/png, image/jpeg" @change="handleFileUpload( $event )" multiple>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <img v-if="isChatFieldEmpty" src="/assets/img/icon/send-icon.svg" width="21" height="21" alt="" />
                                        <img v-else src="/assets/img/icon/send-active-icon.svg" @click="sendMessage()" class="cursor-pointer" width="21" height="21" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Info sidebar -->
                    <div v-if="isInfoOpen" style="border-left: 1px solid #BCC0D1" class="chatroom-sidebar">
                        <ChannelInfo
                            :closeInfo="closeInfo"
                            :isLoadingConsult="isLoadingConsult"
                            :consult="consult"
                            :media="media"
                            :isMediaLoading="isMediaLoading"
                            :mediaPage="mediaPage"
                            :mediaTotalPages="mediaTotalPages"
                            :nextMediaPage="nextMediaPage"
                        />
                    </div>

                    <!-- Medical Record sidebar -->
                    <div v-if="isMedicalRecordOpen" style="border-left: 1px solid #BCC0D1" class="chatroom-sidebar">
                        <MedicalRecord
                            ref="medicalRecord"
                            :isOpen="isMedicalRecordOpen"
                            :userId="channel ? channel.consult.user.id : null"
                            :consultId="channel ? channel.consultId : null"
                            @close="closeMedicalRecord"
                            @add="addPrescription"
                            @copyPrescription="copyPrescriptionFromMedicalRecord"
                            :isLoadingConsult="isLoadingConsult"
                            :consult="consult"
                            :prescriptionUserTags="prescriptionUserTags"
                            :USER_TAGS="USER_TAGS"
                            :isMedicationLoading="isMedicationLoading"
                            :medications="medications"
                        />
                    </div>

                    <!-- Prescription sidebar -->
                    <div v-if="isPrescriptionOpen" style="border-left: 1px solid #BCC0D1" class="chatroom-sidebar">
                        <Prescription
                            ref="prescription"
                            :closePrescription="closePrescription"
                            :channel="channel"
                            :isLoadingConsult="isLoadingConsult"
                            :consult="consult"
                            :prescriptionUserTags="prescriptionUserTags"
                            :updatePrescriptionUserTag="updatePrescriptionUserTag"
                            :USER_TAGS="USER_TAGS"
                            :isMedicationLoading="isMedicationLoading"
                            :medications="medications"
                            :prescription="prescription"
                            :diagnoses="diagnoses"
                            :hasUserProduct="hasUserProduct"
                            :productOptions="productOptions"
                            :deletePrescriptionItem="deletePrescriptionItem"
                            :addPrescriptionItem="addPrescriptionItem"
                            :openPrescriptionTemplate="openPrescriptionTemplate"
                            :isLoadingSubmit="isLoadingSubmit"
                            :CHANNEL_STATUS="CHANNEL_STATUS"
                            :reject="reject"
                            @approve="approve"
                        />
                    </div>
                </div>
            </div>
        </div>

        <!-- Prescription Template -->
        <PrescriptionTemplate
            :isOpen="isPrescriptionTemplateOpen"
            :products="prescriptionTemplateProductsProp"
            :isProductsValid="isPrescriptionTemplateProductsValid"
            @close="closePrescriptionTemplate"
            @useTemplate="applyPrescriptionTemplate"
            @failedSubmit="failedSubmitPrescriptionTemplate"
        />

        <!-- Clock-In Modal -->
        <modal :name="LOW_VALUE_CUSTOMER_MODAL" :adaptive="true" height="auto" :width="450" style="z-index: 2000;">
            <div class="d-flex flex-column gap-1 h-100 p-2">
                <p class="f-18 font-600 mb-2">Are you sure you want to mark customer as low value?</p>
                <div>
                    <textarea rows="3" class="form-control" placeholder="Input reason" v-model="lowValueCustomer.reason"></textarea>
                    <p class="mb-0 f-12">Min. 3 characters</p>
                </div>
                <div class="d-flex flex-row gap-1 justify-content-end mt-2">
                    <button class="btn btn-outline-primary" @click="closeLowValueCustomerModal()">No</button>
                    <button class="btn btn-primary" :disabled="lowValueCustomer.reason === null || lowValueCustomer.reason.replace(/\s/g,'').length <= 2 || isLoadingSubmitLowValueCustomer" @click="markAsLowValueCustomer()">
                        {{ isLoadingSubmitLowValueCustomer ? 'Loading...' : 'Yes'}}
                    </button>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import sanitizeHtml from 'sanitize-html';
import DropdownMenu from '../../components/DropdownMenu/DropdownMenu';
import MedicalRecord from './MedicalRecord.vue';
import PrescriptionTemplate from './PrescriptionTemplate.vue';
import AssigneeFilter from './AssigneeFilter.vue';
import ChannelInfo from './ChannelInfo.vue';
import Prescription from './Prescription.vue';
import ChannelFilter from '@/components/ChannelFilter.vue';
import moment from 'moment';
import debounce from 'debounce';
import { v4 as uuidv4 } from 'uuid';
import { getOrderTypeLabel, getOrderTypeIcon } from '@/lib/order';
import {
    MANUAL_ASSIGNMENT_TAB_KEYS,
    ORDERED_MANUAL_ASSIGNMENT_TAB_KEYS,
    MANUAL_ASSIGNMENT_TABS,
    AUTO_ASSIGNMENT_TAB_KEYS,
    ORDERED_AUTO_ASSIGNMENT_TAB_KEYS,
    AUTO_ASSIGNMENT_TABS,
} from '@/constants/channels';

import { TAGS as USER_TAGS } from '../../constants/userTags';
import AllergicActiveIcon from '../../assets/icons/allergic-active-icon.svg';
import AllergicInactiveIcon from '../../assets/icons/allergic-inactive-icon.svg';

const INFO_WIDTH = 480;
const PRESCRIPTION_WIDTH = 480;
const MEDICAL_RECORD_WIDTH = 480;

const DEFAULT_K_DATE = moment().add(30, 'days').format('YYYY-MM-DD');
const DEFAULT_ITER = 3;

const MEDIA_LIMIT = 30;
const MESSAGES_LIMIT = 20;
const MESSAGES_INTERSECT_OBSERVER_POSITION = 3;

const LOW_VALUE_CUSTOMER_MODAL = 'low-value-customer-modal';

export default {
    components: {
        DropdownMenu,
        MedicalRecord,
        PrescriptionTemplate,
        ChannelFilter,
        AssigneeFilter,
        ChannelInfo,
        Prescription,
    },
    data() {
        return {
            // search & filter
            activeTab: AUTO_ASSIGNMENT_TAB_KEYS.ALL,
            tabCount: {
                [MANUAL_ASSIGNMENT_TAB_KEYS.TO_RESPOND]: 0,
                [MANUAL_ASSIGNMENT_TAB_KEYS.TRIAGE]: 0,
                [AUTO_ASSIGNMENT_TAB_KEYS.ASSIGNED_TO_ME]: 0,
                [AUTO_ASSIGNMENT_TAB_KEYS.ASSIGNED]: 0,
                [AUTO_ASSIGNMENT_TAB_KEYS.UNASSIGNED]: 0,
                [AUTO_ASSIGNMENT_TAB_KEYS.TRIAGE]: 0,
            },
            searchParam: { value: 'consultNumber', label: 'Konsul ID' },
            searchMessage: '',
            filterParamsObject: null,
            assigneeFilterParamsObject: null,

            // channel states
            CHANNEL_STATUS: {
                REVIEW: 0,
                ACTIVE: 1,
                END: 2,
                REVIEW_TO: 4, // TEBUS OBAT, for filter request only
            },
            isLoadingChannels: true,
            channels: [],
            channel: null,
            page: 1,
            lastPage: 1,
            
            // messages
            MESSAGES_INTERSECT_OBSERVER_POSITION,
            chatWindowScrollDisabled: false,
            isMessagesLoading: false,
            isMessagesFailed: false,
            messages: [],
            messagesPage: 1,
            messagesTotalPages: 1,

            // chat states
            message: '',
            attachment: [],
            uploadedImages: [],
            max_file_size: 4,
            editorOption: {
                formats: ['link', 'list'],
                modules: {
                    toolbar: false,
                    clipboard: {
                        matchers: [
                            // handle paste URL
                            [
                                Node.TEXT_NODE,
                                (node, delta) => {
                                    const regex = /https?:\/\/[^\s]+/g;
                                    if (typeof(node.data) !== 'string') return;
                                    const matches = node.data.match(regex);

                                    if (matches && matches.length > 0) {
                                        const ops = [];
                                        let str = node.data;
                                        matches.forEach((match) => {
                                            const split = str.split(match);
                                            const beforeLink = split.shift();
                                            ops.push({ insert: beforeLink });
                                            ops.push({ insert: match, attributes: { link: match } });
                                            str = split.join(match);
                                        });
                                        ops.push({ insert: str });
                                        delta.ops = ops;
                                    }
                                    return delta;
                                }
                            ]
                        ]
                    }
                },
                placeholder:"Halo, saya dokter (nama)...",
            },

            // consult states
            isLoadingConsult: false,
            consult: null,

            // prescription states
            prescription: {
                p: '',
                d: null,
                dNotes: '',
                c: '',
                items: [],
                k: DEFAULT_K_DATE,
                iter: DEFAULT_ITER,
            },
            prescriptionUserTags: {
                redFlag: false,
                pregnant: false,
                breastFeeding: false,
                promil: false,
                kb: false,
                preferFemaleDoctor: false,
                allergic: '',
                importantNotes: '',
            },
            products: [],
            productOptions: [],
            diagnoses: [],
            isLoadingSubmit: false,
            
            // others
            isInfoOpen: false,
            isMedicalRecordOpen: false,
            isPrescriptionTemplateOpen: false,
            isPrescriptionOpen: false,
            infoWrapperWidth: `${INFO_WIDTH}px`,
            prescriptionWrapperWidth: `${PRESCRIPTION_WIDTH}px`,
            medicalRecordWrapperWidth: `${MEDICAL_RECORD_WIDTH}px`,
            isNavbarShow: true,

            // user tags
            USER_TAGS,
            allergic: '',
            errorAllergic: '',
            AllergicActiveIcon,
            AllergicInactiveIcon,

            // media
            media: {},
            isMediaLoading: false,
            mediaPage: 1,
            mediaTotalPages: 1,

            // medication
            medications: [],
            isMedicationLoading: false,

            // low value customer
            isLoadingSubmitLowValueCustomer: false,
            lowValueCustomer: {
                channelId: null,
                reason: null
            },
            LOW_VALUE_CUSTOMER_MODAL,
        }
    },
    computed: {
        permissions() {
            return this.$store.getters.permissions;
        },
        hasEditPermission() {
            return this.permissions.includes('consult_prescription:edit');
        },
        isChatFieldEmpty() {
            return this.attachment.length === 0 && this.message.replace(/<[^>]*>?/gm, '').trim().length === 0;
        },
        isAutoAssignmentActive() {
            return this.$store.getters.isAutoAssignmentActive;
        },
        TABS_KEYS: function() {
            return this.isAutoAssignmentActive ? AUTO_ASSIGNMENT_TAB_KEYS : MANUAL_ASSIGNMENT_TAB_KEYS;
        },
        ORDERED_TABS_KEYS: function() {
            return this.isAutoAssignmentActive
                ? ORDERED_AUTO_ASSIGNMENT_TAB_KEYS.filter(key => this.userSubRolePermissionSlugs.includes(AUTO_ASSIGNMENT_TABS[key].permissionSlug))
                : ORDERED_MANUAL_ASSIGNMENT_TAB_KEYS;
        },
        TABS: function() {
            return this.isAutoAssignmentActive ? AUTO_ASSIGNMENT_TABS : MANUAL_ASSIGNMENT_TABS;
        },
        account: function() {
            return this.$store.state.account;
        },
        isClockedIn: function() {
            return this.$store.getters.isUserClockedIn;
        },
        adminId: function() {
            return this.$store.getters.adminId;
        },
        userSubRole: function() {
            return this.$store.getters.userSubRole;
        },
        userSubRolePermissionSlugs: function() {
            return this.$store.getters.userSubRolePermissionSlugs;
        },
        showSearchBar: function() {
            return this.userSubRolePermissionSlugs.includes('consult_prescription:search_bar');
        },
        showFilter: function() {
            return this.userSubRolePermissionSlugs.includes('consult_prescription:filter');
        },
        userSubRoleDefaultChannelTab: function() {
            return this.$store.getters.userSubRoleDefaultChannelTab;
        },
        defaultChannelTab: function() {
            return this.isAutoAssignmentActive ? this.userSubRoleDefaultChannelTab : MANUAL_ASSIGNMENT_TAB_KEYS.TO_RESPOND;
        },
        hasUserProduct: function() {
            return this.channel?.status !== this.CHANNEL_STATUS.ACTIVE && this.consult?.products?.filter(product => product.consult_product.px == false).length > 0;
        },
        usedLimit() {
            return this.TABS[this.activeTab].limitPerPage;
        },
        pusherConnectionState() {
            return this.$store.state.pusherConnectionState;
        },
        prescriptionTemplateProductsProp() {
            return this.prescription.items.filter(item => item.object !== null).map(item => ({
                productId: item.object.id,
                usageAmount: item.usageAmount,
                usageInterval: item.usageInterval,
                recommended: item.recommended,
            }));
        },
        isPrescriptionTemplateProductsValid() {
            return this.prescription.items.length > 0
                && this.prescription.items.every((item) => {
                    const isValid =  item.object !== null &&
                        item.usageAmount &&
                        item.usageAmount >= item.object.minPurchaseQuantity &&
                        item.usageInterval.length > 0 &&
                        item.usageInterval.length <= 255
                    return item.object.maxPurchaseQuantity
                        ? isValid && (item.usageAmount <= item.object.maxPurchaseQuantity)
                        : isValid
                });
        },
    },
    beforeDestroy() {
        this.$emit('show-mobile-navbar');
        this.isNavbarShow = true;
    },
    methods: {
        sanitizeSearchMessage(message) {
            if (this.searchParam.value === 'email') {
                return message.replace(/ /g, '');
            }
            if (this.searchParam.value === 'mobileNumber') {
                const trimmedMessage = message.replace(/ /g, '');
                if (trimmedMessage.startsWith('+62')) {
                    return `0${trimmedMessage.slice(3)}`;
                }
                return trimmedMessage;
            }
            return message;
        },
        getChannels(options = {}) {
            const {
                page = this.page,
                limit = this.usedLimit,
                withLoading = true,
                mode = "REPLACE", // "APPEND" or "REPLACE"
            } = options;
            const fetchedFilters = {
                activeTab: this.activeTab,
                searchParam: this.showSearchBar ? this.searchParam.value : '',
                searchMessage: this.showSearchBar ? this.searchMessage : '',
            };

            if (withLoading) {
                this.isLoadingChannels = true;
                if (mode === 'REPLACE') {
                    this.channels = [];
                }
            }

            const tabFilters = typeof this.TABS[this.activeTab].filter === 'function'
                ? this.TABS[this.activeTab].filter(this.adminId)
                : this.TABS[this.activeTab].filter;

            // handle END tab status filter
            if (typeof tabFilters.status !== 'undefined' && this.filterParamsObject && this.filterParamsObject.status) {
                this.channels = [];
                this.lastPage = 0;
                this.isLoadingChannels = false;
                return;
            }

            const assigneeFilterParamsObject = this.assigneeFilterParamsObject && this.assigneeFilterParamsObject.assignedTo
                ? this.assigneeFilterParamsObject
                : {};

            const params = {
                [this.searchParam.value]: fetchedFilters.searchMessage === '' ? undefined : this.sanitizeSearchMessage(fetchedFilters.searchMessage),
                page,
                limit,
                ...this.filterParamsObject,
                ...tabFilters,
                ...assigneeFilterParamsObject,
            };

            this.axios.get('/channels', {
                params
            }).then(resp => {
                // check filter & search states to prevent race condition
                if (
                    fetchedFilters.activeTab === this.activeTab &&
                    fetchedFilters.searchMessage === (this.showSearchBar ? this.searchMessage : '') && 
                    fetchedFilters.searchParam === (this.showSearchBar ? this.searchParam.value : '')
                ) {
                    const { rows, totalPages } = resp.data.data;
                    this.lastPage = totalPages;
                    if (mode === "APPEND") {
                        this.channels = [...this.channels, ...rows];
                    } else {
                        // mode === "REPLACE"
                        this.channels = [...rows];
                    }
                    this.isLoadingChannels = false;
                }

            });
        },
        handleChangeFilter(filterParamsObject) {
            this.filterParamsObject = filterParamsObject;
            this.page = 1;
            this.getChannels();
            this.$refs.filterDropdown.closeDropdown();
        },
        resetFilter() {
            this.filterParamsObject = null;
            this.page = 1;
            this.getChannels();
            this.$refs.filterDropdown.closeDropdown();
        },
        handleChangeAssigneeFilter(assigneeFilterParamsObject) {
            this.assigneeFilterParamsObject = assigneeFilterParamsObject;
            this.page = 1;
            this.getChannels();
            this.$refs.assigneeFilterDropdown.closeDropdown();
        },
        resetAssigneeFilter() {
            this.assigneeFilterParamsObject = null;
            this.page = 1;
            this.getChannels();
            this.$refs.assigneeFilterDropdown.closeDropdown();
        },
        refreshAssigneeFilter(assigneeFilterParamsObject) {
            this.assigneeFilterParamsObject = assigneeFilterParamsObject;
            this.page = 1;
            this.getChannels();
        },
        getTabCount() {
            this.axios.get('/channels/counter')
            .then(resp => {
                const { toRespond, triage, assignedToMe, assigned, unAssigned } = resp.data.data;
                this.tabCount = {
                    [MANUAL_ASSIGNMENT_TAB_KEYS.TO_RESPOND]: toRespond,
                    [MANUAL_ASSIGNMENT_TAB_KEYS.TRIAGE]: triage,
                    [AUTO_ASSIGNMENT_TAB_KEYS.ASSIGNED_TO_ME]: assignedToMe,
                    [AUTO_ASSIGNMENT_TAB_KEYS.ASSIGNED]: assigned,
                    [AUTO_ASSIGNMENT_TAB_KEYS.UNASSIGNED]: unAssigned,
                    [AUTO_ASSIGNMENT_TAB_KEYS.TRIAGE]: triage,
                };
                this.$forceUpdate();
            });
        },
        nextChannelsPage() {
            this.page += 1;
            this.getChannels({ mode: 'APPEND' });
        },
        subscribe(data) {
            if (this.channel?.consultId === data.consultId) {
                return;
            }

            this.channel = { ...data };
            this.allergic = data.consult.user.userTags ? data.consult.user.userTags.allergic : '';
            this.errorAllergic = '';
            this.$emit('hide-mobile-navbar');
            this.isNavbarShow = false;
            this.prescriptionUserTags = {
                redFlag: data.consult.user.userTags?.redFlag ?? false,
                pregnant: data.consult.user.userTags?.pregnant ?? false,
                breastFeeding: data.consult.user.userTags?.breastFeeding ?? false,
                promil: data.consult.user.userTags?.promil ?? false,
                kb: data.consult.user.userTags?.kb ?? false,
                preferFemaleDoctor: data.consult.user.userTags?.preferFemaleDoctor ?? false,
                allergic: data.consult.user.userTags?.allergic ?? '',
                importantNotes: data.consult.user.userTags?.importantNotes ?? '',
            };

            if (this.$route.query.chat !== data.consultId) {
                this.$router.replace({ query: { chat: data.consultId } });
            }
            
            // reset data
            this.resetChatInputFields();
            this.consult = null;
            this.resetPrescriptionFields();
            this.isLoadingSubmit = false;
            this.diagnoses = [];
            this.productOptions = [];
            this.medications = [];
            this.isMedicationLoading = false;
            this.$forceUpdate();
            
            this.resetMedia();
            this.closeAllSidebars();
            
            // get messages and reset unread count
            this.resetMessagesState();
            this.getMessages(data.id);
            if (this.hasEditPermission) {
                this.resetReadCount(data.id);
            }
        },
        resetMessagesState() {
            this.messages = [];
            this.messagesPage = 1;
            this.messagesTotalPages = 1;
        },
        getMessages(id) {
            const fetchedChannelId = id;
            this.isMessagesLoading = true;
            this.isMessagesFailed = false;
            this.axios.get(`/channels/${id}/messages/v2`, {
                params: {
                    page: this.messagesPage,
                    limit: MESSAGES_LIMIT,
                }
            }).then(resp => {
                if (fetchedChannelId === this.channel.id) { // prevent race condition
                    this.chatWindowScrollDisabled = true;
                    const scrollBottom = this.$refs.chatWindow.scrollHeight - this.$refs.chatWindow.scrollTop;
                    const { rows, currentPage, totalPages } = resp.data.data;
                    this.messagesTotalPages = totalPages;
                    this.messages = [...rows, ...this.messages];

                    if (currentPage === 1) {
                        setTimeout(() => {
                            this.$refs.chatWindow.scrollTop = this.$refs.chatWindow.scrollHeight
                            this.chatWindowScrollDisabled = false;
                            this.isMessagesLoading = false;
                        }, 100);
                    } else {
                        this.$nextTick(() => {
                            this.$refs.chatWindow.scrollTop = this.$refs.chatWindow.scrollHeight - scrollBottom;
                            this.chatWindowScrollDisabled = false;
                        });
                        this.isMessagesLoading = false;
                    }
                }
            }).catch(() => {
                this.isMessagesLoading = false;
                this.isMessagesFailed = true;
            });
        },
        handleIntersectObserverMessages(event) {
            if (event.going === 'in') {
                this.messagesPage += 1;
                this.getMessages(this.channel.id);
            }
        },
        resetChatInputFields() {
            this.attachment = [];
            this.uploadedImages = [];
            this.message = '';
            if (this.$refs.inputChat) {
                this.$refs.inputChat.quill.setContents([]);
            }
            if (this.$refs.fileUpload) {
                this.$refs.fileUpload.value = '';
            }
            this.$forceUpdate();
        },
        sendMessage() {
            if (this.isChatFieldEmpty) {
                this.$notify({
                    type: 'error',
                    title: 'Gagal mengirim pesan',
                    text: 'Pesan tidak boleh kosong'
                })
                return;
            }

            // transform URL
            const regex = /https?:\/\/[^\s]+/g;
            const { ops } = this.$refs.inputChat.quill.getContents();
            const newOps = [];
            ops.forEach(op => {
                let str = op.insert;
                const matches = str.match(regex);
                if (matches && matches.length > 0) {
                    matches.forEach((match) => {
                        const split = str.split(match);
                        const beforeLink = split.shift();
                        newOps.push({ insert: beforeLink });
                        newOps.push({ insert: match, attributes: { link: match } });
                        str = split.join(match);
                    });
                    newOps.push({ insert: str });
                } else {
                    newOps.push(op);
                }
            });

            // push new chat bubble to chatroom
            const clientMessageId = uuidv4();
            const messageObj = {
                accountId: this.account.admin.accountId,
                consultId: this.channel.consultId,
                name: this.account.admin.fullName,
                avatar: this.account.admin.avatar,
                role: this.account.admin.role.name,
                message: sanitizeHtml(this.message),
                attachment: this.uploadedImages,
                timestamp: new Date().getTime(),
                clientMessageId,
                isSending: true,
                isFailed: false,
            };
            this.messages.push(messageObj);
            this.channel.lastMessage = messageObj;
            this.channel.updatedAt = messageObj.timestamp;

            setTimeout(() => {
                const chatWindow = this.$el.querySelector('#chat-window');
                chatWindow.scrollTop = chatWindow.scrollHeight;
                const chatList = this.$el.querySelector('#chat-list');
                chatList.scrollTop = -chatList.scrollHeight;
            }, 100);

            const FData = new FormData();
            FData.append('message', sanitizeHtml(this.message));
            FData.append('channelName', this.channel.name);
            FData.append('clientMessageId', clientMessageId);

            for (let index = 0; index < this.attachment.length; index++) {
                FData.append('attachment', this.attachment[index]);
            }

            this.resetChatInputFields();
            this.$refs.inputChat.quill.focus();

            this.axios.post('/chat/send', FData)
                .then(() => {
                    const updatedMessageIndex = this.messages.findIndex(msg => msg.clientMessageId === clientMessageId);
                    if (updatedMessageIndex !== -1) {
                        this.messages[updatedMessageIndex].isSending = false;
                        this.messages[updatedMessageIndex].isFailed = false;
                        this.$forceUpdate();
                    }
                    if (this.TABS[this.activeTab].shouldRemoveChannelOnClinicalSendMessage) {
                        // remove from channel list
                        this.channels = this.channels.filter(channel => channel.id !== this.channel.id);
                        this.$forceUpdate();

                        // fetch channel list after removing item to make sure pagination is consistent
                        if (this.TABS[this.activeTab].isPaginated) {
                            this.getChannels({
                                page: 1,
                                limit: this.page * this.usedLimit,
                                withLoading: false,
                            });
                        }
                    }
                    this.getTabCount();
                })
                .catch(err => {
                    const updatedMessageIndex = this.messages.findIndex(msg => msg.clientMessageId === clientMessageId);
                    if (updatedMessageIndex !== -1) {
                        this.messages[updatedMessageIndex].isSending = false;
                        this.messages[updatedMessageIndex].isFailed = true;
                        this.$forceUpdate();
                    }

                    this.$notify({
                        type: 'error',
                        title: 'Error',
                        text: err.response.data.message
                    });
                });
        },
        append(emoji) {
            const editor = this.$refs.inputChat.quill;
            const cursorPosition = editor.getSelection(true);
            editor.insertText(cursorPosition, emoji);
        },
        addPrescriptionItem() {
            this.prescription.items.push({
                recommended: false,
                object: null,
                usageAmount: '',
                usageInterval: '',
            });
            this.$forceUpdate();
        },
        getProducts() {
            return new Promise((resolve, reject) => {
                this.axios.get('products', {
                    params: {
                        type: 'in:Rx Red,Rx Blue,OTC',
                        isActive: true,
                    },
                })
                    .then(resp => {
                        this.productOptions = resp.data.data.rows;
                        this.$forceUpdate();
                        // check added products (if any)
                        const addedItems = [...this.prescription.items];
                        this.prescription.items = [];
                        addedItems.forEach(item => {
                            if (item.object) {
                                const productObj = this.productOptions.find(option => option.id === item.object.id);
                                if (productObj) {
                                    this.prescription.items.push({
                                        recommended: item.recommended,
                                        object: productObj,
                                        usageAmount: item.usageAmount,
                                        usageInterval: item.usageInterval,
                                    });
                                } else {
                                    this.$notify({
                                        type: 'error',
                                        title: 'Error',
                                        text: `Product "${item.object.title}" is not found`
                                    });
                                }
                            }
                        });
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        getDiagnosis() {
            return new Promise((resolve, reject) => {
                this.axios.get('/misc/icd10cm')
                    .then(resp => {
                        this.diagnoses = resp.data.data;
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        resetPrescriptionFields() {
            this.prescription = {
                p: '',
                d: null,
                dNotes: '',
                c: '',
                items: [],
                k: DEFAULT_K_DATE,
                iter: DEFAULT_ITER,
            };
            if (this.$refs.prescription?.getRefs('observer')) {
                this.$refs.prescription?.getRefs('observer').reset();
            }
            this.$forceUpdate();
        },
        approve() {
            this.isLoadingSubmit = true;
            const payload = {
                consultId: this.consult.id,
                c: this.prescription.c,
                d: this.prescription.d.code,
                dNotes: this.prescription.dNotes,
                iter: this.prescription.iter,
                k: this.prescription.k,
                p: this.prescription.p,
                recipes: this.hasUserProduct ? [] : this.prescription.items.map(item => ({
                    productId: item.object.id,
                    recommended: item.recommended,
                    usageAmount: item.usageAmount,
                    usageInterval: item.usageInterval,
                })),
                userTags: this.prescriptionUserTags,
            };

            this.axios.post('/diagnoses', payload)
                .then(resp => {
                    const { orders, activationDate, expiredAt } = resp.data.data.consult;

                    this.$toast.open({
                        message: "Diagnosis & Resep Submitted",
                        type: "info",
                        duration: 3000,
                    });

                    // update opened channel's user tags
                    this.channel.consult.user.userTags = {
                        ...this.channel.consult.user.userTags,
                        ...this.prescriptionUserTags,
                    };
                    this.allergic = this.prescriptionUserTags.allergic;

                    // update channel list user tags
                    const { id: userId } = this.channel.consult.user;
                    this.channels
                        .filter(channel => channel.consult.user.id === userId)
                        .forEach(channel => {
                            channel.consult.user.userTags = {
                                ...channel.consult.user.userTags,
                                ...this.prescriptionUserTags,
                            };
                        });

                    this.resetPrescriptionFields();
                    this.consult.activationDate = activationDate;
                    this.consult.expiredAt = expiredAt;
                    this.channel.consult.activationDate = activationDate;
                    this.channel.consult.expiredAt = expiredAt;
                    
                    if (orders[0].type === 3) {
                        this.channel.status = this.CHANNEL_STATUS.ACTIVE;
                    }

                    this.$forceUpdate();
                    this.closePrescription();

                    this.channels = this.channels.map(channel => channel.consultId === this.consult.id
                        ? {
                            ...channel,
                            consult: {
                                ...channel.consult,
                                activationDate,
                                expiredAt,
                            }
                        }
                        : channel
                    );
                })
                .catch(err => {
                    this.$notify({
                        type: 'error',
                        title: err.response.data.message,
                        text: err.response.data.data[0].message,
                    });
                })
                .finally(() => {
                    this.isLoadingSubmit = false;
                    this.$nextTick(() => this.$refs.prescription?.getRefs('observer').reset());
                });
        },
        reject() {
            this.$swal.fire({
                title: 'Tolak Konsultasi',
                text: 'Chat room konsultasi ini akan diakhiri. Yakin tolak konsultasi?',
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Tidak',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Yakin',
            }).then((result) => {
                if (result.value) {
                    this.axios.patch(`/diagnoses/reject/${this.consult.id}`)
                        .then(() => {
                            this.channel.status = this.CHANNEL_STATUS.END;
    
                            this.resetPrescriptionFields();
                            this.$forceUpdate();
                            this.closePrescription();
    
                            // remove from channel list
                            this.channels = this.channels.filter(channel => channel.consultId !== this.consult.id);

                            // fetch channel list after removing item to make sure pagination is consistent
                            if (this.TABS[this.activeTab].isPaginated) {
                                this.getChannels({
                                    page: 1,
                                    limit: this.page * this.usedLimit,
                                    withLoading: false,
                                });
                            }
                            this.getTabCount();
                        })
                        .catch(() => {
                            this.$notify({
                                type: 'error',
                                title: 'Gagal menolak konsul',
                            });
                        });
                }
            });
        },
        consultColor(x, activationDate)
        {
            let color = ''
            switch (x) {
                case 0:
                    if (activationDate) {
                        color = 'btn-review-to'
                    } else {
                        color = 'btn-warning'
                    }
                    break;
                case 1:
                    color = 'btn-success'
                    break;
                case 2:
                    color = 'btn-danger'
                    break;
            
                default:
                    color = 'btn-primary'
                    break;
            }

            return color;
        },
        consultStatus(x, activationDate)
        {
            let name = ''
            switch (x) {
                case 0:
                    if (activationDate) {
                        name = 'Review TO'
                    } else {
                        name = 'Review'
                    }
                    break;
                case 1:
                    name = 'Aktif'
                    break;
                case 2:
                    name = 'Berakhir'
                    break;
            
                default:
                    name = '-'
                    break;
            }

            return name;
        },
        handleFileUpload(event){
            let i = 0;
            while(i < event.target.files.length){
                let file = event.target.files[i];
                if(this.attachment.length >= 5){
                    this.$toast.open({
                            message: "Maximum 5 images at once",
                            type: "error",
                            duration: 3000,
                        });
                    break;
                } else {
                    if (file.size > ((1024 * 1024) * this.max_file_size)) {
                        event.preventDefault();                        
                        this.$toast.open({
                            message: `The image file is too large, allowed maximum size is ${this.max_file_size} MiB`,
                            type: "error",
                            duration: 3000,
                        });
                    } else{
                        this.attachment.push(file)
                        this.uploadedImages.push(URL.createObjectURL(file))
                    }
                }
                i++;
            }
        },
        deleteFile (id){
            this.attachment.splice( id, 1 )
            this.uploadedImages.splice( id, 1 )
        },
        deletePrescriptionItem(index){
            this.prescription.items = [...this.prescription.items.slice(0, index), ...this.prescription.items.slice(index + 1)];
            this.$forceUpdate();
        },
        resetReadCount(channelId) {
            this.axios.post('/chat/reset-read-count', { channelName: this.channel.name })
                .then(() => {
                    this.channel.unreadAdminCount = 0;
                    const updatedChannelIndex = this.channels.findIndex(channel => channel.id === channelId);
                    if (updatedChannelIndex !== -1) {
                        this.channels[updatedChannelIndex].unreadAdminCount = 0;
                    }
                    this.$forceUpdate();
                });
        },
        htmlStripTag(str) {
            return str.replaceAll('</p><p>', ' ').replace(/(<([^>]+)>)/gi, "")
        },
        getTypeIconUrl(type) {
            switch (type) {
                case 'Belanja':
                    return '/assets/img/icon/shop-icon.svg';
                case 'Konsultasi':
                    return '/assets/img/icon/consult-icon.svg';
                default:
                    return '/assets/img/icon/consult-icon.svg';
            }
        },
        openInfo() {
            this.closeAllSidebars();
            this.getConsultationDetail();
            this.getMedia(this.channel.consultId);
            this.isInfoOpen = true;
        },
        closeInfo() {
            this.resetMedia();
            this.isInfoOpen = false;
        },
        toggleInfo() {
            if (this.isInfoOpen) {
                this.closeInfo();
            } else {
                this.openInfo();
            }
        },
        openMedicalRecord() {
            this.closeAllSidebars();
            this.getConsultationDetail();
            this.getActiveMedication(),
            this.isMedicalRecordOpen = true;
        },
        closeMedicalRecord() {
            this.isMedicalRecordOpen = false;
        },
        toggleMedicalRecord() {
            if (this.isMedicalRecordOpen) {
                this.closeMedicalRecord();
            } else {
                this.openMedicalRecord();
            }
        },
        openPrescriptionTemplate() {
            this.isPrescriptionTemplateOpen = true;
        },
        closePrescriptionTemplate() {
            this.isPrescriptionTemplateOpen = false;
        },
        closeAllSidebars() {
            this.closeInfo();
            this.closeMedicalRecord();
            this.closePrescription();
            this.closePrescriptionTemplate();
        },
        async applyPrescriptionTemplate(products) {
            await this.getProducts();
            products.forEach(product => {
                const productObj = this.productOptions.find(item => item.id === product.productId);
                if (productObj) {
                    this.prescription.items.push({
                        recommended: product.recommended,
                        object: productObj,
                        usageAmount: product.usageAmount,
                        usageInterval: product.usageInterval,
                    });
                } else {
                    this.$notify({
                        type: 'error',
                        title: 'Error',
                        text: `Product "${product.title}" is not found`
                    });
                }
            });

            // validate amount
            setTimeout(() => {
                this.$refs.prescription?.getRefs('prescriptionItemAmountInputs').forEach(input => {
                    input.validate();
                });
            }, 1000);
        },
        failedSubmitPrescriptionTemplate() {
            this.$refs.prescription?.getRefs('prescriptionItemInputs').forEach(input => {
                input.validate();
            });
            this.$refs.prescription?.getRefs('prescriptionItemAmountInputs').forEach(input => {
                input.validate();
            });
            this.$refs.prescription?.getRefs('prescriptionItemUsageInputs').forEach(input => {
                input.validate();
            });
        },
        openPrescription(callback = () => {}) {
            this.closeAllSidebars();
            Promise.all([
                this.getConsultationDetail(),
                this.getActiveMedication(),
                this.getDiagnosis(),
                this.getProducts(),
            ]).then(() => {
                callback();
            });
            this.isPrescriptionOpen = true;
        },
        closePrescription() {
            this.isPrescriptionOpen = false;
        },
        togglePrescription() {
            if (this.isPrescriptionOpen) {
                this.closePrescription();
            } else {
                this.openPrescription();
            }
        },
        addPrescription() {
            this.resetPrescriptionFields();
            this.openPrescription();
        },
        copyPrescriptionFromMedicalRecord({ diagnose, products }) {
            this.resetPrescriptionFields();
            this.openPrescription(() => {
                // assign diagnose
                const diagnoseObj = this.diagnoses.find(item => item.id === diagnose.id);
                if (diagnoseObj) {
                    this.prescription.d = diagnoseObj;
                } else {
                    this.$notify({
                        type: 'error',
                        title: 'Error',
                        text: `Diagnose "${diagnose.name}" is not found`
                    });
                }

                // assign products
                this.prescription.items = [];
                products.forEach(product => {
                    const productObj = this.productOptions.find(item => item.id === product.id);
                    if (productObj) {
                        this.prescription.items.push({
                            recommended: product.recommended,
                            object: productObj,
                            usageAmount: product.usageAmount,
                            usageInterval: product.usageInterval,
                        });
                    } else {
                        this.$notify({
                            type: 'error',
                            title: 'Error',
                            text: `Product "${product.title}" is not found`
                        });
                    }
                });

                // validate amount
                setTimeout(() => {
                    this.$refs.prescription?.getRefs('prescriptionItemAmountInputs').forEach(input => {
                        input.validate();
                    });
                }, 1000);
            });
        },
        closeChatRoom() {
            this.consult = null;
            this.channel = null;
            this.$router.replace({ query: null });
            this.$emit('show-mobile-navbar');
            this.isNavbarShow = true;
        },
        getActiveMedication() {
            this.isMedicationLoading = true;
            return new Promise((resolve, reject) => {
                const { id: userId } = this.channel.consult.user;
                this.axios.get(`/v2/clinical/users/${userId}/active-medication`)
                    .then(res => {
                        // check whether the response is still valid or not to be applied
                        if (this.channel.consult.user.id === userId) {
                            this.medications = res.data.data;
                            this.isMedicationLoading = false;
                            resolve(res);
                        } else {
                            reject();
                        }
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        getConsultationDetail() {
            this.isLoadingConsult = true;
            return new Promise((resolve, reject) => {
                this.axios.get(`/consults/${this.channel.consultId}`)
                    .then(resp => {
                        // check whether the response is still valid or not to be applied
                        const data = resp.data.data;
                        if (this.channel.consultId === data.id) {
                            const types = data.orders.map(order => ({
                                label: getOrderTypeLabel(order.type),
                                icon: getOrderTypeIcon(order.type),
                            }));
                            this.consult = {
                                ...data,
                                types,
                            };
                            this.isLoadingConsult = false;
                            resolve(resp);
                        } else {
                            reject();
                        }
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        getChannelDetailByConsultId(consultId, callback) {
            this.axios.get(`/consults/${consultId}/channel`)
                .then(res => {
                    if (res?.data?.data) {
                        callback(res.data.data);
                    }
                })

        },
        getChannelDetail(channelId, callback) {
            this.axios.get(`/channels/${channelId}`)
                .then(res => {
                    if (res?.data?.data) {
                        callback(res.data.data);
                    }
                })
        },
        handleUnreadChannel(index) {
            if (this.channels[index].unreadAdminCount === 0) {
                const channel = this.channels[index];
                this.axios.post('/channels/mark-as-unread', {
                    channelName: channel.name,
                })
                    .then(() => {
                        this.closeChatRoom();
                        this.channels[index].unreadAdminCount = 1;
                        this.$forceUpdate();
                    });
            }
        },
        openLowValueCustomerModal(channelId) {
            this.lowValueCustomer.channelId = channelId;
            this.$forceUpdate();
            this.$modal.show(LOW_VALUE_CUSTOMER_MODAL);
        },
        closeLowValueCustomerModal() {
            this.lowValueCustomer = {
                channelId: null,
                reason: null,
            };
            this.$modal.hide(LOW_VALUE_CUSTOMER_MODAL);
        },
        markAsLowValueCustomer() {
            this.isLoadingSubmitLowValueCustomer = true;
            this.axios.patch(`/v2/clinical/channels/${this.lowValueCustomer.channelId}/mark-as-low-value-user`, {
                isLowValueCustomer: true,
                lowValueCustomerNotes: this.lowValueCustomer.reason,
            })
                .then(() => {
                    this.closeChatRoom();
                    // refresh channel list
                    this.getChannels({
                        page: 1,
                        limit: this.page * this.usedLimit,
                        withLoading: false,
                    });
                    this.getTabCount();
                })
                .catch(() => {
                    this.$notify({
                        type: 'error',
                        title: 'Error',
                        text: 'Failed to mark customer as low value'
                    });
                })
                .finally(() => {
                    this.isLoadingSubmitLowValueCustomer = false;
                    this.closeLowValueCustomerModal();
                });
        },
        unmarkAsLowValueCustomer(channelId) {
            this.axios.patch(`/v2/clinical/channels/${channelId}/mark-as-low-value-user`, {
                isLowValueCustomer: false,
                lowValueCustomerNotes: null,
            })
                .then(() => {
                    this.closeChatRoom();
                    // refresh channel list
                    this.getChannels({
                        page: 1,
                        limit: this.page * this.usedLimit,
                        withLoading: false,
                    });
                    this.getTabCount();
                })
                .catch(() => {
                    this.$notify({
                        type: 'error',
                        title: 'Error',
                        text: 'Failed to unmark customer as low value'
                    });
                })
        },
        handleDeleteChat(index) {
            const message = this.messages[index];
            if (typeof message.id !== 'undefined') {
                this.axios.delete(`/chat/${message.id}`)
                    .then((res) => {
                        const { message: deletedMessage } = res.data.data;
                        this.messages[index] = {
                            ...this.messages[index],
                            message: deletedMessage,
                            attachment: [],
                        };
                        const isLastMessage = this.messages.length === index + 1;
                        if (isLastMessage) {
                            const consultIndex = this.channels.findIndex(channel => channel.consultId === message.consultId);
                            if (consultIndex !== -1) {
                                this.channels[consultIndex].lastMessage = {
                                    ...this.channels[consultIndex].lastMessage,
                                    message: deletedMessage,
                                    attachment: [],
                                };
                            }
                        }
                        this.$forceUpdate();
                    });
            }
        },
        handleSearchMessage: debounce(function() {
            this.page = 1;
            this.getChannels();
        }, 1000),
        handleChangeTab(oldTab, newTab) {
            // reset assignee filter
            if (oldTab && this.TABS[oldTab].showAssigneeFilter && this.TABS[newTab].showAssigneeFilter) {
                this.$refs.assigneeFilter.resetFilterView();
            }
            this.assigneeFilterParamsObject = null;

            this.activeTab = newTab;
            this.page = 1;
            this.getChannels();
            this.getTabCount();

            if (typeof this.$route.query.chat === 'undefined') {
                this.closeChatRoom();
            }
        },
        toggleUserTag(tag, prevValue) {
            const { id: userId } = this.channel.consult.user;
            const newValue = !prevValue;
            this.axios.put(`/users/${userId}/tag`, {
                tag,
                value: newValue,
            }).then(() => {
                this.channel.consult.user.userTags = {
                    ...this.channel.consult.user.userTags,
                    [tag]: newValue,
                };
                this.channels
                    .filter(channel => channel.consult.user.id === userId)
                    .forEach(channel => {
                        channel.consult.user.userTags = {
                            ...channel.consult.user.userTags,
                            [tag]: newValue,
                        };
                    });
                this.prescriptionUserTags = {
                    ...this.prescriptionUserTags,
                    [tag]: newValue,
                }
                this.$forceUpdate();
            });
        },
        handleInputAllergic() {
            this.errorAllergic = '';
            if (this.allergic.length > 60) {
                this.errorAllergic = '*Maksimal 60 karakter';
            }
        },
        saveAllergic(cb) {
            if (!this.errorAllergic) {
                const { id: userId } = this.channel.consult.user;
                this.axios.put(`/users/${userId}/tag`, {
                    tag: 'allergic',
                    value: this.allergic,
                }).then(() => {
                    this.channel.consult.user.userTags = {
                        ...this.channel.consult.user.userTags,
                        allergic: this.allergic,
                    };
                    this.channels
                        .filter(channel => channel.consult.user.id === userId)
                        .forEach(channel => {
                            channel.consult.user.userTags = {
                                ...channel.consult.user.userTags,
                                allergic: this.allergic,
                            };
                        });
                    this.prescriptionUserTags = {
                        ...this.prescriptionUserTags,
                        allergic: this.allergic,
                    }
                    this.$forceUpdate();
                    cb();
                });
            }
        },
        updatePrescriptionUserTag(tag, value) {
            this.prescriptionUserTags[tag] = value;
            this.$forceUpdate();
        },
        resetMedia() {
            this.media = {};
            this.isMediaLoading = false;
            this.mediaPage = 1;
            this.mediaTotalPages = 1;
            this.$forceUpdate();
        },
        getMedia(consultId) {
            this.isMediaLoading = true;
            this.axios.get('/medias', {
                params: {
                    consultId,
                    page: this.mediaPage,
                    limit: MEDIA_LIMIT,
                    sortBy: 'id.DESC',
                }
            })
                .then(res => {
                    const newMedia = { ...this.media };
                    (res?.data?.data?.rows ?? []).forEach(item => {
                        const yearAndMonth = item.createdAt.slice(0, 7);
                        if (Object.keys(newMedia).includes(yearAndMonth)) {
                            newMedia[yearAndMonth] = [...newMedia[yearAndMonth], item];
                        } else {
                            newMedia[yearAndMonth] = [item];
                        }
                    });
                    this.media = newMedia;
                    this.mediaTotalPages = res?.data?.data?.totalPages ?? 1;
                    this.isMediaLoading = false;
                })
        },
        nextMediaPage() {
            this.mediaPage += 1;
            this.getMedia(this.channel.consultId);
        },
        toggleReadyForDoctors(isReady) {
            this.axios.put(`/channels/${this.channel.id}/ready`, {
                isReady,
            });
        },
        bindPusherEvents(privateChannel, privateIndividualChannel) {
            // Private Channel
            privateChannel.bind('MESSAGE_RECEIVED', (e) => {
                const { consultId, channelId, id, timestamp, clientMessageId, isClinical, assignedTo, isReady } = e;
                const isChatroomOpened = this.channel !== null && this.channel.consultId === consultId;
                const updatedChannelIndex = this.channels.findIndex(channel => channel.consultId === consultId);
                
                const noActiveFilter = this.filterParamsObject === null || Object.values(this.filterParamsObject).every(value => typeof value === 'undefined');
                const noActiveFilterAndSearch = this.searchMessage === '' && noActiveFilter;

                const shouldRefreshUnlistedChannelMessage =
                    (this.TABS[this.activeTab].shouldRefreshOnReceiveUnlistedChannelMessageThatIsAssignedToMe && assignedTo === this.adminId) ||
                    (this.TABS[this.activeTab].shouldRefreshOnReceiveUnlistedChannelMessageThatIsAssigned && assignedTo !== null) ||
                    (this.TABS[this.activeTab].shouldRefreshOnReceiveUnlistedChannelMessageThatIsUnassigned && assignedTo === null) ||
                    (this.TABS[this.activeTab].shouldRefreshOnReceiveUnlistedChannelMessageThatIsNotReadyForDoctors && isReady === false);
                if (updatedChannelIndex === -1 && noActiveFilterAndSearch && this.TABS[this.activeTab].shouldPushNewChannelMessageToTop) {
                    // the channel is not in the loaded channel list => do fetch single channel & append to the top of the list
                    this.getChannelDetail(channelId, (data) => {
                        const isFilterMatch = this.TABS[this.activeTab].shouldPushNewChannelMessageToTop;
                        if (isFilterMatch) {
                            const unreadAdminCount = isChatroomOpened ? 0 : 1;
                            if (this.channels.length % this.usedLimit === 0) {
                                this.channels = [
                                    { ...data, unreadAdminCount },
                                    ...this.channels.slice(0, this.channels.length - 1),
                                ];
                            } else {
                                this.channels = [
                                    { ...data, unreadAdminCount },
                                    ...this.channels,
                                ];
                            }
                        }
                    });
                } else if (updatedChannelIndex === -1 && shouldRefreshUnlistedChannelMessage) {
                    // the channel is not in the loaded channel list => do refetch channel list
                    this.getChannels({
                        page: 1,
                        limit: this.page * this.usedLimit,
                        withLoading: false,
                    });
                    this.getTabCount();
                } else if (updatedChannelIndex !== -1) {
                    // the channel is in the loaded channel list
                    if (isClinical && this.TABS[this.activeTab].shouldRemoveChannelOnClinicalSendMessage) {
                        // remove from channel list
                        this.channels = this.channels.filter(channel => channel.consultId !== consultId);
                        this.getTabCount();
                    } else if (!isClinical && this.TABS[this.activeTab].shouldRemoveChannelOnCustomerSendMessage) {
                        // remove from channel list
                        this.channels = this.channels.filter(channel => channel.consultId !== consultId);
                        this.getTabCount();
                    } else {
                        // get up-to-date channel index to prevent race condition
                        const updatedChannelIndex = this.channels.findIndex(channel => channel.consultId === consultId);

                        // update last message
                        this.channels[updatedChannelIndex].lastMessage = e;
                        this.channels[updatedChannelIndex].updatedAt = timestamp;
                        if (!isChatroomOpened) {
                            this.channels[updatedChannelIndex].unreadAdminCount += 1;
                        }
                        if (this.TABS[this.activeTab].shouldPushNewChannelMessageToTop) {
                            // get up-to-date channel index to prevent race condition
                            const updatedChannelIndex = this.channels.findIndex(channel => channel.consultId === consultId);

                            this.channels = [
                                this.channels[updatedChannelIndex],
                                ...this.channels.slice(0, updatedChannelIndex),
                                ...this.channels.slice(updatedChannelIndex + 1),
                            ];
                        }
                    }   
                }

                if (isChatroomOpened) {
                    const updatedMessageIndex = typeof clientMessageId === 'undefined'
                        ? -1
                        : this.messages.findIndex(msg => msg.clientMessageId === clientMessageId);
                    if (updatedMessageIndex === -1) {
                        this.messages.push(e);
                        this.channel.lastMessage = e;
                        this.channel.updatedAt = timestamp;
    
                        setTimeout(() => {
                            const chatWindow = this.$el.querySelector('#chat-window');
                            chatWindow.scrollTop = chatWindow.scrollHeight;
                            const chatList = this.$el.querySelector('#chat-list');
                            chatList.scrollTop = -chatList.scrollHeight;
                        }, 100);
                        
                        this.resetReadCount(this.channel.id);
                    } else {
                        // update message item status from 'sent' to 'delivered'
                        // set the message id to be able to do message action, e.g. delete message
                        this.messages[updatedMessageIndex].id = id;
                        this.messages[updatedMessageIndex].timestamp = timestamp;
                    }
                }
                this.$forceUpdate();
            });

            privateChannel.bind('CHANNEL_ACTIVATED', (e) => {
                const { id } = e;
                this.getChannelDetail(id, (data) => {
                    if (data.consult) {
                        const expiredAt = data.consult.expiredAt;

                        if (this.channel !== null && this.channel.id === id) {
                            this.channel.status = this.CHANNEL_STATUS.ACTIVE;
                            this.channel.consult.expiredAt = expiredAt;
                            if (this.consult) {
                                this.consult.expiredAt = expiredAt;
                            }
                        }
                        
                        this.channels = this.channels.map(channel => channel.id === id
                            ? {
                                ...channel,
                                status: this.CHANNEL_STATUS.ACTIVE,
                                consult: {
                                    ...channel.consult,
                                    expiredAt,
                                }
                            }
                            : channel
                        );
                        
                        this.$forceUpdate();
                    }
                });
            });

            privateChannel.bind('AUTO_ASSIGNMENT_TOGGLE', (e) => {
                const { value } = e;
                this.$store.dispatch('updateConfig', {
                    key: 'auto_assignment',
                    value: {
                        isActive: value,
                    },
                });
                this.assigneeFilterParamsObject = null;
                this.page = 1;
                this.getChannels();
                this.getTabCount();
            });

            privateChannel.bind('READY_FOR_DOCTORS_TOGGLED', (e) => {
                const { channelId, isReady } = e;
                const isChatroomOpened = this.channel !== null && this.channel.id === channelId;
                if (isChatroomOpened) {
                    this.channel.isReady = isReady;
                }

                if (this.TABS[this.activeTab].shouldRefreshOnReadyForDoctorsToggled) {
                    // refresh channel list
                    this.getChannels({
                        page: 1,
                        limit: this.page * this.usedLimit,
                        withLoading: false,
                    });
                    this.getTabCount();
                } else if (this.TABS[this.activeTab].shouldRemoveChannelOnReadyForDoctorsToggledOff && isReady === false) {
                    // remove channel
                    this.channels = this.channels.filter(channel => channel.id !== channelId);
                    this.getTabCount();
                } else {
                    // update isReady value of channel in the list
                    this.channels = this.channels.map(channel => channel.id === channelId
                        ? { ...channel, isReady }
                        : channel
                    );
                }
            });

            // Private Individual Channel
            privateIndividualChannel.bind('CHANNEL_ASSIGNMENT', () => {
                if (this.TABS[this.activeTab].shouldRefreshOnChannelAssignment) {
                    this.getChannels({
                        page: 1,
                        limit: this.page * this.usedLimit,
                        withLoading: false,
                    });
                }
                this.getTabCount();
            });

            privateIndividualChannel.bind('CHANNEL_UNASSIGNMENT', () => {
                if (this.TABS[this.activeTab].shouldRefreshOnChannelAssignment) {
                    this.getChannels({
                        page: 1,
                        limit: this.page * this.usedLimit,
                        withLoading: false,
                    });
                }
                this.getTabCount();
            });
        }
    },
    directives: {
        focus: {
            inserted(el) {
                el.focus()
            },
        },
    },
    mounted() {
        this.$emit('show-mobile-navbar');
        this.isNavbarShow = true;
        this.handleChangeTab(null, this.defaultChannelTab);

        if (window.innerWidth >= 768) {
            this.editorOption.modules.keyboard = {
                bindings: {
                    enter: {
                        key: 13,
                        handler: () => {
                            this.sendMessage();
                        }
                    }
                }
            }
        }

        if (this.adminId !== null && this.hasEditPermission) {
			const privateChannel = this.$pusher.subscribe('private-clinical');
			const privateIndividualChannel = this.$pusher.subscribe(`private-clinical-${this.adminId}`);
			this.bindPusherEvents(privateChannel, privateIndividualChannel);
		}

        if (this.$route.query.chat) {
            this.getChannelDetailByConsultId(this.$route.query.chat, (data) => {
                // check whether the response is still valid or not to be applied
                if (this.channel === null && this.$route.query.chat === data.consultId.toString()) {
                    this.subscribe(data);
                }
            });
        }

    },
    watch: {
        searchParam: function() {
            if (this.searchMessage !== '') {
                this.page = 1;
                this.getChannels();
            }
        },
        adminId: function(adminId) {
			// Handle not subscribe yet on mounted lifecycle
			if (adminId !== null && this.hasEditPermission) {
				const privateChannel = this.$pusher.subscribe('private-clinical');
				const privateIndividualChannel = this.$pusher.subscribe(`private-clinical-${adminId}`);
				this.bindPusherEvents(privateChannel, privateIndividualChannel);
			}
		},
        'userSubRole.slug': function() {
            // reset all filters & search
            this.searchParam = { value: 'consultNumber', label: 'Konsul ID' };
            this.searchMessage = '';
            this.filterParamsObject = null;
            this.assigneeFilterParamsObject = null;
            
            // close all restricted popup/sidebar
            this.$refs.prescription?.hideApproveConfirmationModal();
            this.closePrescriptionTemplate();
            this.closePrescription();

            this.handleChangeTab(null, this.defaultChannelTab);
        },
        pusherConnectionState: function(state) {
            if (state === 'connected') {
                setTimeout(() => {
                    this.page = 1;
                    this.getChannels();
                    this.getTabCount();
                }, 1000);
            }
        },
    }
}
</script>
<style scoped>
.wrapper {
    height: calc(100vh - 69px);
}

.wrapper__hidden-navbar {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
}

.chatroom-window {
    flex: 1;
}

.chatroom-sidebar {
    position: fixed;
    inset: 0;
    z-index: 1000;
}

@media (min-width: 768px) {
    .wrapper__hidden-navbar {
        height: calc(100vh - 69px);
    }

    .chatroom-window {
        flex: 2;
    }
    
    .chatroom-sidebar {
        position: relative;
        flex: 1;
        max-width: 33%;
    }
}
.emoji-picker {
    position: absolute;
    z-index: 1;
    font-family: Montserrat;
    border: 1px solid #ccc;
    width: 17rem;
    height: 20rem;
    overflow: scroll;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    background: #fff;
    box-shadow: 1px 1px 8px #c7dbe6;
}
.emoji-picker__search {
    display: flex;
}
.emoji-picker__search > input {
    flex: 1;
    border-radius: 10rem;
    border: 1px solid #ccc;
    padding: 0.5rem 1rem;
    outline: none;
}
.emoji-picker h5 {
    margin-bottom: 0;
    color: #b1b1b1;
    text-transform: uppercase;
    font-size: 0.8rem;
    cursor: default;
}
.emoji-picker .emojis {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.emoji-picker .emojis:after {
    content: "";
    flex: auto;
}
.emoji-picker .emojis span {
    padding: 0.2rem;
    cursor: pointer;
    border-radius: 5px;
}
.emoji-picker .emojis span:hover {
    background: #ececec;
    cursor: pointer;
}
.chat.active {
    background: #F2F2F6;
    border-bottom: 0px;
}
.separator {
    border-top: 1px solid #BCC0D1;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.k-date-form .mx-icon-calendar {
    display: none;
}
.k-date-form .position-relative .form-control {
    padding-right: 14px;
}
.btn-review-to{
    background-color:#ddd50b !important;
    color: #fff;
}

.mt-0\.25 {
  margin-top: .25rem;
}

.gap-1 {
    gap: 0.5rem;
}
.gap-2 {
    gap: 1rem;
}

.text-disabled {
    color: #BCC0D1;
}

.cField >>> p {
  font-size: 12px;
}

.filter-indicator {
  position: absolute;
  top: -4px;
  right: -4px;
  width: 8px;
  height: 8px;
  background-color: #EA5455;
  border-radius: 50%;
}

.flex-1 {
    flex: 1;
}
</style>