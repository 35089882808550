export default {
	account: null,
	permissions: [],
	// Pusher
	pusherConnectionState: 'connected',

	// Global config
	config: {
		auto_assignment: null,
	},
}