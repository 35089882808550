<template>
    <div class="section mw-100 p-2">
        <div class="card">
            <div class="card-body p-0">
                <div class="d-flex flex-row justify-content-between">
                    <div style="flex: 1;">
                        <div class="row pl-2 pt-2 pr-2 pb-1" style="gap: 1rem">
                            <p class="align-self-center f-16 font-600 text-black ml-2 mb-0 mr-1">Status</p>
                            <button type="button" class="btn btn-sm" :class="activeButton === '' ? 'btn-primary' : 'btn-outline-secondary'"  @click="handleChangeStatus('')">Semua</button>
                            <button type="button" class="btn btn-sm" :class="activeButton === 0 ? 'btn-primary' : 'btn-outline-secondary'"  @click="handleChangeStatus(0)">Pesanan Baru</button>
                            <button type="button" class="btn btn-sm" :class="activeButton == 1 ? 'btn-primary' : 'btn-outline-secondary'"  @click="handleChangeStatus(1)">Pesanan Diterima</button>
                            <button type="button" class="btn btn-sm" :class="activeButton == 2 ? 'btn-primary' : 'btn-outline-secondary'"  @click="handleChangeStatus(2)">Pesanan Siap Kirim</button>
                            <button type="button" class="btn btn-sm" :class="activeButton == 3 ? 'btn-primary' : 'btn-outline-secondary'"  @click="handleChangeStatus(3)">Sedang Dikirim</button>
                            <button type="button" class="btn btn-sm" :class="activeButton == 4 ? 'btn-primary' : 'btn-outline-secondary'"  @click="handleChangeStatus(4)">Selesai</button>
                            <p class="align-self-center f-16 font-600 text-blue ml-1 mb-0 pointer" 
                                @click="resetFilter">
                                Reset Filter
                            </p>
                        </div>
                        <div class="row pl-2 pr-2 pb-1" style="gap: 1rem">
                            <div class="col-12 col-md-5 d-flex align-items-center">
                                <p class="flex-shrink-0 mb-0 f-14 font-600">Parameter pencarian</p>
                                <multiselect
                                    v-model="searchParam"
                                    :options="[
                                        { value: 'consultId', label: 'Konsul ID' },
                                        { value: 'orderNumber', label: 'Transaksi ID' },
                                        { value: 'userName', label: 'User name' },
                                        { value: 'receiverName', label: 'Receiver name' },
                                        { value: 'userPhone', label: 'User phone number' },
                                        { value: 'receiverPhone', label: 'Receiver phone number' },
                                        { value: 'email', label: 'Email' },
                                        { value: 'shipperAwb', label: 'AWB Shipper' },
                                        { value: 'operatorAwb', label: 'AWB Operator' },
                                    ]"
                                    label="label"
                                    track-by="value"
                                    :show-labels="false"
                                    :multiple="false"
                                    :searchable="false"
                                    :allow-empty="false"
                                    class="ml-1"
                                    @input="handleChangeSearchParam()"
                                >
                                </multiselect>
                            </div>
                            <div class="input-group col-12 col-md-4">
                                <span class="input-group-prepend">
                                    <div class="input-group-text border-right-0 bg-transparent text-blue">
                                        <i class="fa fa-search"></i>
                                    </div>
                                </span>
                                <input class="form-control border-left-0" v-model="search" @input="handleSearch" type="search" placeholder="Cari transaksi...">
                            </div>
                        </div>
                        <div class="row pl-2 pr-2 pb-1">
                            <div class="col-12 col-md-3 d-flex align-items-center">
                                <p class="align-self-center mb-0 f-14 font-600">Tanggal</p>
                                <div class="input-group ml-1">
                                    <div class="position-relative">
                                        <date-picker
                                            v-model="date"
                                            format="DD/MM/YYYY"
                                            type="date"
                                            input-class="form-control"
                                            value-type="YYYY-MM-DD"
                                            placeholder="Pilih tanggal"
                                            style="width: 100%;"
                                            @input="getList()"
                                        >
                                        </date-picker>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-3 d-flex align-items-center">
                                <p class="align-self-center mb-0 f-14 font-600">Tipe</p>
                                <multiselect
                                    v-model="type"
                                    placeholder="Tipe Transaksi"
                                    :options="[
                                        { value: 2, label: getOrderTypeLabel(2) },
                                        { value: 3, label: getOrderTypeLabel(3) },
                                        { value: 4, label: getOrderTypeLabel(4) },
                                    ]"
                                    label="label"
                                    track-by="value"
                                    select-label=""
                                    deselect-label="Reset"
                                    :multiple="false"
                                    :searchable="false"
                                    class="ml-1"
                                    @input="getList()"
                                >
                                </multiselect>
                            </div>
                            <div class="col-12 col-md-3 d-flex align-items-center">
                                <p class="align-self-center mb-0 f-14 font-600">Kurir</p>
                                <multiselect
                                    v-model="shippingRate"
                                    placeholder="Operator dan tipe"
                                    :options="shippingRateOptions"
                                    label="name"
                                    track-by="id"
                                    select-label=""
                                    deselect-label="Reset"
                                    :multiple="false"
                                    :searchable="false"
                                    class="ml-1"
                                    @input="getList()"
                                >
                                </multiselect>
                            </div>
                            <div v-if="activeButton === 3" class="col-12 col-md-3 d-flex align-items-center">
                                <p class="align-self-center mb-0 f-14 font-600">ETA Delivery</p>
                                <multiselect
                                    v-model="etaDeliveryGroup"
                                    placeholder="ETA Delivery"
                                    :options="[
                                        { value: 1, label: 'Normal' },
                                        { value: 2, label: 'Due today' },
                                        { value: 3, label: 'Overdue' },
                                    ]"
                                    label="label"
                                    track-by="value"
                                    select-label=""
                                    deselect-label="Reset"
                                    :multiple="false"
                                    :searchable="false"
                                    class="ml-1"
                                    @input="getList()"
                                >
                                </multiselect>
                            </div>
                        </div>
                    </div>
                    <div v-if="activeButton === 2" class="p-2 d-flex flex-column align-items-stretch flex-shrink-0" style="gap: .5rem">
                        <button
                            type="button"
                            class="btn btn-sm btn-info"
                            @click="downloadInvoice"
                            :disabled="isDownloadInvoiceLoading || isDownloadLabelLoading"
                        >
                            {{ isDownloadInvoiceLoading ? 'Downloading...' : 'Download Invoice' }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-sm btn-info"
                            @click="downloadLabel"
                            :disabled="isDownloadInvoiceLoading || isDownloadLabelLoading"
                        >
                            {{ isDownloadLabelLoading ? 'Downloading...' : 'Download Label' }}
                        </button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table mb-0">
                        <thead>
                            <tr>
                                <th class="font-600 text-black">Transaksi ID</th>
                                <th class="font-600 text-black">Tanggal Bayar</th>
                                <th class="font-600 text-black">Status</th>
                                <th class="font-600 text-black">Tipe</th>
                                <th class="font-600 text-black">Alamat</th>
                                <th class="font-600 text-black">Kurir</th>
                                <th class="font-600 text-black">Pesanan</th>
                                <th class="font-600 text-black">Info Pengiriman</th>
                                <th v-if="hasEditPermission" class="font-600 text-black" style="width: 10px;">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(data, index) in table.dataTable.lists.rows" :key="index">
                                <td>
                                    <div class="d-flex flex-row align-items-center justify-content-center" style="gap: .5rem;">
                                        <div
                                            v-if="data.statusDelivery === 3 && data.etaDeliveryGroup !== 1"
                                            class="eta-group"
                                            :class="{
                                                'bg-warning': data.etaDeliveryGroup === 2,
                                                'bg-danger': data.etaDeliveryGroup === 3,
                                            }"
                                        />
                                        <router-link :to="`/apotek-dan-pengiriman/detail/${data.id}`" style="text-decoration: underline">
                                            {{ data.orderNumber }}
                                        </router-link>
                                    </div>
                                </td>
                                <td style="white-space: pre;">
                                    <span v-if="data.paymentSuccessTime">{{ data.paymentSuccessTime | moment('LL[\n]HH:mm') }}</span>
                                    <span v-else>-</span>
                                </td>
                                <td><div :class="'btn btn-sm ' + statusColor(data.statusDeliveryDetail.code)">{{ data.statusDeliveryDetail.name }}</div></td>
                                <td style="white-space: pre;">{{ getOrderTypeLabel(data.type) }}</td>
                                <td>
                                    <p v-if="data.order_shipping_address">
                                        <b>{{ data.user.isVip ? `👑 ${data.order_shipping_address.name}` : data.order_shipping_address.name }}</b><br>
                                        <span>{{ data.order_shipping_address.city }}</span>
                                    </p>
                                    <span v-else>-</span>
                                </td>
                                <td>
                                    <b v-if="data.order_shipping_address && data.order_shipping_address.shipperReference && data.order_shipping_address.shipperReference.logistic">
                                        {{ data.order_shipping_address.shipperReference.logistic.name }}
                                    </b>
                                    <b v-else>-</b>
                                    <span v-if="data.order_shipping_address && data.order_shipping_address.shipperReference && data.order_shipping_address.shipperReference.rate">
                                        {{ `(${data.order_shipping_address.shipperReference.rate.name})` }}
                                    </span>
                                    <p v-if="data.shipperId" class="f-12 mb-0">
                                        Resi Shipper: {{ data.shipperId }}
                                    </p>
                                    <p v-if="data.awb" class="f-12 mb-0">
                                        Resi Operator: {{ data.awb }}
                                    </p>
                                </td>
                                <td>
                                    <ul v-if="data.productReference && data.productReference.length > 0" class="pl-1">
                                        <li v-for="(produk, index_produk) in data.productReference" :key="index_produk">
                                            {{ produk.name ? produk.name : produk }}
                                        </li>
                                    </ul>
                                    <span v-else>-</span>
                                </td>
                                <td>
                                    <template v-if="data.lastShippingStatus && data.lastShippingStatusDate">
                                        <p class="mb-0">{{data.lastShippingStatus}}</p>
                                        <p class="f-12 mb-0">{{data.lastShippingStatusDate | moment('dddd, LL HH:mm')}}</p>
                                    </template>
                                    <span v-else>-</span>
                                </td>
                                <td v-if="hasEditPermission">
                                    <button type="button" class="btn btn-sm btn-primary rounded" v-if="data.statusDelivery == 0" @click="updateStatus(data.id, 1)">
                                        Terima
                                    </button>
                                    <button type="button" class="btn btn-sm btn-success rounded" v-if="data.statusDelivery == 1" @click="updateStatus(data.id, 2)">
                                        Pesanan Siap Kirim
                                    </button>
                                    <router-link :to="'/apotek-dan-pengiriman/detail/' + data.id">
                                        <button type="button" class="btn btn-sm btn-success rounded" v-if="data.statusDelivery == 2">
                                            Kirim Barang
                                        </button>
                                    </router-link>
                                    <router-link :to="'/apotek-dan-pengiriman/detail/' + data.id">
                                        <button type="button" class="btn btn-sm btn-outline-primary rounded" v-if="data.statusDelivery > 2">
                                            Cek Transaksi
                                        </button>
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col text-center mt-1">
                    <p class="f-14" v-if="table.dataTable.lists.count == 0 || !table.dataTable.lists.rows">There is no data</p>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body pl-3">
                <PaginateList :data="table.dataTable.lists" v-if="table.dataTable.lists.total != 0"/>
            </div>
        </div>
    </div>
</template>

<script>
import PaginateList from '@/components/PaginateList'
// import Table from '@/components/Table.vue'
import { debounce } from "debounce";
import { getOrderTypeLabel } from '@/lib/order';

export default {
	components: {
        // Table,
        PaginateList
	},
	data() {
		return {
			table: {
                dataTable: {
                    lists: [],
                }
			},
            searchParam: { value: 'orderNumber', label: 'Transaksi ID' },
            search: '',
            limit: 10,
            isLoading: false,
            activeButton: 0,
            date: null,
            type: null,
            shippingRate: null,
            shippingRateOptions: [],
            etaDeliveryGroup: null,
            isDownloadInvoiceLoading: false,
            isDownloadLabelLoading: false,
		}
	},
    mounted() {
        let _ = this
        _.getShippingRateOptions();
        _.getList()
        _.$moment.locale('id')
        _.getList = debounce(_.getList, 500)

        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
        _.$root.$on("changeLimit", (data) => {
            _.limit = data
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
        this.$root.$off("changeLimit");
    },
    computed: {
        sanitizedSearchMessage() {
            if (this.searchParam.value === 'email') {
                return this.search.replace(/ /g, '');
            }
            if (this.searchParam.value === 'userPhone' || this.searchParam.value === 'receiverPhone') {
                const trimmedMessage = this.search.replace(/ /g, '');
                if (trimmedMessage.startsWith('+62')) {
                    return `0${trimmedMessage.slice(3)}`;
                }
                return trimmedMessage;
            }
            return this.search;
        },
        permissions() {
            return this.$store.getters.permissions;
        },
        hasEditPermission() {
            return this.permissions.includes('pharmacy:edit');
        },
    },
    methods: {
        getOrderTypeLabel,
        getList(page) {
            this.axios.get('/orders/shippings', {
                params: {
                    sortBy: 'createdAt.DESC',
                    page: page || 1,
                    limit: this.limit,
                    statusDelivery: this.activeButton !== '' ? this.activeButton : undefined,
                    [this.searchParam.value]: this.sanitizedSearchMessage || undefined,
                    paymentSuccessTime: this.date !== null ? `betweenDate:${this.date},${this.date}` : undefined,
                    type: this.type && this.type.value ? this.type.value : undefined,
                    shippingRateId: this.shippingRate?.id ?? undefined,
                    etaDeliveryGroup: this.etaDeliveryGroup?.value ?? undefined,
                }
            })
                .then(resp => {
                    this.table.dataTable.lists = resp.data.data;
                })
                .catch(err => {
                    console.log(err.response);
                });
        },
        getShippingRateOptions() {
            this.axios.get('/v2/misc/couriers', {
                params: {
                    page: 1
                }
            })
                .then(res => {
                    this.shippingRateOptions = res.data.data.rows;
                })
                .catch(err => {
                    console.log(err.response);
                });
        },
        handleChangeStatus(status) {
            this.activeButton = status;
            this.etaDeliveryGroup = null;
            this.getList();
        },
        handleChangeSearchParam() {
            if (this.search !== '') {
                this.getList();
            }
        },
        handleSearch: debounce(function() {
            this.getList();
        }, 1000),
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Are you sure want to delete Flow?',
                text: x.fullName,
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'No',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Yes',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/products/' + x.id)
                            .then(resp => {
                                if (resp.status) {
                                    _.getList()
                                    _.$swal.fire({
                                        title: 'Deleted!',
                                        text: resp.message,
                                        icon: 'success',
                                        confirmButtonColor: '#0036A0',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
        statusColor(x) {
            let color = ''
            switch (x) {
                case 0:
                    color = 'btn-success'
                    break;
                case 1:
                    color = 'btn-warning'
                    break;
                case 2:
                    color = 'btn-primary'
                    break;
                case 3:
                    color = 'btn-info'
                    break;
                case 4:
                    color = 'btn-outline-primary'
                    break;
                case 5:
                    color = 'btn-danger'
                    break;
                default:
                    break;
            }
            return color;
        },
        updateStatus( id, statusCode ) {
            let _ = this
            let data = {
                status: statusCode
            }

            _.axios.put('orders/' + id + '/shipping', data)
                .then(() => {
                    _.getList()
                })
                .catch(error => {
                    _.$swal.fire({
                        title: 'Gagal',
                        text: error.response.data.message,
                        icon: 'error',
                        confirmButtonColor: '#0036A0',
                        confirmButtonText: 'OK'
                    });
                });
        },
        downloadInvoice(){
            this.isDownloadInvoiceLoading = true;
            this.axios.post('/orders/invoice-bulk', { statusDelivery: 2 }, { responseType: 'arraybuffer' })
                .then(resp => {
                    const blob = new Blob([resp.data], { type: 'application/pdf' });
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `download_invoice_${this.$moment().format('DDMMYYYY')}.pdf`;
                    link.click();
                    this.isDownloadInvoiceLoading = false;
                });
        },
        downloadLabel(){
            this.isDownloadLabelLoading = true;
            this.axios.post('/orders/print-label-bulk', {}, { responseType: 'arraybuffer' })
                .then(resp => {
                    const blob = new Blob([resp.data], { type: 'application/zip' });
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `download_resi_${this.$moment().format('DDMMYYYY')}.zip`;
                    link.click();
                    this.isDownloadLabelLoading = false;
                });
        },
        resetFilter() {
            this.activeButton = 0;
            this.searchParam = { value: 'orderNumber', label: 'Transaksi ID' };
            this.search = '';
            this.date = null;
            this.type = null;
            this.shippingRate = null;
            this.etaDeliveryGroup = null;
            this.getList();
        }
    },
}
</script>

<style scoped>
.eta-group {
    content: '';
    height: 12px;
    width: 12px;
    border-radius: 50%;
}
</style>