<template>
  <div class="bg-white h-100 overflow-auto">
    <!-- Record Detail -->
    <div v-if="isRecordDetailOpened" class="bg-white">
      <div
        class="
          p-1
          d-flex
          flex-row
          align-items-center
          justify-content-between
          gap-0.5
          border-bottom
          bg-white
        "
        style="position: sticky; top: 0px; z-index: 10"
      >
        <div class="d-flex flex-row align-items-center gap-0.5">
          <img
            src="/assets/img/icon/arrow-left-icon.svg"
            class="cursor-pointer"
            width="24"
            @click="closeRecord"
            alt=""
          />
          <span class="f-16 text-dark font-500">Rekam Medis</span>
        </div>
        <button
          v-if="isClockedIn && recordDetail && recordDetail.diagnosisDetail"
          class="btn btn-sm btn-primary"
          @click="copyPrescription"
        >
          Make a copy
        </button>
      </div>
      <div class="p-1">
        <p v-if="isRecordDetailLoading" class="f-12 mb-0 text-center">
          Loading...
        </p>
        <div v-else-if="recordDetail">
          <p class="text-dark f-14 font-500 mb-0.5">
            {{ recordDetail.createdAt | moment('D MMMM Y') }}
          </p>
          <p class="f-12 mb-0">
            <b class="text-blue">{{ recordDetail.consult.consultId }}</b>
            <span class="ml-0.5">{{ recordDetail.doctor.fullName }}</span>
          </p>
          <p class="f-12 mb-0">{{ recordDetail.consult.flow.title }}</p>
          <div class="d-flex flex-row gap-1 mt-1">
            <span class="f-14 font-500 mt-1">P</span>
            <p class="f-12 mb-0 border p-1 rounded" style="flex: 1">
              {{ recordDetail.p }}
            </p>
          </div>
          <div class="d-flex flex-row gap-1 mt-1">
            <span class="f-14 font-500 mt-1">D</span>
            <div class="border p-1 rounded" style="flex: 1">
              <p v-if="recordDetail.diagnosisDetail" class="f-12 mb-0">
                {{ recordDetail.diagnosisDetail.diagnosis }}
              </p>
              <p v-else class="f-12 mb-0">-</p>
              <p class="f-12 mb-0 mt-1">{{ recordDetail.dNotes }}</p>
            </div>
          </div>
          <div class="d-flex flex-row gap-1 mt-1">
            <span class="f-14 font-500 mt-1">C</span>
            <div
              class="mb-0 border p-1 rounded cField"
              style="flex: 1"
              v-html="recordDetail.c"
            ></div>
          </div>
          <div class="d-flex flex-row gap-1 mt-1">
            <span class="f-14 font-500 mt-1">K</span>
            <p
              v-if="recordDetail.k"
              class="f-12 mb-0 border p-1 rounded"
              style="flex: 1"
            >
              {{ recordDetail.k | moment('DD-MM-YYYY') }}
            </p>
            <p v-else class="f-12 mb-0 border p-1 rounded" style="flex: 1">-</p>
          </div>
          <div
            class="d-flex flex-row align-items-center gap-1 mt-1"
            :class="{
              'justify-content-between':
                recordDetail.recipes.length > 0 && !recordDetail.isActive,
              'justify-content-end':
                recordDetail.recipes.length === 0 && !recordDetail.isActive,
            }"
          >
            <div
              v-if="recordDetail.recipes.length > 0"
              class="d-flex flex-row align-items-center gap-1"
            >
              <span class="btn btn-sm rounded btn-primary flex-shrink-0"
                >Rx {{ recordDetail.prescriptionIter }}</span
              >
              <span class="f-14">Iter left: {{ recordDetail.iter }}</span>
            </div>
            <span
              v-if="!recordDetail.isActive"
              class="btn btn-sm rounded btn-danger"
              >INACTIVE</span
            >
          </div>
          <div class="mt-1 d-flex flex-column gap-0.5">
            <div
              v-for="(item, idx) in recordDetail.recipes"
              :key="idx"
              class="border border-primary p-1 rounded d-flex flex-column gap-1"
            >
              <div
                v-for="(product, productIdx) in item.products"
                :key="productIdx"
                class="d-flex flex-column gap-0.5"
              >
                <div v-if="productIdx !== 0" class="border-top mb-0.5"></div>
                <div class="d-flex flex-row align-items-start gap-0.5 f-12">
                  <i
                    v-if="item.recommended"
                    class="f-16 fa fa-check-circle text-success"
                  ></i>
                  <span
                    :class="{
                      'text-danger': product.type === 'Rx Red',
                      'text-blue': product.type === 'Rx Blue',
                    }"
                    >{{ product.type }}</span
                  >
                  <span class="font-500 text-dark">{{ product.title }}</span>
                  <span
                    v-if="product.deletedAt || product.isActive === false"
                    class="discontinued-label bg-danger"
                    >Discontinued</span
                  >
                  <span
                    v-if="product.isPrescribed && item.products.length > 1"
                    class="prescribed-label bg-dark"
                    >Prescribed</span
                  >
                </div>
                <div class="d-flex flex-row align-items-start gap-0.5 f-12">
                  <span class="flex-shrink-0">Qty: {{ item.usageAmount }}</span>
                  <span>Usage: {{ item.usageInterval }}</span>
                </div>
                <div
                  v-if="product.orders && product.orders.length > 0"
                  class="d-flex flex-row align-items-center flex-wrap gap-0.5"
                >
                  <span
                    v-for="(order, idx) in product.orders"
                    :key="idx"
                    class="btn btn-success btn-sm"
                  >
                    {{ order.date | moment('DD MMM YY') }}
                    <span class="badge bg-white text-primary">{{
                      order.qty
                    }}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="recordDetail.orderedProductAddOns.length > 0"
            class="
              mt-0.5
              border border-primary
              p-1
              rounded
              d-flex
              flex-column
              gap-1
            "
          >
            <p class="f-14 font-500 text-dark mb-0">Add-on products</p>
            <div
              v-for="(item, idx) in recordDetail.orderedProductAddOns"
              :key="idx"
              class="d-flex flex-column gap-1"
            >
              <hr v-if="idx !== 0" class="w-100 my-0" />
              <div
                v-for="(product, productIdx) in item.products"
                :key="productIdx"
                class="d-flex flex-column gap-0.5"
              >
                <div class="d-flex flex-row align-items-start gap-0.5 f-12">
                  <span
                    :class="{
                      'text-danger': product.type === 'Rx Red',
                      'text-blue': product.type === 'Rx Blue',
                    }"
                    >{{ product.type }}</span
                  >
                  <span class="font-500 text-dark">{{ product.title }}</span>
                  <span
                    v-if="product.deletedAt || product.isActive === false"
                    class="discontinued-label bg-danger"
                    >Discontinued</span
                  >
                </div>
                <div class="d-flex flex-row align-items-start gap-0.5 f-12">
                  <span class="flex-shrink-0">Qty: {{ product.qty }}</span>
                </div>
              </div>
              <span class="btn btn-success btn-sm align-self-start">
                {{ item.date | moment('DD MMM YY') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Record List -->
    <div v-else class="h-100 overflow-auto">
      <div
        class="p-1 d-flex flex-column gap-0.5 border-bottom bg-white"
        style="position: sticky; top: 0px; z-index: 10"
      >
        <div
          class="
            d-flex
            flex-row
            justify-content-between
            align-items-center
            gap-0.5
          "
        >
          <span class="f-16 text-dark font-500">Rekam Medis</span>
          <span @click="close" class="cursor-pointer f-18">
            <i class="fa fa-close"></i>
          </span>
        </div>
        <div class="d-flex align-items-center gap-1 justify-content-between">
          <div class="d-flex flex-row align-items-center gap-0.5">
            <switches
              @input="toggleShowThisConsultOnly"
              :emit-on-mount="false"
              v-model="showThisConsultOnly"
              :color="showThisConsultOnly ? 'blue' : 'default'"
            ></switches>
            <p class="mb-0 f-14">Show EMR for this consult only</p>
          </div>
          <button
            v-if="isClockedIn"
            class="btn btn-sm btn-primary flex-shrink-0"
            @click="add"
          >
            Add +
          </button>
        </div>
      </div>
      <div class="p-1">
        <!-- User Info -->
        <p v-if="isLoadingConsult" class="f-12 text-dark mb-0 text-center">
          Loading...
        </p>
        <div v-else-if="consult" class="d-flex flex-column gap-0.5">
          <div class="f-14 d-flex flex-row align-item-center gap-1 flex-wrap">
            <div
              class="text-truncate d-flex align-items-center"
              style="gap: 4px"
            >
              <span v-if="consult.user.isVip">👑</span>
              <img
                v-if="consult.user.isSubscribed && !consult.user.isVip"
                src="/assets/img/icon/diri-xtra-logo.svg"
                width="20"
                height="20"
                alt=""
              />
              <img
                v-if="consult.user.isLowValueCustomer"
                src="/assets/img/icon/low-value-customer.png"
                width="18"
                height="18"
                alt=""
              />
              <span class="font-500 text-dark text-truncate">{{
                consult.user.fullName
              }}</span>
            </div>
            <span
              >{{ consult.user.gender }},
              {{ getAge(consult.user.dateOfBirth) }}</span
            >
          </div>
          <div class="d-flex flex-row align-items-center" style="gap: 0.5rem">
            <div
              v-for="tag in Object.keys(prescriptionUserTags).filter(
                (tag) => tag !== 'allergic' && tag !== 'importantNotes'
              )"
              :key="tag"
            >
              <VTooltip>
                <img
                  :src="
                    prescriptionUserTags[tag] === true
                      ? USER_TAGS[tag].icon.active
                      : USER_TAGS[tag].icon.inactive
                  "
                  width="20"
                  height="20"
                  :alt="USER_TAGS[tag].label"
                />
                <template #popper>
                  {{ USER_TAGS[tag].label }}
                </template>
              </VTooltip>
            </div>
          </div>
          <div class="d-flex flex-column" style="gap: 0.25rem">
            <label class="f-12">Alergi</label>
            <input
              type="text"
              class="form-control"
              placeholder="Catatan tentang Alergi pasien"
              v-model="prescriptionUserTags.allergic"
              disabled
            />
          </div>
          <div class="d-flex flex-column" style="gap: 0.25rem">
            <label class="f-12">Catatan Pasien</label>
            <input
              type="text"
              class="form-control"
              placeholder="Catatan tentang pasien, contoh: tidak cocok BP25"
              v-model="prescriptionUserTags.importantNotes"
              disabled
            />
          </div>
        </div>

        <!-- Active Medication -->
        <p v-if="isMedicationLoading" class="f-12 text-dark mb-0 text-center">
          Loading...
        </p>
        <div
          v-else-if="!isMedicationLoading"
          class="d-flex flex-column mt-1"
          style="gap: 0.25rem"
        >
          <span class="f-12 text-dark font-500"
            >Pembelian 3 bulan terakhir</span
          >
          <ul
            v-if="medications.length > 0"
            class="d-flex flex-column mb-0 pl-2"
            style="gap: 0.25rem"
          >
            <li v-for="medication in medications" :key="medication.sku">
              <p class="font-500 f-12 mb-0 text-blue">
                {{ medication.totalPurchasedQty }}x [{{ medication.title }}]
              </p>
              <p class="f-12 mb-0">
                <span v-if="medication.signa">{{ medication.signa }} - </span>
                <span class="font-500 text-dark">sejak:</span>
                {{ medication.firstPurchasedAt | moment('DD/MM/YYYY') }}
              </p>
            </li>
          </ul>
          <span v-else class="f-12">-</span>
        </div>

        <hr />

        <!-- Medical Record List -->
        <div v-if="records.length > 0" class="d-flex flex-column gap-0.5">
          <div
            v-for="(record, idx) in records"
            :key="idx"
            class="border p-1 rounded cursor-pointer"
            :class="{ 'border-primary': record.consultId === consultId }"
            @click="openRecord(record.id)"
          >
            <div
              class="
                d-flex
                flex-row
                gap-0.5
                align-items-start
                justify-content-between
              "
            >
              <div>
                <div class="d-flex flex-row gap-0.5 align-items-center mb-0.5">
                  <span
                    v-if="record.isOmnicare"
                    class="btn btn-sm rounded btn-warning flex-shrink-0"
                    >Omnicare</span
                  >
                  <p class="text-dark f-14 font-500 mb-0">
                    {{ record.createdAt | moment('D MMMM Y') }}
                  </p>
                </div>
                <p class="f-12 mb-0">
                  <b class="text-blue">{{ record.consult.consultId }}</b>
                  <span v-if="record.doctor" class="ml-0.5">{{
                    record.doctor.fullName
                  }}</span>
                </p>
                <p class="f-12 mb-0">{{ record.consult.flow.title }}</p>
              </div>
              <div class="d-flex flex-row gap-0.5 flex-shrink-0">
                <span
                  v-if="record.prescriptionCount > 0"
                  class="btn btn-sm rounded btn-primary"
                  >Rx {{ record.prescriptionIter }}</span
                >
                <span
                  v-if="record.numberOfPurchase > 0"
                  class="btn btn-sm rounded btn-success"
                  >Px {{ record.numberOfPurchase }}</span
                >
              </div>
            </div>
            <p class="f-12 mb-0">{{ record.p }}</p>
          </div>
        </div>
        <p v-if="isLoading" class="f-12 mt-1 mb-0 text-center">Loading...</p>
        <p
          v-if="!isLoading && records.length === 0"
          class="f-12 mb-0 text-center"
        >
          Data tidak ditemukan
        </p>
        <p
          v-if="page < totalPages && !isLoading"
          class="font-600 f-12 text-blue mt-1 mb-0 cursor-pointer text-center"
          @click="nextPage"
        >
          Lihat lebih banyak
        </p>
      </div>
    </div>
  </div>
</template>

<script>
const LIMIT = 10;

export default {
  props: {
    userId: Number,
    consultId: Number,
    USER_TAGS: Object,
    isLoadingConsult: Boolean,
    consult: Object,
    prescriptionUserTags: Object,
    isMedicationLoading: Boolean,
    medications: Array,
  },
  data() {
    return {
      // record list
      isLoading: false,
      records: [],
      page: 1,
      totalPages: 1,
      showThisConsultOnly: false,

      // record detail
      isRecordDetailOpened: false,
      isRecordDetailLoading: false,
      recordDetail: null,
    };
  },
  computed: {
    isClockedIn: function () {
      return this.$store.getters.isUserClockedIn;
    },
  },
  mounted() {
    this.getRecords();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    add() {
      this.close();
      this.$emit('add');
    },
    copyPrescription() {
      this.$emit('copyPrescription', {
        diagnose: {
          id: this.recordDetail.diagnosisDetail.id,
          name: this.recordDetail.diagnosisDetail.diagnosis,
        },
        products: this.recordDetail.recipes.map((recipe) => {
          const prescribedProduct = recipe.products.find(
            (product) => product.isPrescribed
          );
          return {
            id: prescribedProduct.id,
            title: prescribedProduct.title,
            usageAmount: recipe.usageAmount,
            usageInterval: recipe.usageInterval,
            recommended: recipe.recommended,
          };
        }),
      });
      this.close();
    },
    getRecords() {
      this.isLoading = true;
      const fetchedParams = {
        consultId: this.showThisConsultOnly ? this.consultId : undefined,
        page: this.page,
      };
      this.axios
        .get(`/users/${this.userId}/diagnoses`, {
          params: {
            sortBy: 'createdAt.DESC',
            page: fetchedParams.page,
            limit: LIMIT,
            consultId: fetchedParams.consultId,
          },
        })
        .then((res) => {
          // check whether the params is still relevant or not
          if (
            fetchedParams.consultId ===
              (this.showThisConsultOnly ? this.consultId : undefined) &&
            fetchedParams.page === this.page
          ) {
            const { rows, totalPages } = res.data.data;
            this.records =
              fetchedParams.page === 1 ? rows : [...this.records, ...rows];
            this.totalPages = totalPages;
            this.isLoading = false;
          }
        });
    },
    nextPage() {
      this.page += 1;
      this.getRecords();
    },
    toggleShowThisConsultOnly() {
      this.records = [];
      this.page = 1;
      this.getRecords();
    },
    openRecord(id) {
      this.isRecordDetailOpened = true;
      this.isRecordDetailLoading = true;
      this.axios.get(`/diagnoses/${id}`).then((res) => {
        this.recordDetail = res.data.data;
        this.isRecordDetailLoading = false;
      });
    },
    closeRecord() {
      this.isRecordDetailOpened = false;
      this.isRecordDetailLoading = false;
      this.recordDetail = null;
      this.$forceUpdate();
    },
    getAge(birthday) {
      if (birthday) {
        const totalMonths = this.$moment().diff(birthday, 'months');
        const years = parseInt(totalMonths / 12);
        const months = totalMonths % 12;

        if (months > 0) {
          return `${years} tahun ${months} bulan`;
        }
        return `${years} tahun`;
      }
      return null;
    },
  },
};
</script>
<style scoped>
.gap-0\.5 {
  gap: 0.5rem;
}

.gap-1 {
  gap: 1rem;
}

.mt-0\.5 {
  margin-top: 0.5rem;
}

.mb-0\.5 {
  margin-bottom: 0.5rem;
}

.ml-0\.5 {
  margin-left: 0.5rem;
}

.cField {
  font-size: 12px;
}

.cField >>> p {
  font-size: 12px;
}

.discontinued-label,
.prescribed-label {
  color: white;
  padding: 2px 4px;
  font-size: 0.75rem;
  border-radius: 5px;
}
</style>
