<template>
    <section class="mw-100 p-2">
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="card">
              <div class="card-body">
                <p class="f-16 font-600">Customer Information</p>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Nama</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">
                      {{ data.user.isVip ? `👑 ${data.user.fullName}` : data.user.fullName }}
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Email</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">{{ data.user.account.email }}</div>
                </div>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">No HP</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">{{ data.user.mobileNumber }}</div>
                </div>
              </div>
            </div>

            <div class="card" v-if="data.order_shipping_address">
              <div class="card-body">
                <p class="f-16 font-600">Info Pengiriman</p>
                <p class="f-14 d-flex align-items-center gap-1 justify-content-between">
                  <span class="text-secondary flex-shrink-0">Nama Penerima</span>
                  <span class="font-500 text-right">{{ data.order_shipping_address.name }}</span>
                </p>
                <p class="f-14 d-flex align-items-center gap-1 justify-content-between">
                  <span class="text-secondary flex-shrink-0">Detail Alamat</span>
                  <span class="font-500 text-right">{{ data.order_shipping_address.destinationArea }}</span>
                </p>
                <p class="f-14 d-flex align-items-center gap-1 justify-content-between">
                  <span class="text-secondary flex-shrink-0">Catatan Kurir</span>
                  <span class="font-500 text-right">{{ data.order_shipping_address.address }}</span>
                </p>
                <p class="f-14 d-flex align-items-center gap-1 justify-content-between">
                  <span class="text-secondary flex-shrink-0">Kelurahan</span>
                  <span class="font-500 text-right">{{ data.order_shipping_address.area }}</span>
                </p>
                <p class="f-14 d-flex align-items-center gap-1 justify-content-between">
                  <span class="text-secondary flex-shrink-0">Kecamatan</span>
                  <span class="font-500 text-right">{{ data.order_shipping_address.suburb }}</span>
                </p>
                <p class="f-14 d-flex align-items-center gap-1 justify-content-between">
                  <span class="text-secondary flex-shrink-0">Kota</span>
                  <span class="font-500 text-right">{{ data.order_shipping_address.city }}</span>
                </p>
                <p class="f-14 d-flex align-items-center gap-1 justify-content-between">
                  <span class="text-secondary flex-shrink-0">Provinsi</span>
                  <span class="font-500 text-right">{{ data.order_shipping_address.province }}</span>
                </p>
                <p class="f-14 d-flex align-items-center gap-1 justify-content-between">
                  <span class="text-secondary flex-shrink-0">Kode Pos</span>
                  <span class="font-500 text-right">{{ data.order_shipping_address.zipCode }}</span>
                </p>
                <p class="f-14 d-flex align-items-center gap-1 justify-content-between">
                  <span class="text-secondary flex-shrink-0">No HP</span>
                  <span class="font-500 text-right">{{ data.order_shipping_address.mobileNumber }}</span>
                </p>
                <p class="f-14 d-flex align-items-center gap-1 justify-content-between">
                  <span class="text-secondary flex-shrink-0">Ekspedisi Pengiriman</span>
                  <span class="font-500 text-right">{{ `${data.order_shipping_address.expedition} (${data.order_shipping_address.shipperReference.rate.name})` }}</span>
                </p>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <p class="f-16 font-600">Info Transaksi</p>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Kode Transaksi</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">{{ data.orderNumber }}</div>
                </div>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary align-self-center">Status Pesanan</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">
                      <div class="btn btn-sm" :class="{ 
                        'btn-success': data.statusTransaction == 0, 
                        'btn-warning': data.statusTransaction == 1, 
                        'btn-primary': data.statusTransaction == 2, 
                        'btn-danger': data.statusTransaction == 3 
                      }">
                        {{ data.statusTransactionDetail.name }}
                      </div>
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Invoice</div>
                    <div class="col-7 f-14 font-500 text-right pr-1 text-blue">{{ data.invoice }}</div>
                </div>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Tanggal Order</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">{{ data.createdAt | moment('ll, HH:mm') }}</div>
                </div>
                <div class="row">
                    <div class="col-5 f-14 text-secondary">Tipe Transaksi</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">{{ getOrderTypeLabel(data.type) }}</div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <p class="f-16 font-600">Rincian Pembayaran</p>
                <div class="row mb-1">
                    <div class="col-6 f-14 text-secondary">Metode Pembayaran</div>
                    <div class="col-6 f-14 font-500 text-right pr-1">
                      {{ data.paymentChannel ? data.paymentChannel.name : '-' }}
                      <div v-if="data.paymentReference">
                        <span v-if="data.paymentReference.va_numbers"> {{data.paymentReference.va_numbers[0].bank.toUpperCase()}}</span>
                        <span v-else-if="data.paymentReference.issuer"> {{data.paymentReference.issuer.toUpperCase()}}</span>
                        <span v-else-if="data.paymentReference.acquirer"> {{data.paymentReference.acquirer.toUpperCase()}}</span>
                      </div>
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-6 f-14 text-secondary">Total Biaya Konsultasi</div>
                    <div v-if="data.consultationCost > 0" class="col-6 f-14 font-500 text-right pr-1">{{ data.consultationCost | currency }}</div>
                    <div v-else class="col-6 f-14 font-500 text-right pr-1">-</div>
                </div>
                <div class="row mb-1">
                    <div class="col-6 f-14 text-secondary">Total Biaya Subscription</div>
                    <div v-if="data.subscriptionCost > 0" class="col-6 f-14 font-500 text-right pr-1">{{ data.subscriptionCost | currency }}</div>
                    <div v-else class="col-6 f-14 font-500 text-right pr-1">-</div>
                </div>
                <div class="row mb-1">
                    <div class="col-6 f-14 text-secondary">Total Biaya Barang</div>
                    <div class="col-6 f-14 font-500 text-right pr-1">{{ data.subtotal | currency }}</div>
                </div>
                <div class="row mb-1">
                    <div class="col-6 f-14 text-secondary">Kredit Konsultasi</div>
                    <div v-if="data.creditAmount > 0" class="col-6 f-14 font-500 text-right pr-1">{{ -data.creditAmount | currency }}</div>
                    <div v-else class="col-6 f-14 font-500 text-right pr-1">-</div>
                </div>
                <div class="row mb-1">
                    <div class="col-6 f-14 text-secondary">Total Harga Ongkir</div>
                    <div v-if="data.shippingCost + data.shippingDiscount > 0" class="col-6 f-14 font-500 text-right pr-1">{{ data.shippingCost + data.shippingDiscount | currency }}</div>
                    <div v-else class="col-6 f-14 font-500 text-right pr-1">-</div>
                </div>
                <div class="row mb-1">
                    <div class="col-6 f-14 text-secondary">Biaya Asuransi Pengiriman</div>
                    <div v-if="data.shippingInsuranceCost > 0" class="col-6 f-14 font-500 text-right pr-1">{{ data.shippingInsuranceCost | currency }}</div>
                    <div v-else class="col-6 f-14 font-500 text-right pr-1">-</div>
                </div>
                <div class="row mb-1">
                    <div class="col-6 f-14 text-secondary">Total Potongan Ongkir</div>
                    <div v-if="data.shippingDiscount > 0" class="col-6 f-14 font-500 text-right pr-1">{{ -data.shippingDiscount | currency }}</div>
                    <div v-else class="col-6 f-14 font-500 text-right pr-1">-</div>
                </div>
                <div class="row mb-1">
                    <div class="col-6 f-14 text-secondary">Total Potongan Diskon <span v-if="data.discount > 0">({{data.promoCode}})</span></div>
                    <div v-if="data.discount > 0" class="col-6 f-14 font-500 text-right pr-1">{{ -data.discount | currency }}</div>
                    <div v-else class="col-6 f-14 font-500 text-right pr-1">-</div>
                </div>
                <div class="row mb-1">
                    <div class="col-6 f-14 text-secondary">Total Potongan Subscription</div>
                    <div v-if="data.subscriptionDiscount > 0" class="col-6 f-14 font-500 text-right pr-1">{{ -data.subscriptionDiscount | currency }}</div>
                    <div v-else class="col-6 f-14 font-500 text-right pr-1">-</div>
                </div>
                <hr/>
                <div class="row">
                    <div class="col-6 f-14 text-secondary">Total Harga</div>
                    <div class="col-6 f-14 font-500 text-right pr-1 text-danger">{{ data.grandtotal | currency }}</div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="col-12 col-md-4">
            <div class="card" v-if="data.consult">
              <div class="card-body">
                <p class="f-16 font-600">Info Konsultasi</p>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Konsul ID</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">{{ data.consult.consultId }}</div>
                </div>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Chat ID</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">{{ data.consult.id }}</div>
                </div>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Time to fill</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">{{ data.consult.createdAt | moment("ll, HH:mm")  }}</div>
                </div>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Klinik</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">{{ data.consult.subClinic }}</div>
                </div>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Kondisi</div>
                    <div v-if="data.consult.flow" class="col-7 f-14 font-500 text-right pr-1">{{ data.consult.flow.title }}</div>
                </div>
              </div>
            </div>

            <div class="card" v-if="data.subscription">
              <div class="card-body">
                <p class="f-16 font-600">Info Subscription</p>
                <div class="row mb-1">
                  <div class="col-5 f-14 text-secondary">Subscription ID</div>
                  <div class="col-7 f-14 font-500 text-right pr-1">{{ data.subscription.package.id }}</div>
                </div>
                <div class="row mb-1">
                  <div class="col-5 f-14 text-secondary">Subscription Package</div>
                  <div class="col-7 f-14 font-500 text-right pr-1">{{ data.subscription.package.name }}</div>
                </div>
                <div class="row mb-1">
                  <div class="col-5 f-14 text-secondary">Active Period</div>
                  <div class="col-7 f-14 font-500 text-right pr-1">
                    {{ data.subscription.activePeriodStart | moment('DD MMM YYYY') }} - {{ data.subscription.activePeriodEnd | moment('DD MMM YYYY') }}
                  </div>
                </div>
              </div>
            </div>
            
            <div class="card" v-if="data.products && data.products.length > 0">
              <div class="card-body pb-0">
                  <p class="f-16 font-600">Detail Pembelian</p>
                  <div class="row pr-1 mb-1" v-for="(pr, index) in data.products" :key="index">
                      <div class="col align-self-center">
                          <p class="f-14 font-500 mb-0">
                            {{ pr.title }}
                            <span v-if="pr.order_product.isAddOn" class="medicine-label bg-dark">Add-on</span>
                            <span v-if="pr.deletedAt || pr.isActive === false" class="medicine-label bg-danger">Discontinued</span>
                          </p>
                          <p class="mb-0 text-warning f-12 font-600">{{ pr.order_product.price | currency }} <span class="font-400 text-secondary">({{pr.order_product.quantity | numFormat}} Item )</span></p>
                      </div>
                      <img :src="pr.images.length > 0 ? pr.images[0] : 'https://via.placeholder.com/100'" alt="" style="width: 48px; height: 48px;" class="rounded">
                  </div>
              </div>
            </div>

            <div v-if="data.type === 2 || data.type === 3 || data.type === 4">
              <button v-if="hasEditPermission" class="btn btn-primary mb-2 w-100 rounded" :class="{'mb-1': data.specialRequest}" @click="openSpecialRequestModal()">{{ data.specialRequest ? 'Edit' : 'Add' }} Special Request</button>
              <div class="card" v-if="data.specialRequest">
                <div class="card-body pb-0">
                    <p class="f-16 font-600">Special Request</p>
                    <p class="f-14 font-500" v-html="formattedSpecialRequest"></p>
                    <p class="f-14 font-400 mt-1 text-secondary">Updated by {{ data.specialRequestUpdatedBy }} ({{ data.specialRequestUpdatedAt | moment("ll, HH:mm") }})</p>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <p class="f-16 font-600">Payment Info</p>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Start Payment</div>
                    <div class="col-7 f-14 font-500 text-right pr-1" v-if="data.paymentReference">{{ data.paymentReference.transaction_time | moment("ll, HH:mm") }}</div>
                    <div class="col-7 f-14 font-500 text-right pr-1" v-else>-</div>
                </div>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Expired Payment</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">{{ '-' }}</div>
                </div>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Payment Success</div>
                    <div class="col-7 f-14 font-500 text-right pr-1" v-if="data.paymentReference && data.paymentReference.settlement_time">{{ data.paymentReference.settlement_time | moment("ll, HH:mm") }}</div>
                    <div class="col-7 f-14 font-500 text-right pr-1" v-else>-</div>
                </div>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Metode Pembayaran</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">
                      {{ data.paymentChannel ? data.paymentChannel.name : '-' }}
                      <div v-if="data.paymentReference">
                        <span v-if="data.paymentReference.va_numbers"> {{data.paymentReference.va_numbers[0].bank.toUpperCase()}}</span>
                        <span v-else-if="data.paymentReference.issuer"> {{data.paymentReference.issuer.toUpperCase()}}</span>
                        <span v-else-if="data.paymentReference.acquirer"> {{data.paymentReference.acquirer.toUpperCase()}}</span>
                      </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-5 f-14 text-secondary">Status</div>
                    <div class="col-7 f-14 font-500 text-right pr-1 text-blue">{{ data.paymentStatus }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="card">
              <div class="card-body">
                <p class="f-16 font-600">Notes</p>
                <textarea name="" id="" cols="30" rows="3" class="form-control" placeholder="Tinggalkan komentar..." v-model="data.notes" :disabled="!hasEditPermission"></textarea>
                <button v-if="hasEditPermission" class="btn btn-primary mt-1 w-100 rounded" @click="updateNotes()">Update Notes</button>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <p class="f-16 font-600">Resi Pengiriman</p>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Kurir</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">{{ data.order_shipping_address ? data.order_shipping_address.expedition + ' (' + data.order_shipping_address.shipperReference.rate.name + ')' : '-' }}</div>
                </div>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Resi Shipper</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">{{ data.shipperId ? data.shipperId : '-' }}</div>
                </div>
                <div class="row mb-1">
                    <div class="col-5 f-14 text-secondary">Resi Operator</div>
                    <div class="col-7 f-14 font-500 text-right pr-1">{{ data.awb ? data.awb : '-' }}</div>
                </div>
                <div class="eta" v-if="data.estimateTimeArrival && data.statusDelivery === 3">
                    <img src="/assets/img/icon/truck-gray-icon.svg" width="18" height="18" alt="" />
                    Estimasi diterima {{ formatETA(new Date (data.estimateTimeArrival)) }}
                </div>
                <!-- <div class="row">
                    <div class="col-5 f-14 text-secondary">Status</div>
                    <div class="col-7 f-14 font-500 text-right pr-1 " :class="data.statusDelivery == 3 ? 'text-warning' : 'text-primary' ">{{data.statusDelivery == 3 ? 'SEDANG DIKIRIM' : 'SAMPAI TUJUAN'}}</div>
                </div> -->
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <button class="btn btn-success w-100 rounded" @click="downloadPdf()">Lihat Invoice PDF</button>
              </div>
            </div>

            <div class="card" v-if="trackings.length > 0">
                <div class="card-body">
                    <p class="f-16 font-600">Informasi Pengiriman</p>
                    <div class="row mb-1" v-for="(tracking, index) in trackings" :key="index">
                        <div class="col-6 f-14 text-secondary" style="border-right: 1px solid #ccc;">
                            <p class="f-14 text-primary mb-0 font-600">{{tracking.logistic_status.name}}</p>
                            <p class="f-12 text-secondary font-300 mb-0">{{ tracking.created_date | moment('dddd, DD MMMM YYYY HH:mm') }} WIB</p>
                        </div>
                        <div class="col-6 f-14 font-500 pr-1">
                            <p class="f-12 text-secondary font-400 mb-0">
                                {{ tracking.logistic_status.description}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>

        <!-- Add special request modal -->
        <modal :name="ADD_SPECIAL_REQUEST" :adaptive="true" height="auto" :width="500" style="z-index: 2000">
          <div class="modal-container">
            <p class="f-16 text-black font-600">Detail Special Request</p>
            <textarea name="" id="" cols="30" rows="3" class="form-control" placeholder="Special Request..." v-model="newSpecialRequest"></textarea>
            <div class="d-flex flex-row gap-1 justify-content-end">
              <button class="btn btn-outline-primary mt-1 rounded" @click="closeSpecialRequestModal()">Cancel</button>
              <button class="btn btn-primary mt-1 rounded" @click="updateSpecialRequest()">Save</button>
            </div>
          </div>
        </modal>

    </section>
</template>

<script type="text/javascript">
import { getOrderTypeLabel } from '@/lib/order';

const ADD_SPECIAL_REQUEST ='add_special_request'

export default {
  data() {
    return {
      ADD_SPECIAL_REQUEST,
      id: this.$route.params.id,
      data: {
        user: {
          account: {}
        },
        status: {},
        paymentReference: {},
        paymentChannel: {},
        consult: {},
        statusTransactionDetail: {},
      },
      newSpecialRequest: "",
      trackings: [],
    }
  },
  computed: {
    formattedSpecialRequest() {
      return this.data.specialRequest.replace(/\n/g, '<br />');
    },
    permissions() {
      return this.$store.getters.permissions;
    },
    hasViewPermission() {
      return this.permissions.includes('transaction:view');
    },
    hasEditPermission() {
      return this.permissions.includes('transaction:edit');
    },
  },
  beforeDestroy() {
        let _ = this
        _.$root.$emit('addAdditionalInfo', '')
    },
  mounted() {
    if (this.hasViewPermission || this.hasEditPermission) {
      this.getPageDetail();
    } else {
      // If user has only transaction:download permission 
      window.location.assign('/daftar-transaksi');
    }
  },
  methods: {
    getPageDetail() {
      let _ = this
      _.$moment.locale('id')
      _.axios.get('/orders/' + _.id)
        .then(resp => {
          _.data = resp.data.data
          _.newSpecialRequest = resp.data.data.specialRequest;

          _.$root.$emit('addAdditionalInfo', _.data.orderNumber)
        })
        .catch(err => {
          console.log(err.response)
        })
      _.getTrackingDetail()
    },
    formatETA(date) {
      if (!date) return '-';
      return new Intl.DateTimeFormat('id-ID', {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }).format(date);
    },
    getOrderTypeLabel,
    updateNotes() {
      let _ = this
      _.axios.put('/orders/'+ _.id +'/notes', {
        notes: _.data.notes
      })
        .then(resp => {
          _.data = resp.data.data
          _.$swal.fire({
              title: resp.data.message,
              text: 'Notes Updated',
              icon: 'success',
              confirmButtonColor: '#0036A0',
              confirmButtonText: 'OK'
          })
        })
        .catch(err => {
          console.log(err.response)
        })
    },
    downloadPdf(){
      let _ = this

      _.axios.get('/orders/' + _.$route.params.id + '/invoice'
          ,{responseType: 'arraybuffer'})
          .then(resp => {
              let blob        = new Blob([resp.data], { type: 'application/pdf' })
              let link        = document.createElement('a')
              link.href       = window.URL.createObjectURL(blob)
              link.download   = 'ORDER-INVOICE-' + _.data.orderNumber + '.pdf'
              link.click()
          })
          .catch(err => {
              console.log(err.response)
          })
    },
    getTrackingDetail() {
        let _ = this
        _.axios.get('orders/' + _.$route.params.id + '/shipping')
            .then(resp => {
                _.trackings = resp.data.data.data.trackings
            })
    },
    lihat(x) {
            let _ = this
            _.$swal.fire({
                imageUrl: x,
                imageWidth: '100%',
                width: '50%',
                customClass: {
                    image: 'rounded'
                }
            })
    },
    openSpecialRequestModal() {
        this.$modal.show(ADD_SPECIAL_REQUEST);
    },
    closeSpecialRequestModal() {
        this.$modal.hide(ADD_SPECIAL_REQUEST);
    },
    updateSpecialRequest() {
      let _ = this
      _.axios.patch('v2/orders/'+ _.id +'/special-request', {
        specialRequest: _.newSpecialRequest
      })
        .then(resp => {
          _.data = resp.data.data
          _.closeSpecialRequestModal();
          _.$swal.fire({
              title: resp.data.message,
              text: 'Special Request Updated',
              icon: 'success',
              confirmButtonColor: '#0036A0',
              confirmButtonText: 'OK'
          })
          _.getPageDetail();
        })
        .catch(err => {
          console.log(err.response)
        })
    }
  }
}
</script>

<style scoped>
.medicine-label {
    color: white;
    padding: 2px 4px;
    font-size: .75rem;
    margin-left: 8px;
    border-radius: 5px;
}

.eta {
  background-color: #f3f4f6;
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: .75rem;
}

.gap-1 {
  gap: 1rem;
}
.modal-container{
    overflow: scroll;
    padding: 1rem;
    height: 100%;
}
</style>