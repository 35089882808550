<template>
  <p v-if="isLoading" class="mb-0 f-14 text-black">Loading...</p>
  <p v-else-if="!isLoading && data === null" class="mb-0 f-14 text-black">
    Data tidak ditemukan
  </p>
  <div
    v-else
    class="p-1 my-0 mx-auto"
    style="max-width: 960px; background-color: white; min-height: 100vh"
  >
    <h1 class="f-20 font-600 text-black mb-2 mb-sm-3">Informasi Kuesioner</h1>
    <div class="d-flex flex-column flex-sm-row gap-1">
      <div class="d-flex flex-column gap-1 flex-1">
        <div>
          <span class="f-12">Konsul ID</span>
          <p class="mb-0 f-14 font-500 text-blue">{{ data.consultId }}</p>
        </div>
        <div>
          <span class="f-12">Nama</span>
          <div class="text-truncate d-flex align-items-center" style="gap: 4px">
            <span v-if="data.user.isVip">👑</span>
            <img
              v-if="data.user.isSubscribed && !data.user.isVip"
              src="/assets/img/icon/diri-xtra-logo.svg"
              width="20"
              height="20"
              alt=""
            />
            <img
              v-if="data.user.isLowValueCustomer"
              src="/assets/img/icon/low-value-customer.png"
              width="18"
              height="18"
              alt=""
            />
            <span class="f-14 font-500 text-blue text-truncate">{{
              data.user.fullName
            }}</span>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column gap-1 flex-1">
        <div>
          <span class="f-12">Jenis Kelamin</span>
          <p class="mb-0 f-14 font-500 text-blue">{{ gender }}</p>
        </div>
        <div>
          <span class="f-12">Usia</span>
          <p class="mb-0 f-14 font-500 text-blue">{{ data.user.age }} tahun</p>
        </div>
      </div>
      <div class="d-flex flex-column gap-1 flex-1">
        <div>
          <span class="f-12">Poliklinik</span>
          <p class="mb-0 f-14 font-500 text-blue">{{ data.subClinic }}</p>
        </div>
        <div>
          <span class="f-12">Kondisi</span>
          <p class="mb-0 f-14 font-500 text-blue">{{ data.flowTitle }}</p>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column mt-3 mt-sm-5 gap-1">
      <div
        class="
          d-flex
          flex-column flex-sm-row
          gap-1
          px-0 px-sm-1
          pb-1
          border-bottom
        "
      >
        <span class="d-none d-sm-flex font-600 text-black flex-1"
          >Pertanyaan</span
        >
        <span class="d-none d-sm-flex font-600 text-black flex-1">Jawaban</span>
        <span class="d-flex d-sm-none font-600 text-black flex-1"
          >Pertanyaan & Jawaban</span
        >
      </div>
      <div
        v-for="(item, idx) in data.questionnaire"
        :key="idx"
        class="
          d-flex
          flex-column flex-sm-row
          gap-1
          px-0 px-sm-1
          pb-1
          border-bottom
        "
      >
        <div class="d-flex flex-column flex-1">
          <span class="f-10">{{ item.title }}</span>
          <p class="mb-0 text-black f-14">{{ item.question }}</p>
        </div>
        <div class="flex-1 d-flex flex-column gap-1">
          <div v-for="(answer, idx) in item.answers" :key="idx">
            <p
              v-if="answer.value"
              class="mb-0 text-black f-14"
              style="overflow-wrap: anywhere; word-break: normal"
            >
              {{ answer.value }}
            </p>
            <img
              v-if="answer.image"
              :src="answer.image"
              width="120"
              height="120"
              style="object-fit: cover; object-position: center"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      data: null,
    };
  },
  computed: {
    gender: function () {
      switch (this.data.user.gender) {
        case 'M':
          return 'Laki-laki';
        case 'F':
          return 'Perempuan';
        default:
          return '-';
      }
    },
  },
  mounted() {
    this.getQuestionnaireAnswer();
  },
  methods: {
    getQuestionnaireAnswer() {
      this.isLoading = true;
      const consultId = this.$route.params.id;
      this.axios
        .get(`/consults/answers/${consultId}`)
        .then((res) => {
          const { data } = res.data;
          this.data = data;
          document.title = `${data.user.fullName} - ${data.consultId}`;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.gap-1 {
  gap: 1rem;
}

.flex-1 {
  flex: 1;
}

div.border-bottom {
  border-color: #dddddd !important;
}
</style>
