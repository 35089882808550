<template>
    <UserForm :userProps="userData" pageType="edit" v-if="!isLoading" />
    <div class="mw-100 p-2" v-else>
        <div class="section">
            <div class="card">
                <div class="card-body">
                    Loading...
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import UserForm from './components/UserForm.vue'

    export default {
        components: {
            UserForm,
        },
        data() {
            return {
                userData: {},
                isLoading: true,
            }
        },
        mounted() {
            if (this.hasEditPermission) {
                this.getDetail();
            } else {
                window.location.assign('/offline-clinic-booking');
            }
        },
        computed: {
            permissions() {
                return this.$store.getters.permissions;
            },
            hasEditPermission() {
                return this.permissions.includes('offline_clinic_booking:edit');
            },
        },
        methods: {
            getDetail() {
                this.axios.get(`/v2/clinical/offline-clinic-booking/users/${this.$route.params.id}`)
                    .then((res) => {
                        this.userData = res.data.data;
                        this.isLoading = false;
                    })
            },
        },
    }
</script>