<template>
	<div class="row">
	    <div class="col">
	        <div class="dataTables_paginate paging_simple_numbers">
				<!-- MOBILE ONLY -->
	            <ul class="pagination mb-0 justify-content-center d-lg-none">
	                <li class="paginate_button page-item previous" :class="{ disabled: data.currentPage <= 1 }">
	                    <button type="button" class="page-link" @click.prevent="changePage(data.currentPage - 1)"></button>
	                </li>

					<li class="paginate_button page-item" v-for="(page, key) in pages" :key="key" :class="isCurrentPage(page) ? 'active' : ''">
						<button type="button" class="page-link" @click.prevent="changePage(page)">{{ page }}</button>
					</li>

	                <li class="paginate_button page-item next" :class="{ disabled: data.currentPage >= data.totalPages }">
	                    <button type="button" class="page-link" @click.prevent="changePage(data.currentPage + 1)"></button>
	                </li>
	            </ul>

				<!-- DESKTOP ONLY -->
				<div class="d-lg-block d-none">
					<ul class="pagination mb-0">
						<li class="paginate_button page-item" :class="{ disabled: data.currentPage <= 1 }">
							<button type="button" class="page-link" @click.prevent="changePage(1)">
								<span class="material-icons f-12">
									keyboard_double_arrow_left
								</span>
							</button>
						</li>
						
						<li class="paginate_button page-item" :class="{ disabled: data.currentPage <= 1 }">
							<button type="button" class="page-link" @click.prevent="changePage(data.currentPage - 1)">
								<span class="material-icons f-12">
									chevron_left
								</span>
							</button>
						</li>

						<li class="paginate_button page-item" v-for="(page, key) in pages" :key="key" :class="isCurrentPage(page) ? 'active' : ''">
							<button type="button" class="page-link" @click.prevent="changePage(page)">{{ page }}</button>
						</li>

						<li class="paginate_button page-item" :class="{ disabled: data.currentPage >= data.totalPages }">
							<button type="button" class="page-link" @click.prevent="changePage(data.currentPage + 1)">
								<span class="material-icons f-12">
									chevron_right
								</span>
							</button>
						</li>

						<li class="paginate_button page-item" :class="{ disabled: data.currentPage >= data.totalPages }">
							<button type="button" class="page-link" @click.prevent="changePage(data.totalPages)">
								<span class="material-icons f-12">
									keyboard_double_arrow_right
								</span>
							</button>
						</li>
					</ul>
				</div>
	        </div>
	    </div>
	</div>
</template>

<script>
export default {
	props: [ 'data' ],
	data() {
		return {
			limit: 10
		}
	},
	methods: {
		isCurrentPage(page){
			return this.data.currentPage === page
		},
		changePage(page) {
			if (page === '...') {
				return;
			}
			if (page > this.data.totalPages) {
				page = this.data.totalPages;
			}
			this.data.currentPage = page;
			this.$root.$emit('paginate', page);
		}
	},
	computed: {
		pages() {
			let current         = this.data.currentPage
			let last            = this.data.totalPages
			let delta           = 2
			let left            = current - delta
			let right           = current + delta + 1
			let range           = []
			let rangeWithDots   = []  
			let l               = 0              

			for (let i = 1; i <= last; i++) {
				if (i == 1 || i == last || i >= left && i < right) {
					range.push(i);
				}
			}

			for (let i of range) {
				if (l) {
					if (i - l === 2) {
						rangeWithDots.push(l + 1);
					} else if (i - l !== 1) {
						rangeWithDots.push('...');
					}
				}
				rangeWithDots.push(i);
				l = i;
			}

			return rangeWithDots;
		}
	},
	watch: {
		limit: function(){
			let _ = this
			_.$root.$emit('changeLimit', _.limit)
		}
	}
}
</script>