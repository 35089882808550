<template>
  <div class="h-100 w-100 overflow-auto position-relative bg-white">
    <div
      class="
        d-flex
        justify-content-between
        gap-1
        align-items-center
        p-1
        position-sticky
        bg-white
        zindex-1
      "
      style="border-bottom: 1px solid #bcc0d1; top: 0"
    >
      <span class="font-500 f-16 text-dark">Resep</span>
      <span @click="closePrescription" class="cursor-pointer f-18">
        <i class="fa fa-close"></i>
      </span>
    </div>
    <div v-if="channel" class="p-1">
      <div v-if="channel.status !== CHANNEL_STATUS.END">
        <ValidationObserver ref="observer">
          <div class="mb-1 d-flex flex-column gap-1">
            <!-- User Info -->
            <p v-if="isLoadingConsult" class="f-12 text-dark mb-0">
              Loading...
            </p>
            <div v-else-if="consult" class="d-flex flex-column gap-1">
              <div
                class="
                  f-14
                  mb-0
                  d-flex
                  flex-row
                  align-item-center
                  gap-2
                  flex-wrap
                "
              >
                <div
                  class="text-truncate d-flex align-items-center"
                  style="gap: 4px"
                >
                  <span v-if="consult.user.isVip">👑</span>
                  <img
                    v-if="consult.user.isSubscribed && !consult.user.isVip"
                    src="/assets/img/icon/diri-xtra-logo.svg"
                    width="20"
                    height="20"
                    alt=""
                  />
                  <img
                    v-if="consult.user.isLowValueCustomer"
                    src="/assets/img/icon/low-value-customer.png"
                    width="18"
                    height="18"
                    alt=""
                  />
                  <span class="font-500 text-dark text-truncate">{{
                    consult.user.fullName
                  }}</span>
                </div>
                <span
                  >{{ consult.user.gender }},
                  {{ getAge(consult.user.dateOfBirth) }}</span
                >
              </div>
              <div
                class="d-flex flex-row align-items-center"
                style="gap: 0.5rem"
              >
                <div
                  v-for="tag in Object.keys(prescriptionUserTags).filter(
                    (tag) => tag !== 'allergic' && tag !== 'importantNotes'
                  )"
                  :key="tag"
                  class="pointer"
                  @click="
                    updatePrescriptionUserTag(tag, !prescriptionUserTags[tag])
                  "
                >
                  <VTooltip>
                    <img
                      :src="
                        prescriptionUserTags[tag] === true
                          ? USER_TAGS[tag].icon.active
                          : USER_TAGS[tag].icon.inactive
                      "
                      width="20"
                      height="20"
                      :alt="USER_TAGS[tag].label"
                    />
                    <template #popper>
                      {{ USER_TAGS[tag].label }}
                    </template>
                  </VTooltip>
                </div>
              </div>
              <div class="d-flex flex-column" style="gap: 0.25rem">
                <ValidationProvider
                  name="Alergi"
                  v-slot="{ errors }"
                  rules="max:60"
                >
                  <label class="f-12">Alergi</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Catatan tentang Alergi pasien"
                    v-model="prescriptionUserTags.allergic"
                  />
                  <small class="text-danger" v-if="errors[0]">{{
                    errors[0]
                  }}</small>
                </ValidationProvider>
              </div>
              <div class="d-flex flex-column" style="gap: 0.25rem">
                <ValidationProvider
                  name="Catatan Pasien"
                  v-slot="{ errors }"
                  rules="max:100"
                >
                  <label class="f-12">Catatan Pasien</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Catatan tentang pasien, contoh: tidak cocok BP25"
                    v-model="prescriptionUserTags.importantNotes"
                  />
                  <small class="text-danger" v-if="errors[0]">{{
                    errors[0]
                  }}</small>
                </ValidationProvider>
              </div>
            </div>

            <!-- Active Medication -->
            <p v-if="isMedicationLoading" class="f-12 text-dark mb-0">
              Loading...
            </p>
            <div
              v-else-if="!isMedicationLoading"
              class="d-flex flex-column"
              style="gap: 0.25rem"
            >
              <span class="f-12 text-dark font-500"
                >Pembelian 3 bulan terakhir</span
              >
              <ul
                v-if="medications.length > 0"
                class="d-flex flex-column mb-0 pl-2"
                style="gap: 0.25rem"
              >
                <li v-for="medication in medications" :key="medication.sku">
                  <p class="font-500 f-12 mb-0 text-blue">
                    {{ medication.totalPurchasedQty }}x [{{ medication.title }}]
                  </p>
                  <p class="f-12 mb-0">
                    <span v-if="medication.signa"
                      >{{ medication.signa }} -
                    </span>
                    <span class="font-500 text-dark">sejak:</span>
                    {{ medication.firstPurchasedAt | moment('DD/MM/YYYY') }}
                  </p>
                </li>
              </ul>
              <span v-else class="f-12">-</span>
            </div>
          </div>
          <hr />
          <ValidationProvider name="P" v-slot="{ errors }" rules="required">
            <div class="d-flex flex-row mb-1" style="gap: 1rem">
              <div class="font-600" style="flex: 0 0 1rem">P</div>
              <div style="flex: 1">
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="3"
                  class="form-control"
                  v-model="prescription.p"
                  placeholder="Ini adalah field catatan yang bisa diberikan oleh pihak clinic mengenai user"
                >
                </textarea>
                <small class="text-danger" v-if="errors[0]">{{
                  errors[0]
                }}</small>
              </div>
            </div>
          </ValidationProvider>
          <div class="d-flex flex-row mb-1" style="gap: 1rem">
            <div class="font-600" style="flex: 0 0 1rem">D</div>
            <div style="flex: 1">
              <ValidationProvider name="D" v-slot="{ errors }" rules="required">
                <multiselect
                  v-model="prescription.d"
                  label="diagnosis"
                  track-by="id"
                  placeholder="Cari diagnosis"
                  :options="diagnoses"
                  :multiple="false"
                  :searchable="true"
                  :internal-search="true"
                  :clear-on-select="false"
                  :close-on-select="true"
                >
                  <span slot="noResult">Oops! No diagnoses found.</span>
                </multiselect>
                <small class="text-danger" v-if="errors[0]">{{
                  errors[0]
                }}</small>
              </ValidationProvider>
              <ValidationProvider
                name="D Notes"
                v-slot="{ errors }"
                rules="required"
              >
                <textarea
                  cols="30"
                  rows="3"
                  class="form-control mt-1"
                  v-model="prescription.dNotes"
                  placeholder="Ini adalah field catatan yang bisa diberikan oleh pihak clinic mengenai user"
                ></textarea>
                <small class="text-danger" v-if="errors[0]">{{
                  errors[0]
                }}</small>
              </ValidationProvider>
            </div>
          </div>
          <hr />
          <p v-if="isLoadingConsult" class="f-12">Loading...</p>
          <div
            v-else-if="hasUserProduct"
            class="d-flex flex-row mb-1"
            style="gap: 1rem"
          >
            <div class="font-600 text-blue" style="flex: 0 0 1rem"></div>
            <div style="flex: 1">
              <p class="f-16 font-500 text-warning mb-0">
                Produk Reference by user
              </p>
              <div v-for="product in consult.products" :key="product.id">
                <p class="f-14 text-black font-500 mb-0">
                  {{ product.title }}
                  <small>(Qty: {{ product.consult_product.qty }})</small>
                </p>
                <p class="f-12 font-300 mb-0 text-muted">
                  {{ product.description }}
                </p>
              </div>
            </div>
          </div>
          <template v-else>
            <div v-for="(item, index) in prescription.items" :key="index">
              <div class="d-flex flex-row mb-1" style="gap: 1rem">
                <div
                  class="font-600 text-blue align-self-center"
                  style="flex: 0 0 1rem"
                >
                  <input
                    type="checkbox"
                    class="mb-1"
                    v-model="prescription.items[index].recommended"
                    title="Recommended"
                  />
                </div>
                <div style="flex: 1">
                  <div class="d-flex flex-row flex-wrap" style="gap: 1rem">
                    <div class="w-100">
                      <ValidationProvider
                        :name="`Resep Produk ${index + 1}`"
                        v-slot="{ errors }"
                        rules="required"
                        ref="prescriptionItemInputs"
                      >
                        <multiselect
                          v-model="prescription.items[index].object"
                          label="title"
                          track-by="id"
                          placeholder="Cari produk"
                          :options="productOptions"
                          :multiple="false"
                          :searchable="true"
                          :internal-search="true"
                          :clear-on-select="false"
                          :close-on-select="true"
                          @input="$forceUpdate()"
                        >
                          <span slot="noResult">Oops! No product found.</span>
                        </multiselect>
                        <small class="text-danger" v-if="errors[0]">{{
                          errors[0]
                        }}</small>
                      </ValidationProvider>
                    </div>
                    <div style="flex: 1">
                      <ValidationProvider
                        :name="`Jumlah Produk ${index + 1}`"
                        v-slot="{ errors }"
                        :rules="{
                          required: true,
                          min_value_custom:
                            prescription.items[index].object &&
                            prescription.items[index].object.minPurchaseQuantity
                              ? prescription.items[index].object
                                  .minPurchaseQuantity
                              : 1,
                          max_qty:
                            prescription.items[index].object &&
                            prescription.items[index].object.maxPurchaseQuantity
                              ? prescription.items[index].object
                                  .maxPurchaseQuantity
                              : false,
                        }"
                        ref="prescriptionItemAmountInputs"
                      >
                        <input
                          type="number"
                          name=""
                          id=""
                          placeholder="Jumlah"
                          v-model="prescription.items[index].usageAmount"
                          class="form-control"
                          @keypress="numbersOnly"
                          @keydown="preventArrowUpAndDown"
                          @wheel="(e) => e.preventDefault()"
                        />
                        <small class="text-danger" v-if="errors[0]">{{
                          errors[0]
                        }}</small>
                      </ValidationProvider>
                    </div>
                    <div style="flex: 1">
                      <ValidationProvider
                        :name="`Dosis Produk ${index + 1}`"
                        v-slot="{ errors }"
                        rules="required|max:255"
                        ref="prescriptionItemUsageInputs"
                      >
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Dosis"
                          v-model="prescription.items[index].usageInterval"
                        />
                        <small class="text-danger" v-if="errors[0]">{{
                          errors[0]
                        }}</small>
                      </ValidationProvider>
                    </div>
                  </div>
                  <button
                    class="btn btn-danger btn-sm mt-1 col"
                    @click="deletePrescriptionItem(index)"
                  >
                    Hapus Resep
                  </button>
                </div>
              </div>
            </div>
            <div class="d-flex flex-row mb-1" style="gap: 1rem">
              <div class="font-600 text-blue" style="flex: 0 0 1rem">R/</div>
              <div class="d-flex gap-1" style="flex: 1">
                <button
                  class="btn btn-sm btn-outline-primary"
                  style="flex: 3"
                  @click="addPrescriptionItem()"
                >
                  <i class="fa fa-plus"></i> Tambah Resep
                </button>
                <button
                  class="btn btn-sm btn-primary"
                  style="flex: 1"
                  @click="openPrescriptionTemplate"
                >
                  Template
                </button>
              </div>
            </div>
          </template>
          <ValidationProvider name="C" v-slot="{ errors }" rules="required">
            <div class="d-flex flex-row mb-1" style="gap: 1rem">
              <div class="font-600" style="flex: 0 0 1rem">C</div>
              <div style="flex: 1">
                <quill-editor
                  id="catatan"
                  v-model="prescription.c"
                  :options="editorOption"
                >
                </quill-editor>
                <small class="text-danger" v-if="errors[0]">{{
                  errors[0]
                }}</small>
              </div>
            </div>
          </ValidationProvider>
          <div class="d-flex flex-row mb-1" style="gap: 1rem">
            <div style="flex: 4">
              <ValidationProvider name="K" v-slot="{ errors }" rules="required">
                <div class="d-flex flex-row" style="gap: 1rem">
                  <div class="font-600" style="flex: 0 0 1rem">K</div>
                  <div class="k-date-form" style="flex: 1">
                    <div class="position-relative has-icon-right">
                      <date-picker
                        v-model="prescription.k"
                        format="DD-MM-YYYY"
                        type="date"
                        input-class="form-control"
                        value-type="YYYY-MM-DD"
                        placeholder="dd-mm-yy"
                        style="width: 100%"
                        :disabled-date="restrictStartDate"
                        :clearable="false"
                      >
                      </date-picker>
                    </div>
                    <small class="text-danger" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </div>
              </ValidationProvider>
            </div>
            <div style="flex: 3">
              <ValidationProvider
                name="Iter"
                v-slot="{ errors }"
                rules="required|min_value:1"
              >
                <div class="d-flex flex-row" style="gap: 1rem">
                  <div class="font-600" style="flex: 0 0 1rem">Iter</div>
                  <div style="flex: 1">
                    <input
                      type="number"
                      class="form-control"
                      placeholder="Iter"
                      v-model.number="prescription.iter"
                      @keypress="numbersOnly"
                      @keydown="preventArrowUpAndDown"
                      @wheel="(e) => e.preventDefault()"
                    />
                    <small class="text-danger" v-if="errors[0]">{{
                      errors[0]
                    }}</small>
                  </div>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <button
            class="btn btn-primary w-100 mb-1 rounded"
            :disabled="isLoadingSubmit || isLoadingConsult"
            @click="showApproveConfirmationModal()"
            v-if="channel.status === CHANNEL_STATUS.REVIEW"
          >
            <div v-if="isLoadingConsult">
              Loading <i class="fa fa-spinner fa-spin"></i>
            </div>
            <div v-else-if="isLoadingSubmit">
              Saving <i class="fa fa-spinner fa-spin"></i>
            </div>
            <div v-else>Confirm & Approve</div>
          </button>
          <button
            class="btn btn-primary w-100 mb-1 rounded"
            :disabled="isLoadingSubmit || isLoadingConsult"
            @click="showApproveConfirmationModal()"
            v-if="channel.status === CHANNEL_STATUS.ACTIVE"
          >
            <div v-if="isLoadingConsult">
              Loading <i class="fa fa-spinner fa-spin"></i>
            </div>
            <div v-else-if="isLoadingSubmit">
              Submitting <i class="fa fa-spinner fa-spin"></i>
            </div>
            <div v-else>Submit Diagnosis</div>
          </button>
          <button
            class="btn btn-outline-danger w-100 mb-1 rounded"
            @click="reject()"
            v-if="channel.status === CHANNEL_STATUS.REVIEW"
          >
            Tolak / Not Approved
          </button>
        </ValidationObserver>
      </div>
      <p class="text-blue text-center">
        <router-link :to="`/konsultasi-dan-resep/${channel.consultId}`">
          Rekam Medis <i class="fa fa-link"></i>
        </router-link>
      </p>
    </div>
    <!-- Approve Prescription Confirmation Modal -->
    <modal
      :name="APPROVE_PRESCRIPTION_CONFIRMATION_MODAL"
      :adaptive="true"
      :height="600"
      :width="500"
      style="z-index: 2000"
    >
      <div
        class="
          d-flex
          flex-column
          h-100
          overflow-auto
          justify-content-between
          position-relative
        "
      >
        <div class="d-flex flex-column">
          <p
            class="
              f-18
              font-600
              text-center
              border-bottom
              p-2
              position-sticky
              bg-white
              mb-0
            "
            style="top: 0; left: 0; right: 0"
          >
            Confirm Prescription
          </p>
          <div class="d-flex flex-column p-2">
            <div class="d-flex flex-row gap-2 border p-1 rounded">
              <span class="f-14 font-500">P</span>
              <p class="f-12 mb-0" style="flex: 1">{{ prescription.p }}</p>
            </div>
            <div
              class="
                mt-1
                d-flex
                flex-row
                align-items-center
                justify-content-between
                gap-2
              "
            >
              <span class="btn btn-sm rounded btn-primary flex-shrink-0"
                >Rx {{ prescription.iter }}</span
              >
              <span class="f-14">{{ new Date() | moment('DD-MM-YYYY') }}</span>
            </div>
            <div class="mt-1 d-flex flex-column gap-1">
              <template v-if="channel && hasUserProduct">
                <div
                  v-for="(item, idx) in consult.products"
                  :key="idx"
                  class="border p-1 rounded"
                >
                  <p class="font-500 text-dark f-12 mb-0">{{ item.title }}</p>
                  <p class="f-12 mt-0.25 mb-0">
                    Qty: {{ item.consult_product.qty }}
                  </p>
                </div>
              </template>
              <template v-else>
                <div
                  v-for="(item, idx) in prescription.items"
                  :key="idx"
                  class="border p-1 rounded"
                >
                  <template v-if="item.object">
                    <div class="d-flex flex-row align-items-center gap-1 f-12">
                      <i
                        v-if="item.recommended"
                        class="f-16 fa fa-check-circle text-success"
                      ></i>
                      <span
                        :class="{
                          'text-danger': item.object.type === 'Rx Red',
                          'text-blue': item.object.type === 'Rx Blue',
                        }"
                        >{{ item.object.type }}</span
                      >
                      <span class="font-500 text-dark">{{
                        item.object.title
                      }}</span>
                    </div>
                    <div
                      class="
                        d-flex
                        flex-row
                        align-items-center
                        gap-1
                        flex-wrap
                        f-12
                        mt-0.25
                      "
                    >
                      <span>Qty: {{ item.usageAmount }}</span>
                      <span>Usage: {{ item.usageInterval }}</span>
                    </div>
                    <p class="f-12 mt-0.25 mb-0">
                      <span class="font-500 text-dark">{{
                        item.object.discountPrice ||
                        item.object.price | currency
                      }}</span>
                      / {{ item.object.unit }}
                    </p>
                  </template>
                </div>
              </template>
            </div>
            <div class="d-flex flex-row gap-2 mt-1 border p-1 rounded">
              <span class="f-14 font-500">C</span>
              <div
                class="mb-0 cField"
                style="flex: 1"
                v-html="prescription.c"
              ></div>
            </div>
          </div>
        </div>
        <div
          class="d-flex flex-row border-top p-2 position-sticky bg-white"
          style="gap: 0.5rem; bottom: 0; left: 0; right: 0"
        >
          <button
            class="btn btn-outline-primary rounded"
            style="flex: 1"
            @click="hideApproveConfirmationModal()"
          >
            Cancel
          </button>
          <button
            class="btn btn-primary rounded"
            style="flex: 1"
            @click="approve()"
          >
            Yes
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
const APPROVE_PRESCRIPTION_CONFIRMATION_MODAL =
  'approve-prescription-corfirmation-modal';

export default {
  props: {
    closePrescription: Function,
    channel: Object,
    isLoadingConsult: Boolean,
    consult: Object,
    prescriptionUserTags: Object,
    updatePrescriptionUserTag: Function,
    USER_TAGS: Object,
    isMedicationLoading: Boolean,
    medications: Array,
    prescription: Object,
    diagnoses: Array,
    hasUserProduct: Boolean,
    productOptions: Array,
    deletePrescriptionItem: Function,
    addPrescriptionItem: Function,
    openPrescriptionTemplate: Function,
    isLoadingSubmit: Boolean,
    CHANNEL_STATUS: Object,
    reject: Function,
  },
  data() {
    return {
      APPROVE_PRESCRIPTION_CONFIRMATION_MODAL,
      editorOption: {
        modules: {
          toolbar: false,
        },
        placeholder: 'Catatan plan disini',
      },
    };
  },
  computed: {},
  methods: {
    getRefs(key) {
      return this.$refs[key];
    },
    numbersOnly(e) {
      const charCode = e.keyCode;
      if (
        (charCode >= 48 && charCode <= 57) ||
        (charCode >= 96 && charCode <= 105)
      ) {
        return true;
      }
      e.preventDefault();
    },
    preventArrowUpAndDown(e) {
      const charCode = e.keyCode;
      if (charCode === 38 || charCode === 40) {
        e.preventDefault();
      }
    },
    getAge(birthday) {
      if (birthday) {
        const totalMonths = this.$moment().diff(birthday, 'months');
        const years = parseInt(totalMonths / 12);
        const months = totalMonths % 12;

        if (months > 0) {
          return `${years} tahun ${months} bulan`;
        }
        return `${years} tahun`;
      }
      return null;
    },
    restrictStartDate(date) {
      return date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
    },
    async showApproveConfirmationModal() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.$modal.show(APPROVE_PRESCRIPTION_CONFIRMATION_MODAL);
      }
    },
    hideApproveConfirmationModal() {
      this.$modal.hide(APPROVE_PRESCRIPTION_CONFIRMATION_MODAL);
    },
    approve() {
      this.hideApproveConfirmationModal();
      this.$emit('approve');
    },
  },
};
</script>

<style scoped>
.gap-1 {
  gap: 0.5rem;
}
.gap-2 {
  gap: 1rem;
}
</style>
