<template>
  <div class="wrapper">
    <div
      v-for="tab in tabs"
      :key="tab.value"
      class="tab"
      :class="{ 'tab-active': activeTab && tab.value === activeTab.value }"
      @click="handleClickTab(tab)"
    >
      {{ tab.label }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    activeTab: {
      type: Object,
    },
  },
  methods: {
    handleClickTab(tab) {
      if (tab.value !== this.activeTab?.value) {
        this.$emit('change', tab);
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  display: flex;
  align-items: stretch;
  border-bottom: 1px solid #d9d9d9;
}

.tab {
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  color: #798ea5;
  cursor: pointer;
}

.tab-active {
  color: #0072cd;
  border-bottom: 2px solid #0072cd;
}
</style>
