<template>
  <portal to="prescriptionTemplatePortal">
    <div
      class="position-fixed"
      :style="`top: 0; left: ${isOpen ? 0 : '100%'}; bottom: 0; right: 0; z-index: 2000; background-color: rgba(39, 42, 51, .3);`"
      @click="close"
    >
      <div
        class="position-fixed bg-white"
        :style="`top: 0; bottom: 0; right: 0; max-width: 85vw; width: ${wrapperWidth}; transform: translateX(${isOpen ? 0 : wrapperWidth}); transition: transform .2s ease-in;`"
        @click="(e) => e.stopPropagation()"
      >
        <!-- Prescription Template Detail -->
        <div
          v-if="templateDetail"
          class="position-fixed bg-white overflow-auto"
          style="transition: transform .25s ease-out; inset: 0; z-index: 20;"
          :style="{ transform: `translateX(${isTemplateDetailOpened ? 0 : '100%'})` }"
        >
          <div class="p-1 d-flex flex-row align-items-center justify-content-between gap-0.5 border-bottom bg-white" style="position: sticky; top: 0px; z-index: 10;">
            <div class="d-flex flex-row align-items-center gap-0.5">
              <img src="/assets/img/icon/arrow-left-icon.svg" class="cursor-pointer" width="24" @click="closeTemplate" alt="" />
              <span class="f-18 text-dark font-500">{{ templateDetail.name }}</span>
            </div>
          </div>
          <div class="p-1 d-flex flex-column gap-0.5">
            <div
              v-for="(product, idx) in templateDetail.prescriptionProducts"
              :key="idx"
              class="border p-1 rounded d-flex flex-column gap-0.5"
            >
              <p class="text-dark f-14 font-500 mb-0">{{ product.title }}</p>
              <div class="d-flex flex-row gap-0.5 align-items-center flex-wrap f-12">
                <i v-if="product.recommended" class="f-16 fa fa-check-circle text-success"></i>
                <span>Qty: {{ product.usageAmount }}</span>
                <span style="overflow-wrap: anywhere; word-break: normal;">Usage: {{ product.usageInterval }}</span>
              </div>
            </div>
            <button class="btn btn-sm btn-primary rounded w-100 mt-1" @click="useTemplate">
              Use Template
            </button>
          </div>
        </div>

        <!-- Prescription Template List -->
        <div class="h-100 overflow-auto">
          <div class="p-1 d-flex flex-row align-items-center gap-0.5 border-bottom bg-white" style="position: sticky; top: 0px; z-index: 10;">
            <img src="/assets/img/icon/arrow-left-icon.svg" class="cursor-pointer" width="24" @click="close" alt="" />
            <span class="f-18 text-dark font-500">Prescription Templates</span>
          </div>
          <div class="p-1">
            <div v-if="templates.length > 0" class="d-flex flex-column gap-0.5">
              <div
                v-for="(template, idx) in templates"
                :key="idx"
                class="border p-1 rounded cursor-pointer d-flex flex-row gap-0.5 align-items-center justify-content-between"
                @click="openTemplate(idx)"
              >
                <p class="text-dark f-14 font-500 mb-0" style="white-space: pre">{{ template.name }}</p>
                <span class="text-danger f-10 cursor-pointer" @click="(event) => deleteTemplate(event, template.id)">Delete</span>
              </div>
            </div>
            <p v-if="isLoading" class="f-12 mt-1 mb-0 text-center">Loading...</p>
            <p v-if="!isLoading && templates.length === 0" class="f-12 mb-0 text-center">Belum ada template yang ditambahkan.</p>
            <button v-if="templates.length < MAX_ITEMS" class="btn btn-sm btn-primary rounded w-100 mt-1" @click="openCreatePrescriptionTemplateModal">
              Save Prescription as Template
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Create Prescription Template Modal -->
    <modal :name="CREATE_PRESCRIPTION_TEMPLATE_MODAL" :adaptive="true" height="auto" :width="450" style="z-index: 2000;">
        <div class="d-flex flex-column gap-1 h-100 p-2">
          <p class="f-16 font-600 mb-0">Template Name</p>
          <div>
            <input type="text" class="form-control" placeholder="Enter template name" v-model="newTemplateName" @input="handleInputTemplateName">
            <p v-if="errorNewTemplateName" class="text-danger f-12 mb-0">{{ errorNewTemplateName }}</p>
          </div>
          <div class="d-flex flex-row justify-content-end gap-0.5">
            <button @click="closeCreatePrescriptionTemplateModal" class="btn btn-sm rounded btn-outline-primary" style="width: 100px;">
              Cancel
            </button>
            <button
              @click="submitPrescriptionTemplate"
              class="btn btn-sm rounded btn-primary"
              style="width: 100px;"
              :disabled="newTemplateName.length === 0 || errorNewTemplateName.length > 0 || isSubmitting"
            >
              {{ isSubmitting ? 'Saving...' : 'Save'}}
            </button>
          </div>
        </div>
    </modal>
  </portal>
</template>

<script>

const WIDTH = 480;
const CREATE_PRESCRIPTION_TEMPLATE_MODAL = 'CREATE_PRESCRIPTION_TEMPLATE_MODAL';
const MAX_ITEMS = 50;

export default {
  props: {
    isOpen: Boolean,
    products: Array,
    isProductsValid: Boolean,
  },
  data() {
    return {
      // template list
      MAX_ITEMS,
      wrapperWidth: `${WIDTH}px`,
      isLoading: false,
      templates: [],

      // template detail
      isTemplateDetailOpened: false,
      templateDetail: null,

      // create template
      CREATE_PRESCRIPTION_TEMPLATE_MODAL,
      newTemplateName: '',
      errorNewTemplateName: '',
      isSubmitting: false,
    }
  },
  methods: {
      close() {
        this.$emit('close');
        this.isLoading = false;
        this.templates = [];
        this.$forceUpdate();
        this.closeTemplate();
      },
      getTemplates() {
        this.isLoading = true;
        this.axios.get('/prescription-template')
          .then(res => {
            this.templates = res.data.data;
            this.isLoading = false;
          });
      },
      openTemplate(idx) {
        this.isTemplateDetailOpened = true;
        this.templateDetail = this.templates[idx];
      },
      closeTemplate() {
        this.isTemplateDetailOpened = false;
        this.templateDetail = null;
        this.$forceUpdate();
      },
      deleteTemplate(event, id) {
        event.stopPropagation();
        this.axios.delete(`/prescription-template/${id}`)
          .then((res) => {
            this.$notify({
							type: 'success',
							title: 'Success',
							text: res.data.message,
						});
          })
          .catch((error) => {
            this.$notify({
							type: 'error',
							title: 'Error',
							text: error.response.data.message,
						});
          })
          .finally(() => {
            this.getTemplates();
          });
      },
      openCreatePrescriptionTemplateModal() {
        if (this.isProductsValid) {
          this.$modal.show(CREATE_PRESCRIPTION_TEMPLATE_MODAL);
        } else {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'Periksa kembali data produk',
          });
          this.close();
          this.$emit('failedSubmit');
        }
      },
      closeCreatePrescriptionTemplateModal() {
        this.$modal.hide(CREATE_PRESCRIPTION_TEMPLATE_MODAL);
        this.newTemplateName = '';
        this.errorNewTemplateName = '';
      },
      handleInputTemplateName(event) {
        const newValue = event.target.value;
        if (this.templates.map(template => template.name).includes(newValue)) {
          this.errorNewTemplateName = 'Template name is already used';
        } else {
          this.errorNewTemplateName = '';
        }
      },
      submitPrescriptionTemplate() {
        this.isSubmitting = true;
        this.axios.post('/prescription-template', {
          name: this.newTemplateName,
          prescriptionProducts: this.products,
        })
          .then((res) => {
            this.$notify({
							type: 'success',
							title: 'Success',
							text: res.data.message,
						});
          })
          .catch((error) => {
            this.$notify({
							type: 'error',
							title: 'Error',
							text: error.response.data.message,
						});
          })
          .finally(() => {
            this.isSubmitting = false;
            this.closeCreatePrescriptionTemplateModal();
            this.getTemplates();
          });
      },
      useTemplate() {
        this.$emit('useTemplate', this.templateDetail.prescriptionProducts);
        this.close();
      },
  },
  watch: {
    isOpen: function(isOpen) {
      if (isOpen) {
        this.getTemplates();
      }
    }
  },
}
</script>
<style scoped>
.gap-0\.5 {
  gap: 0.5rem;
}

.gap-1 {
  gap: 1rem;
}
</style>