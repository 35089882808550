<template>
  <dropdown-menu position="right" ref="dropdown">
    <div
      slot="trigger"
      class="trigger-button"
      :class="{ 'active-trigger-button': appliedFiltersCounter > 0 }"
    >
      <span>
        <i class="fa fa-filter"></i>
      </span>
      <span>{{ triggerButtonLabel }}</span>
    </div>
    <div slot="content" style="width: 350px">
      <div class="more-filter-content-header">
        <span class="text-black font-700">Filters</span>
        <span @click="reset" class="reset-button">Reset</span>
      </div>
      <div class="more-filter-content">
        <div v-if="withDateFilter">
          <p class="input-label">Date</p>
          <div id="datepicker">
            <date-picker
              :value="value.date"
              format="DD/MM/YYYY"
              type="date"
              input-class="form-control"
              value-type="YYYY-MM-DD"
              placeholder="Select Date"
              style="width: 100%"
              range
              :clearable="false"
              :disabled-date="disabledDate"
              @open="handleOpenDatePicker"
              @input="(val) => $emit('input', { ...value, date: val })"
            >
            </date-picker>
          </div>
        </div>
        <div>
          <p class="input-label">Booking Status</p>
          <div class="status-group">
            <div
              class="status-item"
              v-for="option in bookingStatusOptions"
              :key="option.enum"
            >
              <label class="status-item-input">
                <input
                  type="checkbox"
                  :checked="
                    value.bookingStatus
                      .map((status) => status.value)
                      .includes(option.enum)
                  "
                  @input="
                    (e) =>
                      handleClickBookingStatus(
                        { value: option.enum, label: option.label },
                        e.target.checked
                      )
                  "
                />
                <Badge
                  :textColor="option.textColor"
                  :bgColor="option.backgroundColor"
                >
                  {{ option.label }}</Badge
                >
              </label>
            </div>
          </div>
        </div>
        <div>
          <p class="input-label">Treatment Status</p>
          <div class="status-group">
            <div
              class="status-item"
              v-for="option in bookingDetailStatusOptions"
              :key="option.enum"
            >
              <label class="status-item-input">
                <input
                  type="checkbox"
                  :checked="
                    value.bookingDetailStatus
                      .map((status) => status.value)
                      .includes(option.enum)
                  "
                  @input="
                    (e) =>
                      handleClickBookingDetailStatus(
                        { value: option.enum, label: option.label },
                        e.target.checked
                      )
                  "
                />
                <Badge
                  :textColor="option.textColor"
                  :bgColor="option.backgroundColor"
                  >{{ option.label }}</Badge
                >
              </label>
            </div>
          </div>
        </div>
        <div>
          <p class="input-label">Clinic Team</p>
          <InputMultiSelectCheckbox
            :value="value.clinicTeams"
            :options="clinicTeamOptions"
            :searchable="true"
            dropdownScopeClass="more-filter-content"
            @search="getClinicTeamOptions"
            @input="(val) => $emit('input', { ...value, clinicTeams: val })"
          />
        </div>
        <div>
          <p class="input-label">Room Type</p>
          <InputMultiSelectCheckbox
            :value="value.roomTypes"
            :options="[
              { value: 'CONSULT', label: 'Consult Room' },
              { value: 'FACIAL', label: 'Facial Room' },
              { value: 'TREATMENT', label: 'Treatment Room' },
              { value: 'LASER', label: 'Laser Room' },
            ]"
            dropdownScopeClass="more-filter-content"
            @input="handleChangeRoomType"
          />
        </div>
        <div>
          <p class="input-label">Room Name</p>
          <InputMultiSelectCheckbox
            :value="value.rooms"
            :options="clinicRoomOptions"
            :searchable="true"
            dropdownScopeClass="more-filter-content"
            @search="(search) => getClinicRoomOptions({ search, clinicId })"
            @input="(val) => $emit('input', { ...value, rooms: val })"
          />
        </div>
        <div>
          <p class="input-label">Treatment Name</p>
          <InputMultiSelectCheckbox
            :value="value.treatments"
            :options="clinicTreatmentOptions"
            :searchable="true"
            dropdownScopeClass="more-filter-content"
            @search="
              (search) => getClinicTreatmentOptions({ search, clinicId })
            "
            @input="(val) => $emit('input', { ...value, treatments: val })"
          />
        </div>
      </div>
    </div>
  </dropdown-menu>
</template>

<script>
import DropdownMenu from '@/components/DropdownMenu/DropdownMenu';
import Badge from '@/components/Badge';
import InputMultiSelectCheckbox from '@/components/InputMultiSelectCheckbox';

export default {
  components: {
    DropdownMenu,
    Badge,
    InputMultiSelectCheckbox,
  },
  props: {
    value: {
      type: Object,
      default: function () {
        return {
          date: [null, null],
          bookingStatus: [],
          bookingDetailStatus: [],
          clinicTeams: [],
          roomTypes: [],
          rooms: [],
          treatments: [],
        };
      },
    },
    withDateFilter: {
      type: Boolean,
      default: true,
    },
    defaultDateFilter: {
      type: Array,
      date: [null, null],
    },
    clinicId: {
      type: Number,
    },
  },
  data() {
    return {
      bookingStatusOptions: [],
      bookingDetailStatusOptions: [],
      clinicTeamOptions: [],
      clinicRoomOptions: [],
      clinicTreatmentOptions: [],
    };
  },
  computed: {
    appliedFiltersCounter() {
      const { date, ...restFilters } = this.value;
      let counter = 0;

      if (this.withDateFilter && date[0] !== null && date[1] !== null) {
        counter += 1;
      }

      const restFilterValues = Object.values(restFilters);

      return restFilterValues.reduce((acc, curr) => acc + curr.length, counter);
    },
    triggerButtonLabel() {
      switch (this.appliedFiltersCounter) {
        case 0:
          return 'More Filter';
        case 1:
          return `${this.appliedFiltersCounter} filter applied`;
        default:
          return `${this.appliedFiltersCounter} filters applied`;
      }
    },
  },
  mounted() {
    this.getBookingStatusOptions();
    this.getBookingDetailStatusOptions();
    this.getClinicTeamOptions();
    this.getClinicRoomOptions();
    this.getClinicTreatmentOptions();
  },
  methods: {
    reset() {
      this.$emit('input', {
        date: this.defaultDateFilter,
        bookingStatus: [],
        bookingDetailStatus: [],
        clinicTeams: [],
        roomTypes: [],
        rooms: [],
        treatments: [],
      });
      this.$refs.dropdown.closeDropdown();
    },
    handleOpenDatePicker() {
      setTimeout(() => {
        // add stopPropagation when picking a date to prevent closing dropdown
        document
          .querySelector('.mx-datepicker-main')
          .addEventListener('click', (e) => e.stopPropagation());
      });
    },
    disabledDate(date, currentValue) {
      if (currentValue[0] && currentValue[1]) return false;

      const maxThirtyOneDaysFuture =
        currentValue[0] && this.$moment(date).isAfter(currentValue[0], 'day')
          ? date > new Date(currentValue[0].getTime() + 31 * 24 * 3600 * 1000)
          : false;
      const maxThirtyOneDaysPast =
        currentValue[0] && this.$moment(date).isBefore(currentValue[0], 'day')
          ? date < new Date(currentValue[0].getTime() - 31 * 24 * 3600 * 1000)
          : false;
      return maxThirtyOneDaysFuture || maxThirtyOneDaysPast;
    },
    handleClickBookingStatus(option, isChecked) {
      if (isChecked) {
        this.$emit('input', {
          ...this.value,
          bookingStatus: [...this.value.bookingStatus, option],
        });
      } else {
        this.$emit('input', {
          ...this.value,
          bookingStatus: this.value.bookingStatus.filter(
            (item) => item.value !== option.value
          ),
        });
      }
    },
    handleClickBookingDetailStatus(option, isChecked) {
      if (isChecked) {
        this.$emit('input', {
          ...this.value,
          bookingDetailStatus: [...this.value.bookingDetailStatus, option],
        });
      } else {
        this.$emit('input', {
          ...this.value,
          bookingDetailStatus: this.value.bookingDetailStatus.filter(
            (item) => item.value !== option.value
          ),
        });
      }
    },
    handleChangeRoomType(roomTypes) {
      this.$emit('input', {
        ...this.value,
        roomTypes,
        rooms: [], // reset rooms
      });
      this.getClinicRoomOptions({
        clinicId: this.clinicId,
        roomTypes:
          roomTypes.length > 0
            ? `in:${roomTypes.map((roomType) => roomType.value).join(',')}`
            : undefined,
      });
    },
    getBookingStatusOptions() {
      this.axios
        .get('/v2/misc/offline-clinic-booking-status')
        .then((res) => {
          this.bookingStatusOptions = res.data.data;
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: err.response.data.message,
          });
        });
    },
    getBookingDetailStatusOptions() {
      this.axios
        .get('/v2/misc/offline-clinic-booking-detail-status')
        .then((res) => {
          this.bookingDetailStatusOptions = res.data.data;
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: err.response.data.message,
          });
        });
    },
    getClinicTeamOptions(search) {
      this.axios
        .get('/v2/misc/offline-clinic-teams', {
          params: {
            search: search || undefined,
          },
        })
        .then((res) => {
          this.clinicTeamOptions = res.data.data.rows.map((item) => ({
            value: item.id,
            label: item.adminName,
          }));
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: err.response.data.message,
          });
        });
    },
    getClinicRoomOptions(params) {
      this.axios
        .get('/v2/misc/offline-clinic-rooms', {
          params: {
            search: params?.search || undefined,
            clinicId: params?.clinicId || undefined,
            roomTypes: params?.roomTypes || undefined,
          },
        })
        .then((res) => {
          this.clinicRoomOptions = res.data.data.rows.map((item) => ({
            value: item.id,
            label: item.name,
          }));
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: err.response.data.message,
          });
        });
    },
    getClinicTreatmentOptions(params) {
      this.axios
        .get('/v2/misc/clinic-treatment-details', {
          params: {
            search: params?.search || undefined,
            clinicId: params?.clinicId || undefined,
          },
        })
        .then((res) => {
          this.clinicTreatmentOptions = res.data.data.rows.map((item) => ({
            value: item.id,
            label: item.title,
          }));
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: err.response.data.message,
          });
        });
    },
  },
  watch: {
    clinicId: function (newClinicId) {
      // reset rooms & treatments filters
      this.$emit('input', {
        ...this.value,
        rooms: [],
        treatments: [],
      });

      // fetch rooms & treatments options with new clinic id
      this.getClinicRoomOptions({ clinicId: newClinicId });
      this.getClinicTreatmentOptions({ clinicId: newClinicId });
    },
  },
};
</script>

<style scoped>
.trigger-button {
  height: 40px;
  padding-inline: 12px;
  border: 1px solid #bcc0d1;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.active-trigger-button {
  color: #0072cd;
  background-color: #ddedfa;
  border: 1px solid #ddedfa;
}

.reset-button {
  color: #0072cd;
  cursor: pointer;
}

.more-filter-content-header {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  border-bottom: 1px solid #bcc0d1;
}

.more-filter-content {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.input-label {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 4px;
}

.status-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.status-item {
  height: 40px;
  padding-inline: 12px;
  border: 1px solid #bcc0d1;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.status-item-input {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}
</style>

<style>
#datepicker .form-control {
  padding: 20px 10px;
}
</style>
