<template>
    <div class="mw-100 p-2">
        <div class="row">
            <div class="col-12 col-md-4">
                <div class="card">
                    <div class="card-body">
                        <p class="f-20 font-500 text-black">Informasi Pasien</p>
                        <div class="d-flex flex-column gap-1">
                            <div class="d-flex align-items-center" style="gap: 1rem;">
                                <div class="f-14 text-truncate d-flex align-items-center" style="gap: 4px;" >
                                    <span v-if="model.user.isVip">👑</span>
                                    <img
                                        v-if="model.user.isSubscribed && !model.user.isVip"
                                        src="/assets/img/icon/diri-xtra-logo.svg"
                                        width="20"
                                        height="20"
                                        alt=""
                                    />
                                    <img
                                        v-if="model.user.isLowValueCustomer"
                                        src="/assets/img/icon/low-value-customer.png"
                                        width="18"
                                        height="18"
                                        alt=""
                                    />
                                    <span class="font-500 text-black text-truncate">{{ model.user.fullName }}</span>
                                </div>
                                <span class="f-12 font-400">{{ model.user.gender }},  {{ getAge(model.user.dateOfBirth) }}</span>
                            </div>
                            <p class="f-12 font-400 text-primary">{{ model.user.account.email }}</p>
                            <div class="d-flex flex-row justify-content-between gap-1">
                                <span class="f-12 font-400 text-secondary">Konsul ID:</span>
                                <span class="f-12 font-600 text-primary">{{ model.consultId }}</span>
                            </div>
                            <div class="d-flex flex-row justify-content-between gap-1">
                                <span class="f-12 font-400 text-secondary">Type:</span>
                                <span class="f-12 font-600 text-black">{{ type }}</span>
                            </div>
                            <div class="d-flex flex-row justify-content-between gap-1">
                                <span class="f-12 font-400 text-secondary">Tgl pengaktifan:</span>
                                <span class="f-12 font-600 text-black">{{ model.activationDate  | moment('DD MMM YYYY') }}</span>
                            </div>
                            <div class="d-flex flex-row justify-content-between gap-1">
                                <span class="f-12 font-400 text-secondary">Tgl berakhir:</span>
                                <div class="d-flex flex-row gap-1">
                                    <span class="f-12 font-600 text-black">{{ model.expiredAt | moment('DD MMM YYYY')}}</span>
                                    <span v-if="getDiff(model.expiredAt) > 0" class="f-12 font-600 text-warning">{{ `(Tersisa ${+getDiff(model.expiredAt)} Hari)` }}</span>
                                </div>
                            </div>
                            <div class="d-flex flex-row justify-content-between gap-1">
                                <span class="font-400 f-12 text-secondary">Teleconsult</span>
                                <a :href="`/konsultasi-dan-resep/answer/${model.consultId}`" target="_blank" class="text-blue font-600 f-12">Open Questionnaire</a>
                            </div>
                            <div class="d-flex flex-row justify-content-between gap-1">
                                <span class="font-400 f-12 text-secondary">Dokter</span>
                                <span class="font-600 f-12 text-black">{{ model.doctor ? model.doctor.fullName : '-'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-8">
                <div class="card" v-for="(data, index) in diagnoses" :key="index">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between mb-1">
                            <span class="f-20 font-500 text-black">Rekam Medis</span>
                            <span class="btn btn-primary rounded">{{ data.createdAt | moment('DD MMM YYYY')}}</span>
                        </div>
                        <div v-if="data.doctor" class="row align-items-center px-1">
                            <img src="/assets/img/icon/doctor-icon.svg" width="18" height="20" alt="" />
                            <span class="f-14 col">{{ data.doctor.fullName }}</span>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="ml-1 f-20 font-600">P&nbsp;</div>
                            <div class="col align-self-center">
                                <p class="f-14 font-400 mb-0">{{ data.p }}</p>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="ml-1 f-20 font-600">D&nbsp;</div>
                            <div class="col align-self-center">
                                <p class="f-14 font-400">{{ data.diagnosisDetail.diagnosis }}</p>
                                <p class="f-14 font-400 mb-0">{{ data.dNotes }}</p>
                            </div>
                        </div>
                        <hr>
                        <div v-if="data.userProductChoice && data.userProductChoice.length > 0">
                            <div class="row" v-for="(userProduct, index) in data.userProductChoice" :key="index">
                                <div class="ml-1 f-20 font-600 text-primary">R/</div>
                                <div class="col align-self-center">
                                    <p class="f-16 font-500 text-warning">Produk Reference by user </p>
                                    <p class="f-14 text-black font-500 mb-0">{{ userProduct.name }} 
                                        <!-- <small>(Qty: {{ userProduct.consult_product.qty }})</small> -->
                                    </p>
                                    <p class="f-12 font-300 mb-0">{{ userProduct.description }}</p>
                                    <!-- <a href="#" class="text-primary f-12 font-600">Lihat Detail Produk</a> -->
                                </div>
                            </div>
                            <hr>
                        </div>
                        <div v-if="data.recipes.length > 0">
                            <div class="row">
                                <div class="ml-1 f-20 font-600 text-primary">R/</div>
                                <div class="col align-self-center overflow-auto">
                                    <table class="iter-table">
                                        <thead>
                                            <tr>
                                                <th>Product X Iter</th>
                                                <th v-for="(historyDate, index) in data.recipePurchasedHistory.map(h => h.date)" :key="index" class="text-center text-blue">
                                                    {{ historyDate | moment('D MMM YY') }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(recipe, index) in data.recipes" :key="index">
                                                <td class="text-left" style="min-width: 215px;">
                                                    <p class="f-14 text-black font-500 mb-0 d-flex flex-row align-items-center gap-1 flex-wrap">
                                                        {{recipe.product.title}}
                                                        <span class="f-12 font-400 text-secondary"> - QTY: {{ recipe.usageAmount }} - {{ recipe.usageInterval }}</span>
                                                        <span v-if="recipe.product.deletedAt || recipe.product.isActive === false" class="medicine-label bg-danger">Discontinued</span>
                                                    </p>
                                                    <p>
                                                        <span class="f-12 font-600" :class="recipe.product.discountPrice > 0 ? 'strikethrough' : 'text-warning'">{{ recipe.product.price | currency }}</span>
                                                        <span class="ml-1 text-warning f-12 font-600" v-if="recipe.product.discountPrice > 0">{{ recipe.product.discountPrice | currency }}</span>
                                                    </p>
                                                </td>
                                                <td v-for="(history, index) in data.recipePurchasedHistory" :key="index" class="text-center" style="min-width: 80px; vertical-align: top;">
                                                    <span v-if="history.productIds.includes(recipe.product.id)" class="medicine-label bg-success">Paid</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <hr class="mt-0">
                        </div>
                        <div class="row">
                            <div class="ml-1 f-20 font-600">C&nbsp;</div>
                            <div class="col align-self-center">
                                <p class="f-14 mb-0" v-html="data.c"></p>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="ml-1 f-20 font-600">K&nbsp;</div>
                            <div class="col align-self-center">
                                <p class="f-14 mb-0 text-primary font-500">{{ data.k | moment('DD MMM YYYY')}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <json-viewer :value="orders"></json-viewer> -->
    </div>
</template>
<script>
import { getOrderTypeLabel } from '@/lib/order';

export default {
    data() {
        return {
            model: {
                user: {
                    account: {}
                }
            },
            diagnoses: [],
            type: ''
        }
    },
    methods: {
        getDiff(x){
            return this.$moment(x).diff(this.$moment(), 'days')
        },
        getDetail() {
            let _ = this
            _.axios.get('consults/' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data
                    _.type = resp.data.data.orders.map(order => getOrderTypeLabel(order.type)).join(', ');
                })
        },
        getDiagnoses() {
            let _ = this
            _.axios.get('consults/' + _.$route.params.id + '/diagnoses')
                .then(resp => {
                    _.diagnoses = resp.data.data.rows
                })
        },
        getAge(birthday) {
            if(birthday){
                var totalMonths = this.$moment().diff(birthday, 'months');
                var years = parseInt(totalMonths / 12);
                var months = totalMonths % 12;

                if( months !== 0 ){
                    return years + ' tahun ' + months + ' bulan';
                }
                return years + ' tahun';
            }
            return null;
        },
    },
    mounted() {
        let _ = this
        _.getDetail()
        _.getDiagnoses()
    }
}
</script>

<style scoped>
.medicine-label {
    color: white;
    padding: 2px 4px;
    font-size: .75rem;
    margin-left: 0;
    border-radius: 5px;
}

.iter-table {
    min-width: 100%;
    overflow: auto;
}

.gap-1 {
    gap: .25rem;
}
</style>