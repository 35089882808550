<template>
  <div class="h-100 w-100 overflow-auto position-relative bg-white">
    <div
      class="
        d-flex
        justify-content-between
        gap-1
        align-items-center
        p-1
        position-sticky
        bg-white
        zindex-1
      "
      style="border-bottom: 1px solid #bcc0d1; top: 0"
    >
      <span class="font-500 f-16 text-dark">Informasi Konsultasi</span>
      <span @click="closeInfo" class="cursor-pointer f-18">
        <i class="fa fa-close"></i>
      </span>
    </div>
    <p v-if="isLoadingConsult" class="p-1">Loading...</p>
    <div v-else-if="consult" class="p-1">
      <div class="mb-1">
        <div
          class="f-14 text-truncate d-flex align-items-center"
          style="gap: 4px"
        >
          <span v-if="consult.user.isVip">👑</span>
          <img
            v-if="consult.user.isSubscribed && !consult.user.isVip"
            src="/assets/img/icon/diri-xtra-logo.svg"
            width="20"
            height="20"
            alt=""
          />
          <img
            v-if="consult.user.isLowValueCustomer"
            src="/assets/img/icon/low-value-customer.png"
            width="18"
            height="18"
            alt=""
          />
          <span class="font-500 text-truncate">{{
            consult.user.fullName
          }}</span>
        </div>
        <p class="f-12 font-400 mb-0">
          {{ consult.user.gender }}, {{ getAge(consult.user.dateOfBirth) }}
        </p>
        <p class="font-400 f-12 text-light-blue mb-0 text-truncate">
          {{ consult.user.account.email }}
        </p>
      </div>
      <div class="mb-1">
        <p class="f-12 font-400" style="margin-bottom: 4px">Teleconsult</p>
        <a
          :href="`/konsultasi-dan-resep/answer/${consult.consultId}`"
          target="_blank"
          class="text-light-blue font-500 f-14"
          >Open Questionnaire</a
        >
      </div>
      <div v-if="consult.user.isLowValueCustomer" class="mb-1">
        <p class="f-12 font-400" style="margin-bottom: 4px">
          Low value customer notes
        </p>
        <p class="font-500 f-14 text-dark mb-0">
          {{ consult.user.lowValueCustomerNotes }}
        </p>
        <p class="f-14 text-light-blue">
          by
          {{ consult.user.lowValueCustomerMarkedBy }}
          at
          {{
            consult.user.lowValueCustomerMarkedAt | moment('DD MMM YYYY, kk:mm')
          }}
        </p>
      </div>
      <div class="separator" />
      <div class="mb-1 d-flex flex-row gap-1">
        <div class="flex-1">
          <p class="f-12 font-400" style="margin-bottom: 4px">
            Tgl channel dibuat
          </p>
          <p
            v-if="consult.consultCheckoutDate"
            class="font-500 f-14 text-dark mb-0"
          >
            <b>{{ consult.consultCheckoutDate | moment('DD MMM YYYY') }}</b>
          </p>
          <p v-else class="font-500 f-14 text-dark mb-0"><b>-</b></p>
        </div>
        <div class="flex-1">
          <p class="f-12 font-400" style="margin-bottom: 4px">Tgl reaktivasi</p>
          <p
            v-if="consult.reactivationDate"
            class="font-500 f-14 text-dark mb-0"
          >
            <b>{{ consult.reactivationDate | moment('DD MMM YYYY') }}</b>
          </p>
          <p v-else class="font-500 f-14 text-dark mb-0"><b>-</b></p>
        </div>
      </div>
      <div class="mb-1 d-flex flex-row gap-1">
        <div class="flex-1">
          <p class="f-12 font-400" style="margin-bottom: 4px">Tgl berakhir</p>
          <p
            v-if="consult.expiredAt"
            class="font-500 f-14 text-dark mb-0 d-flex flex-wrap"
            style="gap: 0.5rem"
          >
            <span>{{ consult.expiredAt | moment('DD MMM YYYY') }}</span>
            <span class="text-orange">{{
              getDiff(consult.expiredAt) > 0
                ? `(Tersisa ${getDiff(consult.expiredAt)} hari)`
                : ''
            }}</span>
          </p>
          <p v-else class="font-500 f-14 text-dark mb-0"><b>-</b></p>
        </div>
        <div class="flex-1">
          <p class="f-12 font-400" style="margin-bottom: 4px">Reaktivasi ke</p>
          <p
            v-if="consult.numberOfReactivation"
            class="font-500 f-14 text-dark mb-0"
          >
            <b>{{ consult.numberOfReactivation }}</b>
          </p>
          <p v-else class="font-500 f-14 text-dark mb-0"><b>-</b></p>
        </div>
      </div>
      <div class="d-flex flex-row gap-1">
        <div class="flex-1">
          <p class="f-12 font-400" style="margin-bottom: 4px">
            Tgl visit klinik terakhir
          </p>
          <p
            v-if="consult.lastClinicVisit"
            class="font-500 f-14 text-dark mb-0"
          >
            <b>{{ consult.lastClinicVisit | moment('DD MMM YYYY') }}</b>
          </p>
          <p v-else class="font-500 f-14 text-dark mb-0"><b>-</b></p>
        </div>
      </div>
      <div class="separator" />
      <div class="mb-1">
        <p class="f-12 font-400" style="margin-bottom: 4px">Konsul ID</p>
        <p class="font-500 f-14 text-light-blue mb-0">
          {{ consult.consultId }}
        </p>
      </div>
      <div class="mb-1">
        <p class="f-12 font-400" style="margin-bottom: 4px">Chat ID</p>
        <p class="font-500 f-14 text-light-blue mb-0">{{ consult.id }}</p>
      </div>
      <div>
        <p class="f-12 font-400" style="margin-bottom: 4px">Tipe</p>
        <div class="d-flex flex-row flex-wrap" style="gap: 1rem">
          <div
            v-for="(type, index) in consult.types"
            :key="index"
            class="font-500 f-14 text-dark d-flex align-items-center"
            style="gap: 0.25rem"
          >
            <img :src="type.icon" width="18" alt="" />
            <span>{{ type.label }}</span>
          </div>
        </div>
      </div>
      <div class="separator" />
      <div class="mb-1">
        <p class="f-12 font-400" style="margin-bottom: 4px">Dokter</p>
        <p class="font-500 f-14 text-dark mb-0">
          {{ consult.doctor ? consult.doctor.fullName : '-' }}
        </p>
      </div>
      <div class="separator" />
      <div>
        <p class="f-14 font-600 text-dark" style="margin-bottom: 4px">Media</p>
        <div
          v-if="sortedMediaYearAndMonth.length > 0"
          v-viewer="{ title: (img) => img.alt }"
        >
          <div
            v-for="yearAndMonth in sortedMediaYearAndMonth"
            :key="yearAndMonth"
            class="mb-1"
          >
            <p class="f-12 font-400" style="margin-bottom: 4px">
              {{ renderMediaMonth(yearAndMonth) }}
            </p>
            <div class="d-flex flex-row flex-wrap gap-1">
              <img
                v-for="(media, index) in media[yearAndMonth]"
                class="rounded pointer"
                :key="index"
                :src="media.name"
                width="64"
                height="64"
                style="object-fit: cover"
                :alt="`${consult.user.fullName} / ${
                  consult.consultId
                } / ${$moment(media.createdAt).format('DD MMMM YYYY - HH:mm')}`"
              />
            </div>
          </div>
        </div>
        <p
          v-if="mediaPage < mediaTotalPages && !isMediaLoading"
          class="font-600 f-12 text-blue mb-0 cursor-pointer"
          @click="nextMediaPage"
        >
          Lihat lebih banyak
        </p>
        <p v-if="isMediaLoading" class="font-500 f-12 text-dark mb-0">
          Loading...
        </p>
        <p
          v-if="sortedMediaYearAndMonth.length === 0 && !isMediaLoading"
          class="font-500 f-14 text-dark mb-0"
        >
          <b>-</b>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    closeInfo: Function,
    isLoadingConsult: Boolean,
    consult: Object,
    media: Object,
    isMediaLoading: Boolean,
    mediaPage: Number,
    mediaTotalPages: Number,
    nextMediaPage: Function,
  },
  computed: {
    sortedMediaYearAndMonth() {
      return Object.keys(this.media).sort().reverse();
    },
  },
  methods: {
    getAge(birthday) {
      if (birthday) {
        const totalMonths = this.$moment().diff(birthday, 'months');
        const years = parseInt(totalMonths / 12);
        const months = totalMonths % 12;

        if (months > 0) {
          return `${years} tahun ${months} bulan`;
        }
        return `${years} tahun`;
      }
      return null;
    },
    getDiff(date) {
      return this.$moment(date)
        .startOf('day')
        .diff(this.$moment().startOf('day'), 'days');
    },
    renderMediaMonth(yearAndMonth) {
      const [year, month] = yearAndMonth.split('-');
      if (
        this.$moment().month() + 1 === Number(month) &&
        this.$moment().year() === Number(year)
      ) {
        return 'Bulan ini';
      }
      return this.$moment(yearAndMonth).format('MMMM YYYY');
    },
  },
};
</script>

<style scoped>
.gap-1 {
  gap: 0.5rem;
}
.separator {
  border-top: 1px solid #bcc0d1;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.flex-1 {
  flex: 1;
}
</style>
