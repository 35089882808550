import RedFlagActiveIcon from '../assets/icons/redflag-active-icon.svg';
import RedFlagInactiveIcon from '../assets/icons/redflag-inactive-icon.svg';
import PregnantActiveIcon from '../assets/icons/pregnant-active-icon.svg';
import PregnantInactiveIcon from '../assets/icons/pregnant-inactive-icon.svg';
import BreastFeedingActiveIcon from '../assets/icons/breastfeeding-active-icon.svg';
import BreastFeedingInactiveIcon from '../assets/icons/breastfeeding-inactive-icon.svg';
import PregnancyPlanningActiveIcon from '../assets/icons/pregnancy-planning-active-icon.svg';
import PregnancyPlanningInactiveIcon from '../assets/icons/pregnancy-planning-inactive-icon.svg';
import KBPillsActiveIcon from '../assets/icons/kb-pills-active-icon.svg';
import KBPillsInactiveIcon from '../assets/icons/kb-pills-inactive-icon.svg';
import FemaleDoctorActiveIcon from '../assets/icons/female-doctor-active-icon.svg';
import FemaleDoctorInactiveIcon from '../assets/icons/female-doctor-inactive-icon.svg';

const TAGS = {
  redFlag: {
    label: 'Red Flag',
    icon: {
      active: RedFlagActiveIcon,
      inactive: RedFlagInactiveIcon,
    },
  },
  pregnant: {
    label: 'Hamil',
    icon: {
      active: PregnantActiveIcon,
      inactive: PregnantInactiveIcon,
    },
  },
  breastFeeding: {
    label: 'Menyusui',
    icon: {
      active: BreastFeedingActiveIcon,
      inactive: BreastFeedingInactiveIcon,
    },
  },
  promil: {
    label: 'Program Hamil',
    icon: {
      active: PregnancyPlanningActiveIcon,
      inactive: PregnancyPlanningInactiveIcon,
    },
  },
  kb: {
    label: 'KB',
    icon: {
      active: KBPillsActiveIcon,
      inactive: KBPillsInactiveIcon,
    },
  },
  preferFemaleDoctor: {
    label: 'Dokter Perempuan',
    icon: {
      active: FemaleDoctorActiveIcon,
      inactive: FemaleDoctorInactiveIcon,
    },
  },
};

export { TAGS };