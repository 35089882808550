// MANUAL ASSIGNMENT

export const MANUAL_ASSIGNMENT_TAB_KEYS = {
  ALL: 'ALL',
  TO_RESPOND: 'TO_RESPOND',
  TRIAGE: 'TRIAGE',
  END: 'END',
};

export const ORDERED_MANUAL_ASSIGNMENT_TAB_KEYS = [
  MANUAL_ASSIGNMENT_TAB_KEYS.ALL,
  MANUAL_ASSIGNMENT_TAB_KEYS.TO_RESPOND,
  MANUAL_ASSIGNMENT_TAB_KEYS.TRIAGE,
  MANUAL_ASSIGNMENT_TAB_KEYS.END,
];

export const MANUAL_ASSIGNMENT_TABS = {
  [MANUAL_ASSIGNMENT_TAB_KEYS.ALL]: {
    label: 'Semua',
    filter: {
      sortBy: 'lastMessage.createdAt.DESC-nulllast,updatedAt.DESC',
    },
    limitPerPage: 20,
    isPaginated: true,
    shouldRemoveChannelOnClinicalSendMessage: false,
    shouldRemoveChannelOnCustomerSendMessage: false,
    shouldPushNewChannelMessageToTop: true,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsAssignedToMe: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsAssigned: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsUnassigned: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsNotReadyForDoctors: false,
    shouldRefreshOnReadyForDoctorsToggled: false,
    shouldRefreshOnChannelAssignment: false,
    shouldRemoveChannelOnReadyForDoctorsToggledOff: false,
    showToggleReadyForDoctors: false,
    showAssigneeFilter: true,
    showNotAssignedOptionOnAssigneeFilter: true,
  },
  [MANUAL_ASSIGNMENT_TAB_KEYS.TO_RESPOND]: {
    label: 'To Respond',
    filter: {
      sortBy: 'waitingTime.DESC',
      responded: false,
      isReady: true,
    },
    limitPerPage: 50,
    isPaginated: false,
    shouldRemoveChannelOnClinicalSendMessage: true,
    shouldRemoveChannelOnCustomerSendMessage: false,
    shouldPushNewChannelMessageToTop: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsAssignedToMe: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsAssigned: true,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsUnassigned: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsNotReadyForDoctors: false,
    shouldRefreshOnReadyForDoctorsToggled: true,
    shouldRefreshOnChannelAssignment: false,
    shouldRemoveChannelOnReadyForDoctorsToggledOff: false,
    showToggleReadyForDoctors: true,
    showAssigneeFilter: true,
    showNotAssignedOptionOnAssigneeFilter: false,
  },
  [MANUAL_ASSIGNMENT_TAB_KEYS.TRIAGE]: {
    label: 'Triage',
    filter: {
      sortBy: 'waitingTime.DESC',
      responded: false,
      isReady: false,
    },
    limitPerPage: 100,
    isPaginated: false,
    shouldRemoveChannelOnClinicalSendMessage: true,
    shouldRemoveChannelOnCustomerSendMessage: false,
    shouldPushNewChannelMessageToTop: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsAssignedToMe: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsAssigned: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsUnassigned: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsNotReadyForDoctors: true,
    shouldRefreshOnReadyForDoctorsToggled: true,
    shouldRefreshOnChannelAssignment: false,
    shouldRemoveChannelOnReadyForDoctorsToggledOff: false,
    showToggleReadyForDoctors: true,
    showAssigneeFilter: false,
    showNotAssignedOptionOnAssigneeFilter: false,
  },
  [MANUAL_ASSIGNMENT_TAB_KEYS.END]: {
    label: 'Berakhir',
    filter: {
      sortBy: 'lastMessage.createdAt.DESC-nulllast,updatedAt.DESC',
      status: 2,
    },
    limitPerPage: 20,
    isPaginated: true,
    shouldRemoveChannelOnClinicalSendMessage: false,
    shouldRemoveChannelOnCustomerSendMessage: false,
    shouldPushNewChannelMessageToTop: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsAssignedToMe: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsAssigned: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsUnassigned: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsNotReadyForDoctors: false,
    shouldRefreshOnReadyForDoctorsToggled: false,
    shouldRefreshOnChannelAssignment: false,
    shouldRemoveChannelOnReadyForDoctorsToggledOff: false,
    showToggleReadyForDoctors: false,
    showAssigneeFilter: false,
    showNotAssignedOptionOnAssigneeFilter: false,
  },
}

// AUTO ASSIGNMENT

export const AUTO_ASSIGNMENT_TAB_KEYS = {
  ALL: 'ALL',
  ASSIGNED_TO_ME: 'ASSIGNED_TO_ME',
  ASSIGNED_TO_ME_IDLE: 'ASSIGNED_TO_ME_IDLE',
  ASSIGNED: 'ASSIGNED',
  UNASSIGNED: 'UNASSIGNED',
  TRIAGE: 'TRIAGE',
  END: 'END',
};

export const ORDERED_AUTO_ASSIGNMENT_TAB_KEYS = [
  AUTO_ASSIGNMENT_TAB_KEYS.ALL,
  AUTO_ASSIGNMENT_TAB_KEYS.ASSIGNED_TO_ME,
  AUTO_ASSIGNMENT_TAB_KEYS.ASSIGNED_TO_ME_IDLE,
  AUTO_ASSIGNMENT_TAB_KEYS.ASSIGNED,
  AUTO_ASSIGNMENT_TAB_KEYS.UNASSIGNED,
  AUTO_ASSIGNMENT_TAB_KEYS.TRIAGE,
  AUTO_ASSIGNMENT_TAB_KEYS.END,
];

export const AUTO_ASSIGNMENT_TABS = {
  [AUTO_ASSIGNMENT_TAB_KEYS.ALL]: {
    label: 'Semua',
    filter: {
      sortBy: 'lastMessage.createdAt.DESC-nulllast,updatedAt.DESC',
    },
    limitPerPage: 20,
    isPaginated: true,
    shouldRemoveChannelOnClinicalSendMessage: false,
    shouldRemoveChannelOnCustomerSendMessage: false,
    shouldPushNewChannelMessageToTop: true,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsAssignedToMe: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsAssigned: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsUnassigned: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsNotReadyForDoctors: false,
    shouldRefreshOnReadyForDoctorsToggled: false,
    shouldRefreshOnChannelAssignment: false,
    shouldRemoveChannelOnReadyForDoctorsToggledOff: false,
    showToggleReadyForDoctors: false,
    showAssigneeFilter: true,
    showNotAssignedOptionOnAssigneeFilter: true,
    permissionSlug: 'consult_prescription:tab:all',
  },
  [AUTO_ASSIGNMENT_TAB_KEYS.ASSIGNED_TO_ME]: {
    label: 'Assigned to me',
    filter: (adminId) => ({
      sortBy: 'waitingTime.DESC',
      assignedTo: adminId,
      responded: false,
      isReady: true,
    }),
    limitPerPage: 20,
    isPaginated: false,
    shouldRemoveChannelOnClinicalSendMessage: true,
    shouldRemoveChannelOnCustomerSendMessage: false,
    shouldPushNewChannelMessageToTop: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsAssignedToMe: true,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsAssigned: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsUnassigned: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsNotReadyForDoctors: false,
    shouldRefreshOnReadyForDoctorsToggled: false,
    shouldRefreshOnChannelAssignment: true,
    shouldRemoveChannelOnReadyForDoctorsToggledOff: true,
    showToggleReadyForDoctors: true,
    showAssigneeFilter: false,
    showNotAssignedOptionOnAssigneeFilter: false,
    permissionSlug: 'consult_prescription:tab:assigned_to_me',
  },
  [AUTO_ASSIGNMENT_TAB_KEYS.ASSIGNED_TO_ME_IDLE]: {
    label: 'Assigned to me (Idle)',
    filter: (adminId) => ({
      sortBy: 'lastMessage.createdAt.DESC-nulllast,updatedAt.DESC',
      assignedTo: adminId,
      responded: true,
      isReady: true,
    }),
    limitPerPage: 20,
    isPaginated: false,
    shouldRemoveChannelOnClinicalSendMessage: false,
    shouldRemoveChannelOnCustomerSendMessage: true,
    shouldPushNewChannelMessageToTop: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsAssignedToMe: true,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsAssigned: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsUnassigned: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsNotReadyForDoctors: false,
    shouldRefreshOnReadyForDoctorsToggled: false,
    shouldRefreshOnChannelAssignment: true,
    shouldRemoveChannelOnReadyForDoctorsToggledOff: true,
    showToggleReadyForDoctors: true,
    showAssigneeFilter: false,
    showNotAssignedOptionOnAssigneeFilter: false,
    permissionSlug: 'consult_prescription:tab:assigned_to_me_idle',
  },
  [AUTO_ASSIGNMENT_TAB_KEYS.ASSIGNED]: {
    label: 'Assigned',
    filter: {
      sortBy: 'waitingTime.DESC',
      assignedTo: 'notnull',
      responded: false,
      isReady: true,
    },
    limitPerPage: 25,
    isPaginated: false,
    shouldRemoveChannelOnClinicalSendMessage: true,
    shouldRemoveChannelOnCustomerSendMessage: false,
    shouldPushNewChannelMessageToTop: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsAssignedToMe: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsAssigned: true,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsUnassigned: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsNotReadyForDoctors: false,
    shouldRefreshOnReadyForDoctorsToggled: false,
    shouldRefreshOnChannelAssignment: true,
    shouldRemoveChannelOnReadyForDoctorsToggledOff: true,
    showToggleReadyForDoctors: true,
    showAssigneeFilter: true,
    showNotAssignedOptionOnAssigneeFilter: false,
    permissionSlug: 'consult_prescription:tab:assigned',
  },
  [AUTO_ASSIGNMENT_TAB_KEYS.UNASSIGNED]: {
    label: 'Unassigned',
    filter: {
      sortBy: 'waitingTime.DESC',
      assignedTo: 'null',
      responded: false,
      isReady: true,
    },
    limitPerPage: 25,
    isPaginated: false,
    shouldRemoveChannelOnClinicalSendMessage: true,
    shouldRemoveChannelOnCustomerSendMessage: false,
    shouldPushNewChannelMessageToTop: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsAssignedToMe: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsAssigned: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsUnassigned: true,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsNotReadyForDoctors: false,
    shouldRefreshOnReadyForDoctorsToggled: true,
    shouldRefreshOnChannelAssignment: true,
    shouldRemoveChannelOnReadyForDoctorsToggledOff: false,
    showToggleReadyForDoctors: true,
    showAssigneeFilter: false,
    showNotAssignedOptionOnAssigneeFilter: false,
    permissionSlug: 'consult_prescription:tab:unassigned',
  },
  [AUTO_ASSIGNMENT_TAB_KEYS.TRIAGE]: {
    label: 'Triage',
    filter: {
      sortBy: 'waitingTime.DESC',
      responded: false,
      isReady: false,
    },
    limitPerPage: 25,
    isPaginated: false,
    shouldRemoveChannelOnClinicalSendMessage: true,
    shouldRemoveChannelOnCustomerSendMessage: false,
    shouldPushNewChannelMessageToTop: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsAssignedToMe: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsAssigned: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsUnassigned: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsNotReadyForDoctors: true,
    shouldRefreshOnReadyForDoctorsToggled: true,
    shouldRefreshOnChannelAssignment: false,
    shouldRemoveChannelOnReadyForDoctorsToggledOff: false,
    showToggleReadyForDoctors: true,
    showAssigneeFilter: false,
    showNotAssignedOptionOnAssigneeFilter: false,
    permissionSlug: 'consult_prescription:tab:triage',
  },
  [AUTO_ASSIGNMENT_TAB_KEYS.END]: {
    label: 'Berakhir',
    filter: {
      sortBy: 'lastMessage.createdAt.DESC-nulllast,updatedAt.DESC',
      status: 2,
    },
    limitPerPage: 20,
    isPaginated: true,
    shouldRemoveChannelOnClinicalSendMessage: false,
    shouldRemoveChannelOnCustomerSendMessage: false,
    shouldPushNewChannelMessageToTop: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsAssignedToMe: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsAssigned: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsUnassigned: false,
    shouldRefreshOnReceiveUnlistedChannelMessageThatIsNotReadyForDoctors: false,
    shouldRefreshOnReadyForDoctorsToggled: false,
    shouldRefreshOnChannelAssignment: false,
    shouldRemoveChannelOnReadyForDoctorsToggledOff: false,
    showToggleReadyForDoctors: false,
    showAssigneeFilter: false,
    showNotAssignedOptionOnAssigneeFilter: false,
    permissionSlug: 'consult_prescription:tab:end',
  },
}