<template>
    <div class="mw-100 p-2">
        <div class="section">
            <div class="card">
                <div class="card-body p-0">
                    <div class="row p-2">
                        <div v-if="hasTablePermission" class="col">
                            <div class="row">
                                <div class="col">
                                    <multiselect
                                        v-model="searchParam"
                                        :options="[
                                            { value: 'name', label: 'Search by Name' },
                                            { value: 'email', label: 'Search by Email' },
                                            { value: 'mobileNumber', label: 'Search by Phone number' },
                                        ]"
                                        label="label"
                                        track-by="value"
                                        :show-labels="false"
                                        :multiple="false"
                                        :searchable="false"
                                        :allow-empty="false"
                                        @input="handleChangeSearchParam()"
                                    ></multiselect>
                                </div>
                                <div class="input-group col-7">
                                    <span class="input-group-prepend">
                                        <div class="input-group-text border-right-0 bg-transparent text-blue">
                                            <i class="fa fa-search"></i>
                                        </div>
                                    </span>
                                    <input class="form-control border-left-0" v-model="searchMessage" type="search" placeholder="Cari user..." @input="handleSearch()">
                                </div>
                            </div>
                        </div>
                        <div class="col" :class="{ 'text-right': hasTablePermission }" v-if="this.hasAddPermission">
                            <div class="btn-responsive">
                                <button type="button" class="btn btn-primary rounded" @click="openCreateNewModal()">
                                    Tambah <i class="fa fa-plus"></i>
                                </button>
                            </div>
                        </div>
                    </div>   
                    <div v-if="hasTablePermission" class="table-responsive">
                        <table class="table mb-0">
                            <thead>
                                <tr>
                                    <th class="font-600 text-black">User ID</th>
                                    <th class="font-600 text-black">Name</th>
                                    <th class="font-600 text-black">Email</th>
                                    <th class="font-600 text-black">Phone</th>
                                    <th class="font-600 text-black">Terakhir Order</th>
                                    <th class="font-600 text-black" style="width: 10px;">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in rows" :key="index">
                                    <td>{{ data.id }}</td>
                                    <td>
                                        <div class="d-flex align-items-center gap-1">
                                            <span v-if="data.isVip">👑</span>
                                            <img
                                                v-if="data.isSubscribed"
                                                src="/assets/img/icon/diri-xtra-logo.svg"
                                                width="24"
                                                height="24"
                                                alt=""
                                            />
                                            <img
                                                v-if="data.isLowValueCustomer"
                                                src="/assets/img/icon/low-value-customer.png"
                                                width="18"
                                                height="18"
                                                alt=""
                                            />
                                            <span class="text-truncate">{{ data.firstName }} {{ data.lastName }}</span>
                                        </div>
                                    </td>
                                    <td>{{ data.email }}</td>
                                    <td>{{ data.mobileNumber ? data.mobileNumber : '-' }}</td>
                                    <td v-if="data.lastOrderedAt">{{ data.lastOrderedAt | moment("DD MMM yyyy") }}</td>
                                    <td v-else>-</td>
                                    <td>
                                        <div class="btn-action-block">
                                            <button type="button" class="btn btn-sm p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span class="material-icons">more_horiz</span>
                                            </button>
                                            <div class="dropdown-menu mr-3">
                                                <a v-if="hasDetailPermission" class="dropdown-item text-black" @click="$router.push('/daftar-pelanggan/detail/' + data.id)">Lihat Detail</a>
                                                <span v-if="hasMarkVIPPermission" class="dropdown-item text-black cursor-pointer" @click="toggleVip(data.id, data.isVip)">
                                                    {{ data.isVip ? 'Unmark as VIP' : 'Mark as VIP' }}
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-if="hasTablePermission" class="col text-center mt-1">
                        <p class="f-14" v-if="rows.length === 0">There is no data</p>
                    </div>
                </div>
            </div>
            <div v-if="hasTablePermission" class="card">
                <div class="card-body d-flex" style="gap: .5rem;">
                    <button type="button" class="btn btn-primary btn-sm rounded" @click="prevPagination()" :disabled="page === 1">
                        <i class="fa fa-chevron-left"></i>
                    </button>
                    <button type="button" class="btn btn-primary btn-sm rounded" @click="nextPagination()" :disabled="!hasNext">
                        <i class="fa fa-chevron-right"></i>
                    </button>
                </div>
            </div>
        </div>
        
        <!-- Create new user modal -->
        
        <modal :name="CREATE_NEW_MODAL" :adaptive="true" height="auto" :width="500" style="z-index: 2000">
            <div class="modal-container">
                <p class="f-16 text-black font-600">Tambah Customer</p>
                <div class="col">
                    <ValidationObserver ref="observer">
                        <ValidationProvider name="email" v-slot="{ errors }" rules="required|email">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label for="" class="f-14 text-secondary">Email <span class="text-danger">*</span></label>
                                <input type="text" v-model="model.email" class="form-control" placeholder="Masukkan email">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <ValidationProvider name="first name" v-slot="{ errors }" rules="required">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="" class="f-14 text-secondary required">Nama Depan<span class="text-danger">*</span></label>
                                        <input type="text" v-model="model.firstName" class="form-control" placeholder="Masukkan Nama Depan ">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-lg-6">
                                <ValidationProvider name="last name" v-slot="{ errors }">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label for="" class="f-14 text-secondary required">Nama Belakang</label>
                                        <input type="text" v-model="model.lastName" class="form-control" placeholder="Masukkan Nama Belakang">
                                        <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <ValidationProvider name="phone number" v-slot="{ errors }"             
                            :rules="{ required: true, regex:/^08[1-9][0-9]{7,11}$/}" >
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label for="" class="f-14 text-secondary">Nomor Handphone <span class="text-danger">*</span></label>
                                <input type="text" v-model="model.mobileNumber" class="form-control" placeholder="Masukkan Nomor Handphone">
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="birthday" v-slot="{ errors }" rules="required">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label for="" class="f-14 text-secondary">Tanggal Lahir <span class="text-danger">*</span></label>
                                <date-picker
                                    v-model="model.dateOfBirth"
                                    format="DD/MM/YYYY"
                                    type="date"
                                    input-class="form-control"
                                    value-type="YYYY-MM-DD"
                                    placeholder="Pilih tanggal"
                                    style="width: 100%"
                                    :disabled-date="disabledDate"
                                    :default-value="$moment().subtract(17, 'years')"
                                />
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ul class="form-group">
                            <li class="col-6 p-0">
                                <button 
                                    type="button" 
                                    class="gender left" 
                                    :class="model.gender === 'M' ? 'active' : ''"
                                    @click="setGender('M')"
                                >
                                    Laki-Laki
                                </button>
                            </li>
                            <li class="col-6 p-0">
                                <button 
                                    type="button" 
                                    class="gender right" 
                                    :class="model.gender === 'F' ? 'active' : ''"
                                    @click="setGender('F')"
                                >
                                    Perempuan
                                </button>
                            </li>
                        </ul>
                        <div class="form-group row" v-if="model.gender === 'F'">
                            <label class="f-14 d-flex align-items-center gap-1 mb-1 col-12 col-lg-6">
                                <input type="checkbox" v-model="model.pregnant">
                                Hamil
                            </label>
                            <label class="f-14 d-flex align-items-center gap-1 mb-1 col-12 col-lg-6">
                                <input type="checkbox" v-model="model.breastFeeding">
                                Menyusui
                            </label>
                            <label class="f-14 d-flex align-items-center gap-1 mb-1 col-12 col-lg-6">
                                <input type="checkbox" v-model="model.promil">
                                Program Hamil
                            </label>
                            <label class="f-14 d-flex align-items-center gap-1 mb-1 col-12 col-lg-6">
                                <input type="checkbox" v-model="model.kb">
                                Menggunakan KB
                            </label>
                            <label class="f-14 d-flex align-items-center gap-1 mb-1 col-12 col-lg-6">
                                <input type="checkbox" v-model="model.preferFemaleDoctor">
                                Menggunakan Hijab
                            </label>

                        </div>
                        <div class="submit-wrapper row flex justify-content-end gap-1">
                            <button type="button" class="btn btn-outline-primary rounded" @click="closeCreateNewModal()">
                                Cancel
                            </button>
                            <button type="submit" class="btn btn-primary rounded" @click="onSubmit()" :disabled="isLoadingSubmit">
                                Create
                            </button>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
    </modal>
    </div>
</template>

<script>
import { debounce } from "debounce";

const CREATE_NEW_MODAL = 'create_new_modal';

export default {
	data() {
		return {
            CREATE_NEW_MODAL,
			rows: [],
            limit: 10,
            hasNext: true,
            prevLastKeys: [],
            nextLastKey: undefined,
            searchMessage: '',
            searchParam: { value: 'name', label: 'Search by Name' },
            model: {
                email: "",
                firstName: "",
                lastName: "",
                mobileNumber: "",
                dateOfBirth: "",
                gender: "M",
                pregnant: false,
                breastFeeding: false,
                promil: false,
                kb: false,
                preferFemaleDoctor: false,
            },
            isLoadingSubmit: false,
		}
	},
    mounted() {
        if (this.hasTablePermission) {
            this.getList();
        }
    },
    computed: {
        page() {
            return this.prevLastKeys.length + 1;
        },
        sanitizedSearchMessage() {
            if (this.searchParam.value === 'email') {
                return this.searchMessage.replace(/ /g, '');
            }
            if (this.searchParam.value === 'mobileNumber') {
                const trimmedMessage = this.searchMessage.replace(/ /g, '');
                if (trimmedMessage.startsWith('+62')) {
                    return `0${trimmedMessage.slice(3)}`;
                }
                return trimmedMessage;
            }
            return this.searchMessage;
        },
        permissions() {
            return this.$store.getters.permissions;
        },
        hasViewPermission() {
            return this.permissions.includes('customer:view');
        },
        hasAddPermission() {
            return this.permissions.includes('customer:add');
        },
        hasEditPermission() {
            return this.permissions.includes('customer:edit');
        },
        hasMarkVIPPermission() {
            return this.permissions.includes('customer:vip');
        },
        hasTablePermission() {
            return this.hasViewPermission || this.hasEditPermission || this.hasMarkVIPPermission;
        },
        hasDetailPermission() {
            return this.hasViewPermission || this.hasEditPermission;
        },
    },
    methods: {
        toggleVip(userId, isVip) {
            const newIsVip = !isVip;
            this.axios.patch(`/v2/users/${userId}/vip`, {
                isVip: newIsVip,
            })
                .then(() => {
                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        text: newIsVip ? 'Successfully marked user as VIP' : 'Successfully unmarked user as VIP',
                    });
                    this.rows = this.rows.map(row =>
                        row.id === userId ? {
                            ...row,
                            isVip: newIsVip,
                            isLowValueCustomer: newIsVip ? false : row.isLowValueCustomer
                        } : row
                    );
                })
                .catch((err) => {
                    this.$notify({
                        type: 'error',
                        title: 'Error',
                        text: err.response.data.message
                    });
                });
        },
        handleChangeSearchParam: debounce(function() {
            if (this.searchMessage) {
                this.getList();
            }
        }, 500),
        handleSearch: debounce(function() {
            this.getList();
        }, 500),
        prevPagination() {
            this.getList('prev');
        },
        nextPagination() {
            this.getList('next');
        },
        getList(type = 'reset') { // type: 'next' or 'prev' or 'reset'
            let lastKey;
            switch (type) {
                case 'next':
                    lastKey = this.nextLastKey;
                    break;
                case 'prev':
                    lastKey = this.prevLastKeys[this.prevLastKeys.length - 2];
                    break;
                default: // 'reset'
                    lastKey = undefined;
                    break;
            }
            const params = {
                lastKey,
                limit: this.limit,
            };
            if (this.searchMessage) {
                params[this.searchParam.value] = this.sanitizedSearchMessage;
            }
            this.axios.get('/v2/users', { params })
                .then(resp=>{
                    this.rows = resp.data.data.rows;
                    this.hasNext = resp.data.data.rows.length === this.limit;
                    switch (type) {
                        case 'next': {
                            this.prevLastKeys.push(this.nextLastKey);
                            this.nextLastKey = resp.data.data.lastKey;
                            break;
                        }
                        case 'prev': {
                            this.prevLastKeys.splice(-1, 1);
                            this.nextLastKey = resp.data.data.lastKey;
                            break;
                        }
                        default: // 'reset'
                            this.prevLastKeys = [];
                            this.nextLastKey = resp.data.data.lastKey;
                            break;
                    }
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        clearForm() {
            this.model = {
                email: "",
                firstName: "",
                lastName: "",
                mobileNumber: "",
                dateOfBirth: "",
                gender: "M",
                pregnant: false,
                breastFeeding: false,
                promil: false,
                kb: false,
                preferFemaleDoctor: false,
            }
        },
        openCreateNewModal() {
            this.$modal.show(CREATE_NEW_MODAL);
            this.clearForm()
        },
        closeCreateNewModal() {
            this.$modal.hide(CREATE_NEW_MODAL);
            if (this.$refs.observer) {
                this.$refs.observer.reset();
            }
        },
        disabledDate(date) {
        return (
            this.$moment() &&
            date.setHours(0, 0, 0, 0) > new Date(this.$moment().subtract(17, 'years')).setHours(0, 0, 0, 0)
        );
        },
        async onSubmit() {
            const isValid = await this.$refs.observer.validate();
            if (isValid) {
                const {email, firstName, lastName, mobileNumber, dateOfBirth, gender, pregnant, breastFeeding, promil, kb, preferFemaleDoctor} = this.model;
                const data={ email,firstName, lastName, mobileNumber, dateOfBirth, gender };
                if(gender === 'F'){
                    data.pregnant = pregnant;
                    data.breastFeeding = breastFeeding;
                    data.promil = promil;
                    data.kb = kb;
                    data.preferFemaleDoctor = preferFemaleDoctor;
                }

                this.axios.post('/v2/users', data)
                    .then(() => {
                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            text: 'new user is successfully created'
                        });
                        if (this.hasTablePermission) {
                            this.getList();
                        }
                        this.closeCreateNewModal();
                    })
                    .catch((err) => {
                        this.$notify({
                            type: 'error',
                            title: 'Error',
                            text: err.response.data.message
                        });
                    });

            }
        },
        setGender(gender){
            this.model.gender = gender;
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Are you sure want to delete Flow?',
                text: x.fullName,
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'No',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Yes',
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/products/' + x.id)
                            .then(resp => {
                                if (resp.status) {
                                    _.getList()
                                    _.$swal.fire({
                                        title: 'Deleted!',
                                        text: resp.message,
                                        icon: 'success',
                                        confirmButtonColor: '#0036A0',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
    },
}
</script>

<style scoped>
.modal-container{
    overflow: scroll;
    padding: 1rem;
    height: 100%;
}
.gap-1 {
  gap: 0.5rem;
}

ul.form-group{
    list-style: none;
    display: flex;
    padding: 0;
}
.gender {
    cursor: pointer;
    padding: 12px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    width: 100%;
    background: #FFFFFF;
    color: #626262;
    border: 1px solid #626262;
}
.gender.left{
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
.gender.right{
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
.gender.active {
    background: #0036A0;
    color: #FFFFFF;
    border: 1px solid #0036A0;
}

.submit-wrapper {
    margin: 16px 0 0;
}
</style>