<template>
  <div class="badge" :style="{ color: textColor, backgroundColor: bgColor }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    textColor: String,
    bgColor: String,
  },
};
</script>

<style scoped>
.badge {
  display: inline-flex;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  padding-inline: 8px;
}
</style>
